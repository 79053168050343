/* eslint-disable no-irregular-whitespace */
export default `
      <p class = "p-content">
        다음 이용 약관을 철저히 읽으십시오. 제품 (아래 정의 됨)에 액세스하거나 사용함으로써 귀하는 수시로 수정되거나 보완 될 수 있으므로 본 사용 약관에 계약을 맺고이를 확고히 준수하는 데 동의합니다. 이 이용 약관에 동의하지 않으면 제품을 사용할 수 없습니다.
      </p>

      <p class = "p-content">
        <b> 【일반 조항】 </b>이 약관에서 사용하는 대문자 용어는 다음과 같은 의미를 갖습니다.
        공급자-제품에 대한 액세스를 제공하는 엔티티, 즉 {project} PTE입니다. LTD.
        제품-사용자가 이미 설치된 모든 프로그램 및 게임에 액세스하고 사용자의 컴퓨터에 저장된 비디오와 음악을 즐기거나 PC 게임 및 모든 게임을 즐길 수있는 클라이언트 원격 데스크톱 인이 사용 약관에 따른 유틸리티 소프트웨어 플래시 게임.
        사용 조건-이 문서는 제품 사용 조건과 사용자 및 공급자의 권리와 의무를 명시합니다. 사용 약관은 사용자의 제품 사용과 관련하여 사용자와 공급자 간의 구속력있는 계약을 구성합니다.
        사용자-사용 약관에 따라 제품을 사용하는 자신을 대신하여 법적 거래에 참여할 수있는 완전한 능력을 가진 자신의 관할권에서 성년에 도달 한 개인.
        전자적 수단으로 서비스를 제공함에있어 공급자는 다음과 같은 이용 약관을 설정합니다.
      </p>
      <p class = "p-content">
        <b> 【기능】 </b>이 제품은 인터넷에 액세스 할 수있는 모든 사람이 전 세계적으로 액세스 할 수 있습니다.
        이 제품은 사용자가 이미 설치 한 모든 프로그램 및 게임, 클라우드 컴퓨터 (Windows, Mac 또는 Linux 사용)에 저장된 게임에 원격으로 액세스하거나 PC 게임 및 모든 플래시 게임을 즐길 수 있도록 설계되었습니다. 지금까지 제품은 Android 기반 장치에서만 작동합니다.
        공급자는 특히 사용자에게 새로운 기능과 시설을 도입하여 언제든지 제품 기능을 변경할 수있는 권한을 보유합니다.
      </p>
     <p class = "p-content">
        <b> 【제품 사용 조건 및 책임 규칙】 </b> 제품 사용에 관한 기술적 요구 사항은 다음과 같습니다.
        3G / 4G / 5G 또는 Wi-Fi를 지원하는 Android 기반 모바일 장치
        사용자가 소유 한 장비와 사용 된 소프트웨어가 위의 요구 사항을 충족하는지 확인하고 제품을 사용할 수 있도록 허용하는 것은 사용자의 책임입니다.
        제품 사용을 시작하기 전에 사용자는 본 이용 약관 및 개인 정보 보호 정책의 조항을 숙지해야합니다. 제품 액세스 및 사용은 본 이용 약관 및 개인 정보 보호 정책에 동의하는 것과 같습니다.
        사용자는 제품을 사용하는 동안 현재 문구와 이후 수정 사항 모두에서 사용 약관을 존중할 것을 약속합니다.
        사용자는 하나의 라이선스 만 보유 할 수 있습니다. 라이선스는 제 3 자에게 양도하거나 그러한 제 3자가 사용할 수 없습니다.
        사용자는 제품 사용을 방해하거나 불안정하게 만들 수있는 모든 행위를 삼가해야합니다. 제공자는 사전 통지없이 악의적 인 활동이나 관련 법률 또는 본 이용 약관의 기타 위반에 대한 대응으로 보상 요구를 포함하여 가능한 조치를 취할 수 있습니다. 제한없이 이전 문장에서 언급 된 조치는 다음과 같습니다.
        다른 사용자의 가입이나 컴퓨터에 대한 액세스를 방해하거나 차단하려는 시도 (DOS, DDOS 공격, DNS 스푸핑)
        피싱 (Phishing) : 먼저 서면 동의없이 사용자를 식별 할 수있는 정보를 위조하거나 숨기는 행위
        특히 바이러스, 트로이 목마 또는 인터넷 버그를 포함한 악성 소프트웨어를 시스템 또는 공급자의 컴퓨터에 입력하는 행위
        보안 허점을 찾기 위해 제공자 또는 다른 사용자의 네트워크 무단 스캔;
        네트워크 트래픽의 무단 모니터링 또는 제공자 또는 다른 사용자에게 예약 된 정보를 가로 채려는 기타 시도
        다른 사용자의 컴퓨터 또는 네트워크 보안을 침해하려는 무단 시도
        IP 주소 인수;
       대량 원치 않는 서신 (스팸)을 보낼 목적으로 전자 메일 주소를 직접 또는 간접적으로 전송, 수집, 판매 또는 배포하는 행위.
        본 이용 약관의 다른 조항을 제한하지 않고 사용자는 제품을 사용하여 다음을 수행하거나 다른 사람이 다음을 수행하도록 지원할 수 없습니다.
        다른 사람을 위협, 명예 훼손, 스토킹, 학대 또는 괴롭 히거나 불법 활동에 가담하는 행위
        개인 또는 법인의 법적 권리 (지적 재산권 또는 개인 정보 보호 권리를 포함하되 이에 국한되지 않음)를 위반하거나, 그러한 권리를 위반하거나 회피하는 자료를 전송하거나 지적 재산권 또는 기타 법적 고지를 제거 또는 변경하는 행위
        바이러스, 스파이웨어, 애드웨어 또는 기타 유해한 코드가 포함 된 파일을 전송하는 행위
        공급자가 단독 재량에 따라 불쾌하다고 판단하는 상품 또는 서비스를 광고하거나 홍보하는 행위 (스팸 전송을 포함하되 이에 국한되지 않음)
        제품을 사용하는 다른 사람을 방해하는 행위
        해당 사용자 및 공급자의 동의없이 사용자의 개인 데이터를 전송, 수집 또는 액세스하는 행위
        무단 스파이더 링, "스크래핑"또는 수집, 접촉 또는 기타 개인 데이터에 관여하거나 기타 무단 자동화 된 수단을 사용하여 정보를 컴파일하는 행위
        액세스 제어를 무효화하고 공급자가 사용자에게 액세스 권한을 부여하지 않은 제품 부분에 액세스합니다.
        공급자는 사전 통지없이 즉시 계약을 해지하고 본 이용 약관을 위반 한 사용자의 제품에 대한 액세스를 일시 중지하거나 영구적으로 제거 할 수 있습니다.
        사용자가 문의 양식을 사용하는 경우, 그는 사건의 사실에 맞는 실제 정보를 제공 할 의무가 있습니다.
        공급자는 제품의 최고 품질과 안정성을 보장하기 위해 노력하지만 관련 법률이 허용하는 최대 범위 내에서 불가항력 또는 제 3 자의 무단 활동으로 인한 중단이나 방해에 대해 책임을지지 않습니다.
        관련 법률에서 허용하는 최대 범위 내에서 공급자는 제품의 프레임 워크 내에서 새로운 솔루션 및 시설의 구현으로 인해 제품을 일시적으로 사용할 수 없게 된 것에 대해 책임을지지 않습니다. 가능한 한 공급자는 제품 접근성의 예정된 휴식 또는 사용 중에 발생할 수있는 불편을 사용자에게 알립니다. 공급자는 제품에 대한 액세스를 제공하는 데 사용되는 IT 시스템 장비에 대한 유지 관리 작업을 수행 할 권리가 있으며, 이로 인해 일시적으로 문제가 발생하거나 사용자가 제품을 사용할 수 없게 될 수 있습니다.
        제품의 보안 또는 안정성에 영향을 미치는 특수한 경우 공급자는 사전에 사용자에게 알리지 않고 제품에 대한 액세스를 일시적으로 줄이거 나 일시 중지하고 제품의 보안 및 안정성을 복원하기위한 유지 관리 작업을 수행 할 권리가 있습니다.
        관련 법률이 허용하는 최대 범위 내에서, 제공자는 다른 사용자 또는 다른 사용자가 이용 약관 또는 현행법 위반과 관련하여 제공자에게 청구를 제출하는 경우 다른 사용자 또는 제 3자가 책임을지지 않습니다. 제 3 자. 이러한 경우에는 법을 위반 한 사용자 또는 제 3 자만이 책임을집니다.
        제품은 사용자가 게임을 할 수있는 플랫폼을 제공합니다.
      </p>
     <p class = "p-content">
        <b> 【계약 체결 시점】 </b> 제품에 대한 액세스를 제공하고 본 이용 약관 및 개인 정보 보호 정책을 동시에 숙지하기위한 동의. 사용자는 본 이용 약관 및 개인 정보 보호 정책에 대한 사전 동의없이 제품에 액세스하고 사용할 수 없습니다.
        사용자는 제품에 대한 액세스를 제공하기위한 계약을 해지 할 수 있으며, 이유를 밝히지 않고 즉시 효력을 발휘합니다.
        사용자는 언제든지 제품 사용을 중지 할 수 있습니다.
      </p>

      <p class = "p-content">
        <b> 【불만 사항】 </b> 제공자가 여기에 명시된 의무를 이행하지 않거나 제공자가 본 조항에 반하는 방식으로이를 이행하는 경우 사용자는 불만을 제기 할 권리가 있습니다.
        불만 사항은 이메일 주소 {email}을 통해 제기 할 수 있습니다. 불만은 특히 불만의 근거를 구성하는 문제와 사용자 식별 정보 (이름, 이메일 주소)를 포함해야합니다.
        제공자는 추가 정보가 필요하고 사용자가이를 즉시 제공 할 의무가있는 경우 사용자에게 연락 할 권리를 보유합니다.
        사용자의 불만을 적절한 형식으로 접수 한 날로부터 14 일 (근무일 기준) 이내에 공급자는 다음을 수행합니다.
        내부 조사를 완료하고 사용자에게 결정을 통보합니다. 또는
        조사를 완료하는 데 시간이 더 필요하다고 사용자에게 알립니다.
        지정된 기한 내에 불만 사항을 고려할 수없는 경우, 제공자는 불만을 제출 한 사용자에게 그러한 지연 사유와 불만 해결 예정일을 이메일로 통지하고 추가로 사용자에게 주간 업데이트를 제공해야합니다. 제공자가 사용자의 응답을 기다리고 있고 사용자가 제공자가 사용자의 응답을 요구한다고 통보받은 경우를 제외하고 조사 진행 상황.
        불만 사항은 불만 사항의 이유가 분명해진 시점에서 달력으로 삼십 (30) 일 이내에 제출해야합니다.
        조사가 완료되면 공급자는 본 이용 약관의 관련 조항을 참조하여 결과 및 결정 이유를 사용자에게 알립니다.
        사용자가 제기 된 불만 사항에 대한 공급자의 결정에 만족하지 않는 경우 사용자는 해당 문제를 외부 독립 분쟁 해결로 가져갈 수 있습니다.
        불만 사항에 대한 답변은 사용자가 불만 사항을 제출 한 이메일 주소로 발송됩니다.
        공급자는 현행법, 본 이용 약관의 조항 또는 제품에 발표 된 정보를 무시하여 발생하는 불만을 고려하지 않습니다.
        불만 처리 절차의 조건을 위반하는 경우 불만 사항은 고려되지 않을 수 있습니다.
      </p>

     <p class = "p-content">
        <b> 【지적 재산】 </b> 제품 및 이와 관련된 모든 권리는 공급자 또는 제 3 자의 독점 재산입니다. 이 사이트에 배치 된 모든 창의적 요소는 지적 재산권, 특히 저작권에 의해 보호됩니다. 제품에 나타나는 모든 상표, 로고, 그래픽, 사진, 애니메이션, 비디오, 텍스트 및 기타 고유 한 기호는 공급자 또는 제 3 자의 지적 재산입니다. 따라서 공급자 또는 제 3 자의 사전 서면 승인 없이는 복제, 사용 또는 표현할 수 없습니다.
        사용자는 이에 따라 제공자 또는 제 3자가 부여받은 지적 재산권 (저작자의 경제적 권리 및 산업 재산권, 상표 등록으로 인한 권리 포함)을 존중해야합니다.
        사용자가 본 이용 약관에 따른 모든 의무를 준수한다는 조건하에 공급자는 사용자에게 다운로드, 설치, 백업에 대한 제한적, 취소 가능, 비 독점, 양도 불가능, 재 라이선스 불가능한 권한을 부여합니다. , 제공자가 제품을 사용하려는 의도에 따라, 특히 다음 조건을 충족하는 경우에만 제공되는 문서 파일 또는 웹 사이트 정보를 포함하여 제품을 사용합니다. (i) 제품이 어떤 방식 으로든; (ii) 모든 저작권 및 소유권 고지 또는 레이블은 원래 형식으로 제품에 유지됩니다. (iii) 제품의 사용은 오로지 사용자의 비상업적 (내부) 목적을위한 것입니다. 공급자는 묵시적이든 다른 방식 으로든 사용자에게 다른 권한을 부여하지 않습니다. 사용자는 제 3 자에게 다음을 허용하거나 허용하지 않습니다. (i) 제품의 파생물을 복사, 수정, 개조, 번역 또는 기타 방식으로 생성하는 행위 (ii) 해당 법률이 특별히 그러한 제한을 금지하는 경우를 제외하고 제품의 소스 코드를 리버스 엔지니어링, 디 컴파일, 디스 어셈블하거나 다른 방식으로 발견하려는 시도 (iii) 제품에 대한 임대, 재 라이선스, 임대, 판매, 양도 또는 기타 방식으로 권리를 양도 (또는 동일한 작업을 수행하려는 의도) (iv) 제품의 작동을 방해하거나 방해하려고 시도하는 장치, 소프트웨어 또는 루틴을 사용, 게시, 전송 또는 도입하는 행위. 사용자는 제품 사용 및 액세스와 관련된 모든 관련 법률 및 규정을 준수해야합니다. 사용자는 제 3 자에게 제품에 대한 액세스를 제공 할 수 없습니다. 위에서 부여한 라이선스와 사용자의 제품 사용 권한은 사용자가 본 이용 약관을 준수하지 않을 경우 즉시 종료됩니다.
        제품은 판매되는 것이 아니라 라이센스가 부여되고 있으며 공급자는 제품에 대한 지적 재산권을 포함하여 제품에 대한 모든 소유권을 항상 명시 적으로 보유하고 유지합니다. 본 사용 약관은 사용자에게 제품에 대한 소유권을 부여하는 것으로 해석되지 않으며, 여기에 명시된대로이 라이선스가 해지 될 때까지 사용자에게 제품을 사용할 수있는 제한된 라이선스 만 제공합니다. 사용자는 저작권, 영업 비밀 또는 상표법에 따른 모든 지적 재산권을 포함한 제품이 공급자의 소유임을 인정합니다. 공급자는 사용자가 본 이용 약관을 위반하여 제품을 사용하는 경우 모든 법적 구제 조치를 취할 수 있습니다.
        사용자는 본 사용 약관을 위반하여 제품을 사용하는 경우 (제품의 복사, 배포 또는 파생물 제작을 포함하되 이에 국한되지 않음) 사용자는 형사 및 / 또는 민사 벌금 및 처벌을받을 수 있음을 인정합니다. 관련 법률에 따라.
        사용자가 제품을 사용하는 조건으로 사용자는 불법적이거나 본 이용 약관에서 금지하는 목적으로 제품을 사용하지 않을 것임을 보증합니다. 사용자는 제품 사용시 사용자에게 적용되는 모든 현지 규칙을 준수하는 데 동의합니다. 사용자는 제품의 일부를 상업적 목적으로 복제, 재판매 또는 악용하지 않을 것에 동의합니다. 사용자가 이러한 조건을 위반하면 제품 사용 라이센스가 자동으로 종료됩니다.
        제품은 합법적 인 목적과 사용을 위해서만 제공됩니다. 사용자는 본 이용 약관의 조건에 따라 제품 사용을 감독하고 통제하는 데 동의합니다.
        제품은 공급자에 의해 "있는 그대로"사용자에게 제공되며 제품 사용에 따른 위험은 사용자 자신에게 있습니다. 사용자는 제품에 시스템 또는 기타 장애 및 데이터 손실을 유발할 수있는 버그, 오류 및 기타 문제가 포함될 수 있음을 인정합니다. 공급자는 제품의 성능을 보증하지 않으며 제품이 제품과 함께 제공되는 문서에 따라 실질적으로 작동 할 것이라고 보증하지 않습니다. 제품은 사용자의 레지스트리 및 기타 파일을 변경할 수 있으며 사용자는 이러한 변경에 대한 모든 위험을 감수합니다. 제품을 사용하거나 실행하기 전에 컴퓨터와 모든 중요한 데이터를 백업하십시오.
        법률이 허용하는 최대 범위 내에서 공급자는 상품성, 특정 목적에 대한 적합성 및 비 침해에 대한 묵시적 보증을 포함하되 이에 국한되지 않는 모든 종류의 명시 적 또는 묵시적, 형평성 또는 법적 보증을 부인합니다. 공급자는 제품에 포함 된 기능이 사용자가 가질 수있는 요구 사항 또는 요구 사항을 충족하거나 제품이 오류없이 또는 중단없이 작동하거나 제품의 결함 또는 오류가 수정 될 것임을 보증하지 않습니다. , 또는 제품이 특정 플랫폼과 호환되는지 여부. 공급자는 사용자에게 제품의 가장 최근에 개발 또는 배포 된 버전을 제공 할 것이라는 보증을하지 않습니다. 사용자는 자신이 제품의 최신 버전을 가지고 있는지 확인하는 데 전적인 책임이 있습니다.
        사용자는 제품 사용 또는 설치의 결과 또는 사용자의 컴퓨터 및 모바일 장치의 변경으로 인해 사용자가 입은 모든 손실 또는 손해에 대해 최종적으로 자신의 책임이 있음을 인식하고 이에 동의합니다. 법률이 허용하는 최대 범위 내에서, 제공자는 어떠한 상황이나 사건에서도 부수적 또는 결과적 손해 (직간접 적, 부수적, 특수, 처벌 또는 제품의 사용 또는 사용 불능으로 인해 발생하는 비즈니스 손실, 수익 또는 수익 손실, 비즈니스 중단 또는 비즈니스 정보 또는 데이터 손실에 대한 예시 적 손해 또는 제공자가 제공하는 경우에도 다른 당사자의 클레임 그러한 손해의 가능성 또는 손해가 예측 가능한지에 대해 통보 받았습니다.
      </p>

     <p class = "p-content">
        <b> 【개인 데이터 보호 및 개인 정보 보호 정책】 </b> 제공 업체 데이터에 의한 사용자 개인의 수집, 사용 및 공개는 개인 정보 보호 정책의 조항에 따릅니다. 사용자는 개인 정보 보호 정책에 명시된 상황에서 사용자의 개인 데이터 사용 및 공개에 동의합니다.
        사용자는 사용자가되기로 결정하기 전에 전체 개인 정보 보호 정책을주의 깊게 읽어야합니다.
        사용자가 본 이용 약관 또는 현행법을 위반하여 제품을 사용하는 경우 공급자는 책임을 설정하는 데 필요한 범위 내에서 사용자의 개인 데이터를 사용할 권리가 있습니다. 이 경우 제공자는 즉시 중단을 요구하고 불법 행위를 저지른 사실을 사용자에게 알려야합니다.
      </p>

      <p class = "p-content">
        <b> 【결제】 </b> 제품에 대한 결제는 제 3 자 서비스를 통해 처리됩니다. 공급자는 지불 처리에 대한 책임이 없으며 모든 문제는 지불 공급자와 해결해야합니다.
      </p>
     <p class = "p-content">
        <b> 【최종 조항】 </b> 이용 약관은 여기에서 확인할 수 있습니다.
        제공자는 언제라도 본 이용 약관을 수정, 보완, 교체 또는 변경할 권리가 있으며, 특히 다음 이유 중 하나 이상으로 인해 지불 할 요금 변경, 새로운 요금 도입, 새로운 이용 약관 도입 등을 통해 변경할 수 있습니다.
        관련 법률 또는 실행 규범의 변경 또는 예상되는 변경을 준수하기 위해
        법원, 옴부즈맨 또는 규제 기관의 결정을 반영하기 위해
        보안상의 이유를 포함하여 시스템 또는 절차의 변경을 반영하기 위해
        변경된 상황의 결과 (제품에 혜택 또는 새로운 기능 추가 포함)
        제품 제공 비용의 변화에 ​​비례하여 대응합니다. 또는
        더 명확하게합니다.
        어떤 법률이 공급자가 결정한 변경을 규제하는 경우 공급자는 법률에서 허용하는 범위까지만 변경할 수 있습니다.
        제품의 보안을 복원하거나 유지하기 위해 공급자의 관점에서 변경이 필요한 경우 사용자에게 사전 통지없이 즉시 변경 될 수 있습니다.
        본 이용 약관의 수정, 보충, 교체 또는 기타 변경의 경우 사용자는이 사이트의 홈 페이지에 게시 된 적절한 공지를 통해 이에 대한 정보를 받게됩니다. 수정, 보충, 교체 또는 기타 변경 사항은 공급자가 지정한 날짜에 적용되며, 해당 날짜는 해당 발표일로부터 달력으로 14 일 이전 일 수 없습니다. 본 이용 약관의 수정, 보완, 교체 또는 기타 변경에 동의하지 않는 사용자는 공급자와의 계약을 해지 할 수 있으며 즉시 다음 이메일 주소 : service @ netboom으로 관련 선언을 보내 공급자에게 알려야합니다. com. 해지는 이용자의 성명을받은 시점부터 효력이 발생하지만 개정 된 이용 약관의 발효 일 이전에 효력이 발생하지 않습니다. 본 이용 약관의 수정, 보충, 교체 또는 기타 변경이 효과적으로 이루어진 후에도 본 사이트를 계속 사용하는 것은 사용자가 수정 된 이용 약관에 동의 함을 나타냅니다.
        본 계약의 어느 당사자도 상대방의 사전 동의없이 계약에 따른 권리 나 의무를 양도하거나 양도 할 수 없습니다. 공급자와 관련된 합병, 개편 또는 구조 조정 또는 공급자에 의한 사업 매각에 따라 수행되는 경우 공급자는 사용자에게 통지하여이 약관에 따라 권한을 양도 또는 양도하거나 권리와 의무를 혁신 할 수 있습니다. 사용 및 사용자는 그러한 양도, 양도 또는 개보수에 동의 한 것으로 간주됩니다.
        이용 약관은 법률 원칙의 충돌과 관계없이 중화 인민 공화국 법률의 적용을받습니다.
        이 이용 약관에 대한 질문이나 의견이 있으시면 {email}을 통해 저희에게 연락하십시오.
      </p>
  `
