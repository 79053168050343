export default [
  {
    path: '/game',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'Game'
    },
    children: [
      {
        path: '/game/html',
        name: 'GameHtml',
        component: () => import('@/views/game/game-html.vue'),
        meta: {
          title: 'GameHtml'
        }
      }
    ]
  }
]
