export default {
  slogan: 'Ưu đãi tuyệt vời chỉ dành cho vip!',
  introduce_1: '20 giờ chơi game mỗi tháng',
  introduce_2: 'Hàng đợi ưu tiên cao độc quyền',
  introduce_3: 'Tất cả các trò chơi có sẵn',
  encourage_only: 'CHỈ {money}',
  encourage_discounts: 'TIẾT {discounts}%',
  monthUnit: 'THÁNG',
  agreementInstructionMain: 'Thanh toán định kỳ, hủy bất kỳ lúc nào',
  agreementInstructionTitle: 'Hướng dẫn dịch vụ gia hạn tự động',
  agreementInstructionItem_1: '1. Sau khi mở, tự động gia hạn sẽ được hoạt động trong mỗi tháng tự nhiên và gia hạn dịch vụ VIP thêm một tháng;',
  agreementInstructionItem_2: '2. Nếu bạn muốn hủy bỏ việc gia hạn, vui lòng đóng nó trong vòng ít nhất 48 giờ trong thời gian khấu lưu;',
  agreementInstructionItem_3: '3. Cách hủy: bạn có thể hủy bất kỳ lúc nào trong Đăng ký trên Paypal'
}
