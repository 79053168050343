export default {
  slogan: 'Tawaran hebat untuk VIP sahaja!',
  introduce_1: '20 jam masa permainan setiap bulan',
  introduce_2: 'Baris keutamaan tinggi eksklusif',
  introduce_3: 'Semua permainan ada',
  encourage_only: 'HANYA {money}',
  encourage_discounts: 'JIMAT {discounts}%',
  monthUnit: 'SEBULAN',
  agreementInstructionMain: 'Pengebilan berulang, batalkan bila-bila masa',
  agreementInstructionTitle: 'Arahan perkhidmatan pembaharuan automatik',
  agreementInstructionItem_1: '1. Setelah dibuka, pembaharuan automatik akan dikendalikan pada setiap bulan semula jadi dan memperpanjang perkhidmatan VIP selama sebulan;',
  agreementInstructionItem_2: '2. Sekiranya anda ingin membatalkan pembaharuan, sila tutupnya sekurang-kurangnya 48 jam dalam tempoh penahanan;',
  agreementInstructionItem_3: '3. Cara membatalkan: anda boleh membatalkannya pada bila-bila masa dalam Langganan di Paypal'
}
