import Vue from 'vue'
import Vuex from 'vuex'
import service from '@/store/service'
import pay from '@/store/pay.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    service,
    pay
  }
})
