export default `
<p class = "p-content">
<b>Perjanjian Perkhidmatan VIP</b>
</p>
<p class = "p-content">
Selamat datang untuk menggunakan perkhidmatan VIP kami (selepas ini disebut sebagai "Perkhidmatan"). Sila baca perjanjian ini dengan teliti sebelum anda menggunakan Perkhidmatan kami. Dengan melanggan Perkhidmatan kami, anda bersetuju untuk terikat dengan terma dan syarat seperti berikut,
</p>
<p class = "p-content">
<b>1. Penerangan Perkhidmatan</b>
</p>
<p class = "p-content">
1.1 Perkhidmatan boleh menjadi langganan bulanan berulang secara automatik. Setelah anda membeli Perkhidmatan melalui NetBoom, anda akan diminta untuk mengesahkan pembelian anda dengan penyedia pembayaran yang berlaku, dan kaedah pembayaran anda (baik itu kad anda atau akaun pihak ketiga seperti Google Play atau iTunes) ("Kaedah Pembayaran" anda) akan dikenakan untuk pembelian dalam aplikasi dengan harga yang ditunjukkan kepada anda untuk Perkhidmatan serta penjualan atau cukai serupa yang mungkin dikenakan pada pembayaran anda.
</p>
<p class = "p-content">
1.2 Langganan diperbaharui secara automatik, sehingga anda menghentikan atau membatalkan langganan. Setelah tempoh komitmen langganan awal anda, dan sekali lagi setelah tempoh langganan berikutnya, langganan anda akan secara automatik berlanjutan untuk tempoh tambahan yang setara, pada harga yang anda setujui semasa melanggan.
</p>
<p class = "p-content">
1.3 Kaedah Pembayaran anda akan terus ditagih setiap bulan lebih awal dalam tempoh 24 jam dari tarikh pembelian awal dengan harga yang anda setujui ketika mula-mula melanggan. Maklumat pembayaran kad anda akan disimpan dan kemudian digunakan untuk pembayaran kad automatik sesuai dengan Perjanjian.
</p>
<p class = "p-content">
1.4 Selepas setiap pembayaran, anda akan menerima resit melalui e-mel. Keberatan terhadap pembayaran yang telah dibuat, harus ditujukan kepada sokongan Pelanggan jika anda ditagih langsung oleh {project} atau akaun pihak ketiga yang berkaitan seperti Google Play. Anda juga dapat membantah dengan menghubungi bank atau penyedia pembayaran anda, yang dapat memberikan maklumat lebih lanjut mengenai hak anda dan juga had masa yang berlaku.
</p>
<p class = "p-content">
1.5 Anda tidak boleh dibatalkan {project} akaun pihak ketiga, sebagaimana yang berlaku, untuk menagih anda, tanpa pengesahan kelayakan, termasuk tetapi tidak terhad kepada kata laluan anda, maklumat identiti anda, yuran langganan selama 30 hari berikutnya sebelum tamatnya 30 hari untuk anda langganan semasa. Pengisian yang tidak berjaya akan dianggap sebagai penamatan Perkhidmatan anda
</p>
<p class = "p-content">


<b>2. Hak</b>
</p>
<p class = "p-content">
Dengan melanggan Perkhidmatan, anda berhak mendapat hak seperti yang ditunjukkan oleh halaman VIP.

 </p>
<p class = "p-content">

<b>3. Bayaran Perkhidmatan</b>
</p>
<p class = "p-content">
{project} boleh menyesuaikan Bayaran Perkhidmatan secara berkala dan menunjukkan penyesuaian tersebut kepada anda untuk pengesahan.
</p>
<p class = "p-content">


<b>4. Penamatan</b>
</p>
<p class = "p-content">
4.1 Anda boleh menamatkan Perkhidmatan setiap kali anda memilih untuk melakukannya dengan syarat anda bersetuju bahawa {project} tidak akan mengembalikan yuran perkhidmatan kepada anda untuk tempoh komitmen langganan semasa anda.
</p>
<p class = "p-content">
4.2 {project} berhak mutlak untuk menghentikan Perkhidmatan dengan sebab atau tanpa sebab. {project} tidak akan bertanggungjawab kepada anda sekiranya {project} menamatkan Perkhidmatan sesuai dengan perintah atau keputusan dari pihak berkuasa yang berwibawa atau jika {project} secara munasabah percaya bahawa tingkah laku anda melanggar peraturan platform {project}.
</p>
<p class = "p-content">
4.3 {project} akan mengembalikan kepada anda yuran perkhidmatan untuk tempoh komitmen langganan semasa anda sekiranya {project} menghentikan Perkhidmatan tanpa sebab.
</p>
<p class = "p-content">
<b>5. Pelbagai</b>
</p>
<p class = "p-content">
5.1 Perjanjian ini adalah tambahan kepada Perjanjian Pengguna {project} yang telah anda buat dengan {project} semasa mendaftar di platform kami.
</p>
<p class = "p-content">
5.2 Sebarang perbezaan atau perbezaan antara Perjanjian ini dan Perjanjian Pengguna {project}, Perjanjian ini akan berlaku.
</p>
`
