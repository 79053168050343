export default `
<h3>Terms</h3>
<h4>I. Membership and Admissions</h4>
<p>A. {project} operates under a closed membership policy. Membership is granted or refused based solely on the judgment of the administration and is not a right. Membership can be revoked at any time for any reason. Each accepted member is granted one, and only one, username. If you are caught using multiple usernames, you will be subject to immediate and permanent banning of all accounts involved. If you wish to change your username, simply notify a staff member via Private Message (though bear in mind that frequent changes will be frowned upon).</p>
<p>B. Registration must be performed with an Internet Service Provider, education, or workplace email address. No other types of email addresses will be accepted, including all free email addresses (@gmail), addresses tied to personal domains (@myrandomblog), addresses part of standalone paid accounts (@me), or any of a limited number of domains that have been blacklisted for abuse despite otherwise falling within the bounds of ISP/education/workplace (@aol). The approval process is manual and subject to a significant waiting period.</p>
<p>C. By registering an account at {project} and participating (including but not limited to submitting posts or private messages on netboom.com), you hereby grant Netboom Game + and our licensees, distributors, agents, representatives and other authorized users, a perpetual, non-exclusive, irrevocable, fully-paid, royalty-free, sub-licensable and transferable (in whole or part) worldwide license under all copyrights, trademarks, patents, trade secrets, privacy and publicity rights and other intellectual property rights you own or control to use, reproduce, transmit, display, exhibit, distribute, index, comment on, modify, create derivative works based upon, perform and otherwise exploit the messages posted or private messages sent on netboom.com, in whole or in part, in all media formats and channels now known or hereafter devised, for any and all purposes including entertainment, news, advertising, promotional, marketing, publicity, trade or commercial purposes, all without further notice to you, with or without attribution, and without the requirement of any permission from or payment to you or to any other person or entity.</p>
<p>D. Users 13 years of age and under are prohibited from registering.</p>
<h4>II. Expected Behavior</h4>
<p>{project} is a forum for holding civil, evidence-based discussion. Do not post disingenuously, or in an inflammatory manner for the sole purpose of upsetting others. Negative commentary and minority opinions are not frowned upon, but members are expected to be able to substantiate their positions. At the same time, do not derail threads around yourself or attempt to respond to every single reaction among dozens after posting a comment that is reacted to severely en masse.</p>
<p>A. Language</p>
<p>You agree, through your use of this service, that you will not use {project} to post any material which is knowingly false and/or defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, profane, sexually oriented, threatening, invasive of a person's privacy, or otherwise in violation of any law. Sexual, racial, or ethnic slurs will not be tolerated in any form and are bannable on the first offense. It is recommended that profanity be held to a minimum in order to keep the level of discourse high, and profanity should not be used when addressing others. Though {project} is an international forum, discussion is to be held in English unless otherwise designated.</p>
<p>B. Images</p>
<p>Images of a sexual or profane nature are discouraged. Images presenting exposed genitalia, bared buttocks, and/or female nipple/aureola are not allowed. Outside of this, common sense and a "safe-for-work" rule should be the primary considerations. Do not spam meme images or any other image macro. Avatar images should be static and entirely "G-rated" and family-friendly.</p>
<p>C. Copyrighted Material</p>
<p>Full transcriptions of articles and whole or partially-scanned images (e.g. magazine scans) are prohibited. Standard fair-use policies apply, and sources should always be attributed.</p>
<p>D. Emulation/Piracy</p>
<p>The topics of emulation and piracy in the context of the technical nature of emulators and ROM images, hardware modification technology, as well as their effect on the industry as a political topic are deemed to be generally acceptable.</p>
<p>Linking to pirate download sites, directions on how to get pirated software to work, reviews or impressions of pirated software, and livestreams of pirated software play are all strictly prohibited.</p>
<p>E. Self-Promotion and Spam</p>
<p>Do not create new threads promoting a product or service you have any involvement in, or paste PR materials for promotional purposes, or attempt to embed referral links in your posts to generate revenue. If an existing discussion is taking place directly involving something you are associated with, you may participate in order to answer questions, clarify details, and otherwise engage with the community, but as a person, not an advertisement.</p>
<h4>III. Moderation Policy</h4>
<p>A. Moderation of {project} is not by democracy. All decisions with regards to thread closure and movement, permanent and temporary bans, membership, and any other relevant issues are made by the {project} administration alone and are final. That being said, constructive criticisms and suggestions are welcome, provided they are directed to {project} Administration via e-mail. Do not dispute administrative policy or action within the forum itself.</p>
<p>B. Considering the real-time nature of {project}, it is impossible for us to review messages or confirm the validity of information posted. Please remember that we do not actively monitor the contents of and are not responsible for any messages posted. We do not vouch for or warrant the accuracy, completeness or usefulness of any message, and are not responsible for the contents of any message. The messages express the views of the author of the message, not necessarily the views of Netboom Game + or any entity associated with it. Any user who feels that a posted message is objectionable is encouraged to contact us immediately by email. We have the ability to remove objectionable messages and we will make every effort to do so, within a reasonable time frame, if we determine that removal is necessary. This is a manual process, however, so please realize that we may not be able to remove or edit particular messages immediately.</p>
<p>C. Although we cannot review every messages posted, and are not responsible for the content of any of these messages, we reserve the right to edit or delete any message for any reason. You remain solely responsible for the content of your messages, and you agree to indemnify and hold Netboom Game + harmless with respect to any claim based upon transmission of your message(s).</p>
<p>D. We reserve the right to reveal your identity (or whatever information we know about you), typically in (but not limited to) the event of a complaint or legal action arising from any message posted by you.</p>
<p>These aforementioned Terms of Service are subject to change at any time without notification.</p>
`
