export default {
  slogan: 'Penawaran hebat hanya untuk vip!',
  introduce_1: '20 jam waktu bermain game setiap bulan',
  introduce_2: 'Antrian prioritas tinggi eksklusif',
  introduce_3: 'Semua game tersedia',
  encourage_only: 'HANYA {money}',
  encourage_discounts: 'HEMAT {discounts}%',
  monthUnit: 'BULAN',
  agreementInstructionMain: 'Penagihan berulang, batalkan kapan saja',
  agreementInstructionTitle: 'Instruksi layanan perpanjangan otomatis',
  agreementInstructionItem_1: '1. Setelah pembukaan, perpanjangan otomatis akan dioperasikan pada setiap bulan dan memperpanjang layanan VIP selama satu bulan;\n',
  agreementInstructionItem_2: '2. Jika Anda ingin membatalkan pembaruan, harap tutup dalam waktu setidaknya 48 jam selama periode pemotongan;',
  agreementInstructionItem_3: '3. Cara membatalkan: Anda dapat membatalkan kapan saja di Langganan di Paypal'
}
