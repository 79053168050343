export default `
<b>Privacy</b>
<p>{project} respects your privacy. This document explains our policy relating to the personal data that is received by us.</p>
<b>Information Collected</b>
<p>{project}'s server automatically records information when you visit our website, including your IP address and browser used. Additionally, at the time of registration, we retain data on your email address and any other information that you choose to denote in your profile. We also use cookies to store your account information for a convenient browsing experience.</p>
<b>Uses</b>
<p>Third parties outside of {project} may receive aggregated non-personal information. Under limited circumstances we may share personal information with third parties as well, including when complying with legal matters and also when ensuring the security of our site and related services.</p>
<b>Your Choices</b>
<p>Personal information fields in your profile, outside of the necessary information to process and approve your account, are entirely optional and at your discretion to provide. Your account information at signup, specifically your valid ISP, college, or workplace email address, is necessary to post on {project} and to ensure that we are not attacked by anonymous, malicious users. If you do not wish to provide this information, please do not register to post at {project}, as you can still read select subforums as an unregistered guest.</p>
`
