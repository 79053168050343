export default `
<p class = "p-content">
<b>VIP 서비스 계약</b>
</p>
<p class = "p-content">
VIP 서비스 (이하 "서비스"라고 함)를 이용해 주셔서 감사합니다. 당사 서비스를 사용하기 전에이 계약을주의 깊게 읽으십시오. 당사 서비스에 가입함으로써 귀하는 다음과 같은 이용 약관에 동의하는 것으로 간주됩니다.
</p>
<p class = "p-content">
<b>1. 서비스 설명</b>
</p>
<p class = "p-content">
1.1 서비스는 자동 반복 월간 구독 일 수 있습니다. {project}을 통해 서비스를 구매 한 후 해당 결제 제공 업체와 결제 방법 (카드 또는 Google Play 또는 iTunes와 같은 제 3 자 계정) (귀하의 "결제 방법")을 통해 구매를 확인하라는 메시지가 표시됩니다. 귀하의 지불에 부과 될 수있는 판매 또는 유사한 세금뿐만 아니라 서비스에 대해 귀하에게 표시된 가격으로 인앱 구매에 대해 청구됩니다.
</p>
<p class = "p-content">
1.2 구독은 귀하가 구독을 종료하거나 취소 할 때까지 자동으로 갱신됩니다. 최초 구독 약정 기간이 지나고 이후의 구독 기간이 지나면 구독은 구독시 동의 한 가격으로 추가 동등한 기간 동안 자동으로 계속됩니다.
</p>
<p class = "p-content">
1.3 귀하의 결제 방법은 처음 구독 할 때 동의 한 가격으로 최초 구매 일로부터 24 시간 이내에 매월 사전 청구됩니다. 귀하의 카드 결제 정보는 저장되고 이후 계약에 따라 자동 카드 결제에 사용됩니다.
</p>
<p class = "p-content">
1.4 각 결제 후 이메일로 영수증을 받게됩니다. 이미 이루어진 결제에 대한 이의는 {project} 또는 Google Play와 같은 관련 제 3 자 계정으로 직접 청구 된 경우 고객 지원팀으로 전달해야합니다. 또한 귀하의 권리와 해당 시간 제한에 대한 추가 정보를 제공 할 수있는 귀하의 은행 또는 지불 제공 업체에 연락하여 이의를 제기 할 수 있습니다.
</p>
<p class = "p-content">
1.5 귀하는 해당되는 경우 {project}이 귀하의 비밀번호, 귀하의 신원 정보, 귀하의 30 일 만료 전 다음 30 일에 대한 가입비를 포함하되 이에 국한되지 않는 자격 증명 확인없이 귀하에게 청구 할 수있는 제 3 자 계정을 취소 할 수 없습니다. 현재 구독. 실패한 청구는 서비스 종료로 간주됩니다.
</p>
<p class = "p-content">


<b>2. 자격</b>
</p>
<p class = "p-content">
서비스에 가입하면 VIP 페이지에 표시된대로 자격을 부여받을 수 있습니다.
</p>
<p class = "p-content">


<b>3. 서비스 수수료</b>
</p>
<p class = "p-content">
{project}은 정기적으로 서비스 요금을 조정하고 확인을 위해 그러한 조정을 표시 할 수 있습니다.
</p>
<p class = "p-content">


<b>4. 해지</b>
</p>
<p class = "p-content">
4.1 귀하는 {project}이 귀하의 현재 가입 약정 기간 동안 귀하에게 서비스 요금을 환불하지 않는다는 데 동의하는 경우 언제든지 서비스를 종료 할 수 있습니다.
</p>
<p class = "p-content">
4.2 {project}은 이유 또는 이유없이 서비스를 종료 할 수있는 절대적인 재량권을 보유합니다. {project}이 관할 당국의 명령이나 법령에 따라 서비스를 종료하거나 {project}이 귀하의 행위가 {project} 플랫폼의 규정을 위반한다고 합리적으로 판단하는 경우 {project}은 귀하에게 책임을지지 않습니다.
</p>
<p class = "p-content">
4.3 {project}은 아무런 이유없이 서비스를 종료 할 경우 현재 가입 약정 기간 동안 서비스 요금을 환불합니다.
</p>
<p class = "p-content">
<b>5. 기타</b>
</p>
<p class = "p-content">
5.1 본 계약은 귀하가 당사 플랫폼에 등록 할 때 {project}과 체결 한 {project} 사용자 계약의 추가 조항입니다.
</p>
<p class = "p-content">
5.2 본 계약과 {project} 사용자 계약 사이의 차이 또는 불일치는 본 계약이 우선합니다.
</p>
`
