import PrivacyPolicyqf from '@/i18n/agreements/PrivacyPolicyqf'
import ServiceClauseqf from '@/i18n/agreements/ServiceClauseqf'
import VIPServiceAgreement from '@/i18n/agreements/VIPServiceAgreement'
import IOSPrivacy from '@/i18n/agreements/IOSPrivacy'
import IOSTerms from '@/i18n/agreements/IOSTerms'
import TreasureBox from '@/i18n/rules/TreasureBox'
import NetboomPrivacyPolicyqf from '@/i18n/netboom/PrivacyPolicyqf'
import NetboomServiceClauseqf from '@/i18n/netboom/ServiceClauseqf'
import NetboomVIPServiceAgreement from '@/i18n/netboom/VIPServiceAgreement'

import subscribe from '@/i18n/subscribe/th.js'

export default {
  subscribe,
  rules: {
    TreasureBox: TreasureBox.th
  },
  agreement: {
    PrivacyPolicyqf: PrivacyPolicyqf.th,
    ServiceClauseqf: ServiceClauseqf.th,
    VIPServiceAgreement: VIPServiceAgreement.th,
    NetboomPrivacyPolicyqf: NetboomPrivacyPolicyqf.th,
    NetboomServiceClauseqf: NetboomServiceClauseqf.th,
    NetboomVIPServiceAgreement: NetboomVIPServiceAgreement.th,
    IOSPrivacy: IOSPrivacy.th,
    IOSTerms: IOSTerms.th
  },
  tips: {
    Redeem: 'เข้าร่วม netboom ใน discord เพื่อเข้าร่วมกิจกรรมชุมชนและลุ้นรับรางวัล'
  },
  common: {}
}
