export default `
<div id="write" class="is-mac">
    <h2><a name="header-n0" class="md-header-anchor "></a>Terms of Use</h2>
    <p>"App" as used here in refers to all products and services owned and operated by <strong>{project} Team</strong>.
    </p>
    <h3><a name="header-n3" class="md-header-anchor "></a>Terms</h3>
    <p>By using <strong>{project}</strong> (the "App"), you are agreeing to be bound by these terms of use, all applicable
      laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do
      not agree with any of these terms, you are prohibited from using the App. The materials contained in this App are
      protected by applicable copyright and trademark law.</p>
    <h3><a name="header-n5" class="md-header-anchor "></a>Use License</h3>
    <p>Permission is granted to temporarily download one copy of the materials (information or software) for personal,
      non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this
      license you may not:</p>
    <ul>
      <li>modify or copy the materials;</li>
      <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
      <li>attempt to decompile or reverse engineer any software contained within the app;</li>
      <li>remove any copyright or other proprietary notations from the materials; </li>
      <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
    </ul>
    <p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by
      <strong>{project}</strong> at any time. Upon terminating your viewing of these materials or upon the termination of
      this license, you must destroy any downloaded materials in your possession whether in electronic or printed
      format.</p>
    <h3><a name="header-n19" class="md-header-anchor "></a>Disclaimer</h3>
    <ul>
      <li>The materials within <strong>{project}</strong> App are provided on an 'as is' basis. <strong>{project}</strong>
        makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including,
        without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or
        non-infringement of intellectual property or other violation of rights.</li>
      <li>Further, <strong>{project}</strong> does not warrant or make any representations concerning the accuracy, likely
        results, or reliability of the use of the materials within the app.</li>
    </ul>
    <h3><a name="header-n25" class="md-header-anchor "></a>Limitations</h3>
    <p>In no event shall <strong>{project}</strong> or its suppliers be liable for any damages (including, without
      limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or
      inability to use the App and the materials within <strong>{project}</strong>, even if <strong>{project}</strong>
      authorized representative has been notified orally or in writing of the possibility of such damage. Because some
      jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or
      incidental damages, these limitations may not apply to you.</p>
    <h3><a name="header-n27" class="md-header-anchor "></a>Accuracy of materials</h3>
    <p>The materials appearing within <strong>{project}</strong> could include technical, typographical, or photographic
      errors. <strong>{project}</strong> does not warrant that any of the materials on its website are accurate, complete
      or current. <strong>{project}</strong> may make changes to the materials contained on its website at any time
      without notice. However <strong>{project}</strong> does not make any commitment to update the materials.</p>
    <h3><a name="header-n29" class="md-header-anchor "></a>Modifications</h3>
    <p><strong>{project}</strong> may revise these terms of use at any time without notice. By using the App you are
      agreeing to be bound by the then current version of these terms of use.</p>
    <h3><a name="header-n31" class="md-header-anchor "></a>Access</h3>
    <p>You must provide at your own expense the equipment, Internet connections or mobile devices and/or service plans
      to access and use this app. <strong>{project}</strong> does not guarantee that <strong>{project}</strong> app is
      available in all geographic locations. You acknowledge that when You use <strong>{project}</strong> app, Your
      wireless carrier may charge you fees for data, messaging, and/or other wireless access. Check with your carrier to
      see if there are any such fees that apply to you. You are solely responsible for any costs You incur to access
      this application from your mobile device and/or PC device. Your right to use <strong>{project}</strong> app is also
      predicated on your compliance with any applicable terms of agreements you have with third parties when using the
      services.</p>
    <h3><a name="header-n33" class="md-header-anchor "></a>Governing Law and Disputes Resolution</h3>
    <p>Except to the extent that: (i) any applicable additional terms incorporated into these Terms provide differently,
      or (ii) the applicable laws and regulations of your jurisdiction mandate otherwise, the Agreement and any dispute
      or claim arising out of or in connection with the Agreement will be governed by the laws of the People’s
      Republican of China.</p>
    <p>Any dispute, controversy or claim (whether in contract, tort or otherwise) arising out of, relating to, or in
      connection with the Agreement, including their existence, validity, interpretation, performance, breach or
      termination, will be referred to and finally resolved by arbitration administered by the China International
      Economic and Trade Arbitration Commission (CIETAC), and the place of arbitration shall be in Beijing. Within the
      valid period for the arbitration, such arbitration tribunal shall subject to the arbitration rules of the CIETAC
      in effect at the time of the arbitration. The award of the arbitration tribunal shall be final and binding upon
      the two parties.</p>
    <h3><a name="header-n36" class="md-header-anchor "></a>Definitions and Miscellaneous</h3>
    <ul>
      <li><strong>{project}</strong> is a software product developed, operated and managed by <strong>{project}
          Team</strong> with the aim to provide you with a royalty-free, non-exclusive and global software license and
        technical services within the scope of this Agreement.</li>
      <li><strong>{project}</strong> refers to <strong>{project} Team</strong>.</li>
    </ul>
    <h3><a name="header-n42" class="md-header-anchor "></a>Contact</h3>
    <p>If you have any questions, complaints or claims with respect to our Services, you may contact us at <a
        href="mailto:{email}" target="_blank" class="url" style="
        color: #990000;
        text-decoration: none;
      ">{email}</a></p>
    <p> </p>
  </div>`
