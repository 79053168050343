export default [
  {
    path: '/netboom',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'Netboom'
    },
    children: [
      {
        path: '/netboom/privacyPolicyqf',
        name: 'NetboomPrivacyPolicyqf',
        component: () => import('@/views/netboom-agreements/PrivacyPolicyqf.vue'),
        meta: {
          title: 'NetboomPrivacyPolicyqf'
        }
      },
      {
        path: '/netboom/ServiceClauseqf',
        name: 'NetboomServiceClauseqf',
        component: () => import('@/views/netboom-agreements/ServiceClauseqf.vue'),
        meta: {
          title: 'NetboomServiceClauseqf'
        }
      },
      {
        path: '/netboom/VipAgreements',
        name: 'NetboomVipAgreements',
        component: () => import('@/views/netboom-agreements/VIPServiceAgreement'),
        meta: {
          title: 'NetboomVipAgreements'
        }
      }
    ]
  }
]
