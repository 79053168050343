export default [
  {
    path: '/rules',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'Rules'
    },
    children: [
      {
        path: '/rules/treasure-box',
        name: 'TreasureBox',
        component: () => import('@/views/rules/TreasureBox'),
        meta: {
          title: 'TreasureBox'
        }
      }
    ]
  }
]
