export default {
  slogan: 'VIP만을위한 훌륭한 제안!',
  introduce_1: '매월 20 시간 게임 시간',
  introduce_2: '독점 높은 우선 순위 대기열',
  introduce_3: '모든 게임 가능',
  encourage_only: '{money} 만',
  encourage_discounts: '{discounts}% 절약',
  monthUnit: '달',
  agreementInstructionMain: '반복 청구, 언제든지 취소',
  agreementInstructionTitle: '자동 갱신 서비스 안내',
  agreementInstructionItem_1: '1. 개봉 후 자동 갱신은 매월 자연적으로 운영되며 VIP 서비스는 1 개월 연장됩니다',
  agreementInstructionItem_2: '2. 갱신을 취소하려면 원천 징수 기간 동안 최소 48 시간 이내에 취소하십시오',
  agreementInstructionItem_3: '3. 취소 방법 : Paypal 구독에서 언제든지 취소 할 수 있습니다'
}
