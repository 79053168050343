export default `
<p class =“ p-content”>
        請通讀以下使用條款。通過訪問或使用產品（定義如下），您特此同意受這些使用條款的約束並嚴格遵守這些使用條款，因為該條款可能會不時地進行修改或補充。如果您不接受這些使用條款，則不得使用產品。
      </p>

      <p class =“ p-content”>
        <b>【一般規定】</b>本《使用條款》中的大寫字母含義如下：
        提供者-提供對產品訪問權限的實體，即{project} PTE。 LTD。
        產品-符合這些使用條款的實用程序軟件，它是客戶端遠程桌面，用戶可以通過它訪問所有已安裝的程序和遊戲，欣賞存儲在用戶計算機上的視頻和音樂或玩PC遊戲以及所有Flash遊戲。
        使用條款-本文檔，指定使用產品的條款和條件以及用戶和提供者的權利和義務。使用條款構成用戶與提供者之間關於用戶使用產品的具有約束力的協議。
        用戶-在其管轄範圍內已達到成年年齡並完全有能力代表自己進行合法交易的個人，該個人根據使用條款使用產品。
        通過電子方式提供服務時，提供商應建立以下使用條款。
      </p>
      <p class =“ p-content”>
        <b>【功能】</b>該產品在全球範圍內均可訪問。
        該產品旨在允許用戶遠程訪問該用戶已經安裝的所有程序和遊戲，存儲在雲計算機（使用Windows，Mac或Linux）上的遊戲或玩PC遊戲和所有Flash遊戲。到目前為止，該產品僅可在基於Android的設備上使用。
        提供商保留隨時更改產品功能的權利，特別是通過為用戶引入新功能和設施。
      </p>
<p class =“ p-content”>
        <b>【產品使用條件和責任規則】</b>關於產品使用的技術要求如下：
        具有3G / 4G / 5G或Wi-Fi的基於Android的移動設備；
        用戶有責任確保自己擁有的設備以及所使用的軟件符合上述要求，並允許其使用產品。
        在開始使用產品之前，用戶有義務使自己熟悉這些使用條款和隱私政策。訪問和使用產品等同於接受這些使用條款和隱私政策。
        用戶承諾在使用產品時遵守其當前措辭和任何後續修改的使用條款。
        用戶只能持有一個許可證。許可證不能轉讓給第三方或由該第三方使用。
        用戶承諾避免任何可能阻礙或破壞產品使用的行為。對於任何惡意活動或任何其他違反適用法律或使用條款的行為，提供者可在不事先通知的情況下採取任何可用的行動，包括要求賠償。不受限制地，前一句中提到的動作如下：
        試圖干擾或切斷對其他用戶或其計算機的訂閱的訪問（DOS，DDOS攻擊，DNS欺騙）；
        網絡釣魚，即偽造信息，該信息允許在沒有事先徵得書面同意的情況下識別用戶或隱藏此類信息；
        將惡意軟件輸入到提供商的系統或計算機中，尤其包括病毒，特洛伊木馬或互聯網錯誤；
        未經授權就掃描提供商或其他用戶的網絡以尋找安全漏洞；
        未經授權的網絡流量監視或其他嘗試截取為提供商或其他用戶保留的信息的嘗試；
        未經授權的企圖破壞另一用戶的計算機或網絡的安全性；
        接管IP地址；
        直接或間接發送，收集，出售或分發電子郵件地址，以發送大量未經請求的信件（垃圾郵件）。
        在不限制這些使用條款中任何其他規定的情況下，用戶不得使用產品執行以下操作或協助他人執行以下操作：
        威脅，誹謗，跟踪，虐待或騷擾他人或從事非法活動；
        侵犯任何人或實體的合法權利（包括但不限於知識產權或隱私權），傳輸侵犯或規避此類權利的材料或刪除或更改知識產權或其他法律聲明；
        傳輸包含病毒，間諜軟件，廣告軟件或其他有害代碼的文件；
        廣告商自行決定對提供者認為有害的商品或服務進行廣告或促銷（包括但不限於通過發送垃圾郵件）；
        干擾他人使用產品；
        未經這些用戶和提供者的同意，傳輸，收集或訪問用戶的個人數據；
        從事未經授權的爬網，“刮擦”或收穫，聯繫或其他個人數據，或使用任何其他未經授權的自動化手段來彙編信息；
        擊敗任何訪問控制，訪問提供商未授權用戶訪問的產品的任何部分。
        提供者有權立即終止協議，中止或永久刪除違反這些使用條款的用戶對產品的訪問，恕不另行通知。
        如果用戶使用聯繫表，則他有義務提供真實的信息，以符合案件事實。
        供應商承諾可能會確保產品的最高質量和穩定性，但是在適用法律允許的最大範圍內，供應商對由於不可抗力或第三方未經授權的活動而造成的任何破壞或乾擾概不負責。
        在適用法律允許的最大範圍內，由於在產品框架內實施新的解決方案和設施而導致暫時無法使用產品，提供商不承擔任何責任。提供商將盡可能將產品可訪問性的任何預定中斷或使用過程中可能帶來的任何不便通知用戶。提供商保留對用於提供產品訪問權限的IT系統設備進行維護工作的權利，這可能會暫時造成困難或使用戶無法使用產品。
        在影響產品安全性或穩定性的特殊情況下，提供商保留在不事先通知用戶的情況下臨時減少或中止對產品的訪問的權利，並有權進行旨在恢復產品安全性和穩定性的維護工作。
        在適用法律允許的最大範圍內，如果其他用戶或第三方因其他用戶或第三方違反使用條款或現行法律而向提供商提出任何索賠，則提供商不承擔任何責任。第三方。在這種情況下，僅用戶或第三方違反現行法律應承擔責任。
        該產品提供了使用戶能夠玩遊戲的平台。
      </p>
      <p class =“ p-content”>
        <b>【協議完成之時】</b>用於提供對產品的訪問並同時熟悉這些使用條款和隱私政策的協議。未經事先接受這些使用條款和隱私政策，用戶將無法訪問和使用產品。
        用戶可以隨時終止協議，以提供對產品的訪問，此協議自即日起生效，無需說明任何原因。
        用戶可以隨時停止使用產品。
      </p>

      <p class =“ p-content”>
        <b>【投訴】</b>如果提供商未履行本協議規定的義務，或者提供商違反本協議規定的義務履行了義務，則用戶有權提出投訴。
        可以通過我們的電子郵件地址{email}進行投訴。投訴應特別包括構成投訴基礎的問題和用戶標識信息（名稱，電子郵件地址）。
        如果需要進一步的信息，並且用戶有義務及時提供信息，則提供商保留與用戶聯繫的權利。
        提供商將以適當的形式在收到用戶投訴的十四（14）個工作日內：
        完成內部調查並向用戶告知其決定；要么
        通知用戶需要更多時間才能完成調查。
        如果在指定的期限內無法考慮投訴，則提供商應在那時通過電子郵件通知提交此類投訴的用戶有關此類延遲的原因和預計的解決日期，並應向用戶提供每週一次的更新。調查的進度，除非提供者正在等待用戶的答复，並且已告知用戶該提供者需要用戶的答复。
        自投訴原因顯而易見之日起，應在三十（30）個日曆日內提交投訴。
        調查完成後，提供商將參考這些使用條款的相關規定，將結果和做出決定的原因告知用戶。
        如果用戶對提供商對提出的投訴的決定不滿意，則用戶可能希望將此事提交外部獨立爭議解決。
        對投訴的回复應發送到用戶提交投訴所指示的電子郵件地址。
        提供者不應考慮因不了解現行法律，本使用條款的規定或產品中宣布的信息而引起的投訴。
        如果違反了投訴程序的條件，則可能不會考慮投訴。
      </p>
<p class =“ p-content”>
        <b>【知識產權】</b>產品及其所有相關權利均為提供商或第三方的專有財產。本網站上放置的所有創意元素均受知識產權保護，尤其是受版權保護。產品中出現的所有商標，徽標，圖形，照片，動畫，視頻，文本和其他明顯標誌均為提供商或第三方的知識產權。因此，未經提供商或第三方的事先書面授權，不得複制，使用或表示它們。
        用戶在此承諾尊重提供商或第三方應享有的知識產權（包括作者的經濟權利和工業產權以及商標註冊產生的權利）。
        在用戶遵守本使用條款下他/她的所有義務的情況下，提供商應授予用戶有限，可撤銷，非排他，不可轉讓，不可再許可的下載，安裝，備份權利。並按照提供者打算使用產品的方式使用產品（包括隨附的任何文檔文件或網站信息），並且僅根據這些使用條款，尤其是在以下情況下使用：（i）在以下情況下不對產品進行修改：任何方式（ii）所有版權和專有權聲明或標籤均以其原始格式保存在產品上； （iii）僅出於用戶非商業（內部）目的使用產品。提供商不向用戶授予任何其他暗示或其他權利。用戶不會也不會允許任何第三方：（i）複製，修改，改編，翻譯或以其他方式創建產品的衍生作品； （ii）進行逆向工程，反編譯，反彙編或以其他方式試圖發現產品的源代碼，除非適用法律明確禁止此類限制； （iii）出租，轉授，出租，出售，轉讓或以其他方式將產品（或意圖做任何相同的）的權利（或意圖做任何相同的事情）； （iv）使用，發布，傳輸或介紹任何會干擾或試圖干擾產品操作的設備，軟件或例程。用戶將遵守與產品使用和訪問有關的所有適用法律和法規。用戶不得向第三方提供對產品的訪問權限。如果用戶不遵守這些使用條款，則上面授予的許可和用戶對產品的使用權將立即終止。
        產品已獲得許可，而不是出售，提供商明確地隨時保留和維護產品的所有所有權，包括其中的任何知識產權。這些使用條款不應解釋為向用戶授予產品的任何所有權，而應僅向用戶授予有限的使用本產品的許可，直到本許可終止為止（如本文所述）。用戶確認該產品（包括版權，商業秘密或商標法下的所有知識產權）歸提供商所有。如果用戶違反本使用條款使用產品，則提供者可以尋求所有法律救濟。
        用戶承認，如果他/他違反本使用條款（包括但不限於通過複製，分發或製作產品衍生產品）使用產品，則用戶可能會受到刑事和/或民事罰款和罰款。根據適用法律。
        作為用戶使用產品的條件之一，用戶保證他不會將本產品用於任何非法或本使用條款禁止的目的。用戶同意在使用產品時遵守適用於該用戶的所有當地法規。用戶同意不出於任何商業目的複制，轉售或利用產品的任何部分。如果用戶違反任何這些條款，則使用產品的許可將自動終止。
        僅出於合法目的和使用目的提供產品。用戶同意根據這些使用條款的條款監督和控制產品的使用。產品由提供商按“原樣”提供給用戶，對產品的任何使用均由用戶自擔風險。用戶確認產品可能包含錯誤，錯誤和其他可能導致系統故障或其他故障以及數據丟失的問題。提供者不保證產品的性能，也不保證產品將基本按照產品隨附的文件運行。產品可能會更改用戶的註冊表和其他文件，並且用戶承擔所有此類更改的風險。使用或運行產品之前，請確保備份計算機和所有重要數據。
        在法律允許的最大範圍內，提供者不承擔任何形式的擔保，無論是明示或暗示的權益或法律擔保，包括但不限於對適銷性，適用於特定目的和不侵權的默示擔保。提供者不保證產品中包含的功能將滿足用戶可能具有的任何要求或需求，也不保證產品將無錯誤或以不間斷的方式運行，或者產品中的任何缺陷或錯誤將得到糾正。 ，或產品與任何特定平台兼容。提供商不保證將向用戶提供產品的最新開發或發行版本。用戶應獨自負責確保其擁有最新版本的產品。
        用戶認識到並接受，對於因使用或安裝產品或對用戶的計算機和移動設備進行任何更改而導致的任何損失或損害，最終用戶僅由他/她負責。在法律允許的最大範圍內，提供商在任何情況或情況下均不對用戶或任何其他個人或實體對任何附帶或間接損失（包括但不限於直接或間接，附帶，特殊，懲罰性或因使用或無法使用產品而引起的業務損失，利潤或收入損失，業務中斷或業務信息或數據丟失的示例性損害賠償，或任何其他方的索賠，即使提供商已被告知可能發生此類損壞或可預見的損壞。
      </p>
      <p class =“ p-content”>
        <b>【個人數據保護和隱私政策】</b>提供商數據對用戶個人信息的收集，使用和披露受隱私政策條款的約束。用戶同意在該隱私政策規定的情況下使用和披露用戶的個人數據。
        用戶在決定成為用戶之前，應仔細閱讀完整的隱私政策。
        如果用戶以違反本使用條款或現行法律的方式使用產品，則提供商應有權在確立其責任的必要範圍內使用用戶的個人數據。在這種情況下，提供者應將其立即停止的要求通知用戶他/她進行的非法活動。
      </p>

      <p class =“ p-content”>
        <b>【付款】</b>該產品的付款由第三方服務處理。提供商不負責付款處理，任何問題應與付款提供商解決。
      </p>
<p class =“ p-content”>
        <b>【最終條款】</b>使用條款可在此處向用戶提供。
        提供商保留隨時出於以下一種或多種原因而修改，補充，替換或更改本使用條款的權利，特別是通過更改應付費用，引入新費用，引入新使用條款的權利：
        遵守任何相關法律或行為準則的任何變更或預期的變更；
        反映法院，監察員或監管機構的任何決定；
        反映我們系統或程序的變化，包括出於安全原因；
        由於情況的變化（包括通過向產品添加利益或新功能）；
        按比例對提供產品的成本變化做出響應；要么
        使它們更清晰。
        如果任何法律規定了提供商決定做出的更改，則提供商只能在法律允許的範圍內進行更改。
        如果提供商要求進行更改以恢復或維護產品的安全性，則可以立即進行更改，而無需事先通知用戶。
        如果對這些使用條款進行任何修改，補充，替換或其他更改，將通過在本網站主頁上發布的適當公告告知用戶。修改，補充，替換或其他更改在提供商指定的日期生效，該日期不得早於上述公告之日的十四（14）個日曆日。不同意這些使用條款的修改，補充，替換或其他更改的用戶可以終止與提供商的協議，並應立即通過以下電子郵件地址發送相關聲明，將其告知提供商：service @ netboom。 com。終止應在收到用戶聲明後生效，但不得早於經修訂的使用條款生效之日。在對這些使用條款進行有效的修改，補充，替換或其他更改後，繼續使用本網站即表示用戶接受經修訂的使用條款。
        未經另一方事先同意，本協議任何一方均不得轉讓或轉讓其在該協議下的任何權利或義務。如果是根據涉及提供商的合併，重組或重組或提供商出售其業務而進行的，則提供商可通過通知用戶，轉讓或轉讓其權利，或更新其在這些條款下的權利和義務使用權和用戶將被視為已同意此類轉讓，轉讓或更新。
        使用條款應受中華人民共和國法律的約束，不考慮其法律衝突原則。
        如果您對這些使用條款有任何疑問或意見，請通過{email}與我們聯繫。
      </p>
`
