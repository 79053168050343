export default `
<h3>1. Data We Collect</h3>
<p>When you use Cyberline's services, we will record your IP address and the pictures, videos and texts you post on Cyberline. We also use cookies to store your account information for a convenient browsing experience.</p>

<h3>2. How We Use Your Data</h3>
<p>Under limited circumstances we may share personal information with third parties as well, including when complying with legal matters and also when ensuring the security of our site and related services.</p>

<h3>3. Your Choices and Obligations</h3>
<p>Personal information fields in your profile, outside of the necessary information to process and approve your account, are entirely optional and at your discretion to provide. Your account information at signup, specifically your valid ISP, college, or workplace email address, is necessary to post on Cyberline and to ensure that we are not attacked by anonymous, malicious users. If you do not wish to provide this information, please do not register to post at Cyberline, as you can still read select subforums as an unregistered guest.</p>
`
