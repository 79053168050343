export default `
      <pclass="p-content">
        Harap baca syarat penggunaan berikut dengan seksama. Dengan mengakses atau menggunakan Produk (sebagaimana didefinisikan di bawah), Anda dengan ini setuju untuk terikat secara kontrak oleh dan dengan tegas mematuhi persyaratan penggunaan ini, karena dapat diubah atau ditambah dari waktu ke waktu. Jika Anda tidak menerima Ketentuan Penggunaan ini, Anda tidak boleh menggunakan Produk.
      </p>

      <pclass="p-content">
        <b> 【Ketentuan Umum】 </b> Istilah dalam huruf besar yang digunakan dalam Ketentuan Penggunaan ini memiliki arti sebagai berikut:
        Penyedia - entitas yang menyediakan akses ke Produk, yaitu {project} PTE. LTD.
        Produk - perangkat lunak utilitas sesuai dengan Ketentuan Penggunaan ini, yang merupakan desktop jarak jauh klien di mana Pengguna dapat, antara lain, mengakses semua program dan game yang sudah diinstal, menikmati video dan musik yang disimpan di komputer Pengguna atau memainkan game PC dan semua Game Flash.
        Syarat Penggunaan - dokumen ini, yang menjelaskan syarat dan ketentuan penggunaan Produk serta hak dan kewajiban Pengguna dan Penyedia. Ketentuan Penggunaan merupakan perjanjian yang mengikat antara Pengguna dan Penyedia, sehubungan dengan penggunaan Produk oleh Pengguna.
        Pengguna - seseorang yang telah mencapai usia dewasa di yurisdiksinya, dengan kapasitas penuh untuk melakukan transaksi hukum, bertindak atas namanya sendiri, yang menggunakan Produk sesuai dengan Ketentuan Penggunaan.
        Tentang penyediaan layanan melalui sarana elektronik, Penyedia dengan ini menetapkan Syarat Penggunaan berikut.
      </p>
      <pclass="p-content">
        <b> 【Fungsi】 </b> Produk dapat diakses di seluruh dunia oleh siapa saja yang memiliki akses Internet.
        Produk dirancang untuk memungkinkan Pengguna memiliki akses jarak jauh ke semua program dan game yang telah diinstal oleh Pengguna, ke game yang disimpan di komputer cloud (dengan Windows, Mac atau Linux) atau memainkan game PC dan semua game Flash. Sejauh ini Produk hanya berfungsi di perangkat berbasis Android.
        Penyedia berhak untuk mengubah fungsi Produk setiap saat, khususnya dengan memperkenalkan fungsi dan fasilitas baru untuk Pengguna.
      </p>
      <pclass="p-content">
        <b> 【Ketentuan Penggunaan Produk dan Aturan Tanggung Jawab】 </b> Persyaratan teknis terkait penggunaan Produk adalah sebagai berikut:
        Perangkat seluler berbasis Android dengan 3G / 4G / 5G atau Wi-Fi;
        Merupakan tanggung jawab Pengguna untuk memastikan bahwa peralatan yang dimilikinya serta perangkat lunak yang digunakan memenuhi persyaratan di atas dan memungkinkannya untuk menggunakan Produk.
        Sebelum mulai menggunakan Produk, Pengguna wajib membiasakan diri dengan ketentuan Ketentuan Penggunaan ini dan Kebijakan Privasi. Akses dan penggunaan Produk sama dengan menerima Syarat Penggunaan dan Kebijakan Privasi ini.
        Seorang Pengguna berjanji untuk menghormati Syarat Penggunaan saat menggunakan Produk, baik dalam kata-katanya saat ini dan dengan perubahan selanjutnya.
        Seorang Pengguna hanya dapat memiliki satu lisensi. Lisensi tidak dapat dialihkan ke pihak ketiga atau digunakan oleh pihak ketiga tersebut.
        Pengguna berjanji untuk tidak melakukan tindakan apa pun yang dapat menghalangi atau mengganggu kestabilan penggunaan Produk. Penyedia dapat, tanpa pemberitahuan sebelumnya, melakukan tindakan apa pun yang tersedia, termasuk permintaan kompensasi, sebagai tanggapan atas aktivitas jahat atau pelanggaran hukum lainnya yang berlaku atau Ketentuan Penggunaan ini. Tanpa batasan, tindakan yang dirujuk pada kalimat sebelumnya adalah sebagai berikut:
        upaya untuk mengganggu atau memotong akses ke langganan Pengguna lain atau ke komputer mereka (DOS, serangan DDOS, spoofing DNS);
        phishing, yaitu memalsukan informasi yang memungkinkan untuk mengidentifikasi Pengguna atau menyembunyikan informasi tersebut tanpa terlebih dahulu mendapatkan persetujuan tertulis;
        memasukkan perangkat lunak berbahaya ke dalam sistem atau ke komputer Penyedia, terutama termasuk virus, kuda Troya atau bug internet;
        pemindaian jaringan Penyedia atau Pengguna lain yang tidak sah untuk mencari celah keamanan;
        pemantauan tidak sah atas lalu lintas jaringan atau upaya lain untuk mencegat informasi yang disediakan untuk Penyedia atau Pengguna lain;
        upaya tidak sah untuk merusak keamanan komputer atau jaringan Pengguna lain;
        mengambil alih alamat IP;
        pengiriman, pengumpulan, penjualan atau pendistribusian alamat email secara langsung atau tidak langsung untuk tujuan pengiriman korespondensi massal yang tidak diminta (spam).
        Tanpa membatasi ketentuan lain apa pun dalam Ketentuan Penggunaan ini, Pengguna tidak boleh menggunakan Produk untuk melakukan hal berikut atau membantu orang lain untuk melakukan hal berikut:
        mengancam, memfitnah, menguntit, menyalahgunakan atau melecehkan orang lain atau terlibat dalam kegiatan ilegal;
        melanggar hak hukum seseorang atau entitas (termasuk, tanpa batasan, hak kekayaan intelektual atau hak privasi), mengirimkan materi yang melanggar atau mengelak hak tersebut atau menghapus atau mengubah kekayaan intelektual atau pemberitahuan hukum lainnya;
        mengirimkan file yang berisi virus, spyware, adware, atau kode berbahaya lainnya;
        mengiklankan atau mempromosikan barang atau layanan yang dianggap oleh Penyedia, atas kebijakannya sendiri, tidak dapat diterima (termasuk, tanpa batasan, dengan mengirimkan spam);
        mengganggu orang lain dalam menggunakan Produk;
        mengirimkan, mengumpulkan atau mengakses data pribadi Pengguna tanpa persetujuan dari Pengguna tersebut dan Penyedia;
        terlibat dalam perampasan tanpa izin, "mengorek" atau memanen, menghubungi atau data pribadi lainnya atau menggunakan cara otomatis tidak sah lainnya untuk mengumpulkan informasi;
        mengalahkan kontrol akses apa pun, mengakses bagian mana pun dari Produk yang Penyedia tidak mengizinkan Pengguna untuk mengaksesnya.
        Penyedia berwenang untuk segera, tanpa pemberitahuan sebelumnya, mengakhiri perjanjian, menangguhkan atau secara permanen menghapus akses ke Produk Pengguna yang melanggar Ketentuan Penggunaan ini.
        Jika Pengguna menggunakan Formulir Kontak, ia wajib memberikan informasi yang sebenarnya, sesuai fakta kasus.
        Penyedia berjanji untuk memastikan kualitas dan stabilitas Produk yang mungkin tertinggi, namun dalam batas maksimum yang diizinkan oleh hukum yang berlaku, Penyedia tidak akan bertanggung jawab atas setiap jeda atau gangguan yang disebabkan oleh keadaan kahar atau aktivitas pihak ketiga yang tidak sah.
        Dalam batas maksimum yang diizinkan oleh hukum yang berlaku, Penyedia tidak bertanggung jawab atas ketidakmampuan sementara untuk menggunakan Produk, yang disebabkan oleh penerapan solusi dan fasilitas baru dalam kerangka Produk. Sebisa mungkin, Penyedia akan memberi tahu Pengguna tentang jeda terjadwal dalam aksesibilitas Produk atau ketidaknyamanan yang mungkin terjadi dalam penggunaannya. Penyedia berhak melakukan pekerjaan pemeliharaan pada peralatan sistem TI yang digunakan untuk menyediakan akses ke Produk, yang dapat menyebabkan kesulitan sementara atau membuat Pengguna tidak dapat menggunakan Produk.
        Dalam kasus khusus yang memengaruhi keamanan atau stabilitas Produk, Penyedia berhak untuk sementara waktu mengurangi atau menangguhkan akses ke Produk tanpa memberi tahu Pengguna sebelumnya dan untuk melakukan pekerjaan pemeliharaan yang bertujuan untuk memulihkan keamanan dan stabilitas Produk.
        Dalam batas maksimum yang diizinkan oleh hukum yang berlaku, Penyedia tidak akan menanggung tanggung jawab apa pun jika Pengguna lain atau pihak ketiga mengajukan klaim apa pun kepada Penyedia terkait pelanggaran Ketentuan Penggunaan atau hukum yang berlaku, baik oleh Pengguna lain atau oleh pihak ketiga. Hanya Pengguna atau pihak ketiga yang melanggar hukum yang berlaku yang bertanggung jawab dalam kasus seperti itu.
        Produk menyediakan platform untuk memungkinkan Pengguna bermain game.
      </p>

     <pclass="p-content">
        <b> 【Saat Penutupan Perjanjian】 </b> Perjanjian untuk menyediakan akses ke Produk dan pengenalan secara simultan dengan Ketentuan Penggunaan dan Kebijakan Privasi ini. Pengguna tidak dapat mengakses dan menggunakan Produk tanpa terlebih dahulu menerima Syarat Penggunaan dan Kebijakan Privasi ini.
        Pengguna dapat mengakhiri perjanjian untuk menyediakan akses ke Produk, efektif segera, kapan saja dan tanpa menyebutkan alasan apa pun.
        Pengguna dapat berhenti menggunakan Produk kapan saja.
      </p>

      <pclass="p-content">
        <b> 【Keluhan】 </b> Pengguna berhak untuk mengajukan keluhan jika Penyedia tidak memenuhi kewajibannya yang ditentukan di sini atau jika Penyedia memenuhinya dengan cara yang bertentangan dengan ketentuan di sini.
        Keluhan dapat diajukan melalui alamat email kami {email}. Keluhan harus mencakup secara khusus masalah yang menjadi dasar keluhan dan informasi identifikasi Pengguna (nama, alamat email).
        Penyedia berhak untuk menghubungi Pengguna jika informasi lebih lanjut diperlukan dan Pengguna berkewajiban untuk segera memberikannya.
        Dalam empat belas (14) hari kerja setelah menerima keluhan Pengguna dalam bentuk yang benar, Penyedia akan:
        menyelesaikan penyelidikan internalnya dan memberi tahu Pengguna tentang keputusannya; atau
        memberi tahu Pengguna bahwa perlu lebih banyak waktu untuk menyelesaikan penyelidikan.
        Jika keluhan tidak dapat dipertimbangkan dalam tenggat waktu yang ditentukan, Penyedia pada saat itu akan memberi tahu melalui email Pengguna yang mengirimkan keluhan tentang alasan penundaan tersebut dan tanggal yang diharapkan untuk penyelesaian keluhan dan sebagai tambahan memberi Pengguna pembaruan mingguan dari kemajuan penyelidikan, kecuali Penyedia menunggu tanggapan dari Pengguna dan Pengguna telah diberi tahu bahwa Penyedia memerlukan tanggapan Pengguna.
        Pengaduan harus diajukan dalam waktu tiga puluh (30) hari kalender sejak alasan pengaduan menjadi jelas.
        Setelah penyelidikan selesai, Penyedia akan memberi tahu Pengguna tentang hasil dan alasan keputusannya dengan mengacu pada ketentuan yang relevan dari Ketentuan Penggunaan ini.
        Jika Pengguna tidak puas dengan keputusan Penyedia sehubungan dengan keluhan yang diajukan, Pengguna mungkin ingin membawa masalah ini ke penyelesaian sengketa independen eksternal.
        Balasan untuk keluhan akan dikirim ke alamat email yang ditunjukkan oleh Pengguna yang mengirimkan keluhan.
        Penyedia tidak akan mempertimbangkan keluhan yang timbul dari ketidaktahuan hukum yang berlaku, ketentuan dari Ketentuan Penggunaan ini, atau informasi yang diumumkan dalam Produk.
        Jika kondisi prosedur keluhan dilanggar, keluhan mungkin tidak dipertimbangkan.
      </p>

    <pclass="p-content">
        <b> 【Kekayaan Intelektual】 </b> Produk dan semua hak yang terkait dengannya adalah milik eksklusif Penyedia atau pihak ketiga. Semua elemen kreatif yang ditempatkan di Situs ini dilindungi oleh hak kekayaan intelektual, dan khususnya oleh hak cipta. Semua merek dagang, logo, grafik, foto, animasi, video, teks, dan tanda khusus lainnya yang muncul di Produk adalah kekayaan intelektual Penyedia atau pihak ketiga. Oleh karena itu, mereka tidak boleh direproduksi, digunakan atau diwakili tanpa izin tertulis sebelumnya dari Penyedia atau pihak ketiga.
        Pengguna dengan ini berjanji untuk menghormati hak kekayaan intelektual (termasuk hak ekonomi penulis dan hak kekayaan industri, serta hak yang dihasilkan dari pendaftaran merek dagang) yang menjadi hak Penyedia atau pihak ketiga.
        Dengan syarat Pengguna mematuhi semua kewajibannya berdasarkan Ketentuan Penggunaan ini, Penyedia dengan ini memberikan kepada Pengguna hak terbatas, dapat dibatalkan, non-eksklusif, tidak dapat dialihkan, tidak dapat disublisensikan untuk mengunduh, menginstal, mencadangkan. , dan menggunakan Produk, termasuk file dokumentasi atau informasi situs web yang menyertainya, sebagaimana Penyedia bermaksud agar Produk digunakan, dan hanya sesuai dengan Ketentuan Penggunaan ini dan secara khusus dengan ketentuan bahwa: (i) Produk tidak dimodifikasi dalam cara apapun; (ii) semua pemberitahuan atau label hak cipta dan hak milik dipertahankan pada Produk dalam format aslinya; dan (iii) penggunaan Produk hanya untuk tujuan non-komersial (internal) Pengguna. Penyedia tidak memberikan hak lain kepada Pengguna, tersirat atau sebaliknya. Pengguna tidak akan dan Pengguna tidak akan mengizinkan pihak ketiga untuk: (i) menyalin, memodifikasi, mengadaptasi, menerjemahkan atau membuat karya turunan dari Produk; (ii) merekayasa balik, menghapus kompilasi, membongkar, atau mencoba menemukan kode sumber Produk, kecuali sejauh hukum yang berlaku secara khusus melarang pembatasan tersebut; (iii) menyewakan, mensublisensikan, menyewakan, menjual, mengalihkan atau mengalihkan hak (atau dimaksudkan untuk melakukan hal yang sama) di dalam atau pada Produk; dan (iv) menggunakan, memposting, mengirimkan, atau memperkenalkan perangkat, perangkat lunak, atau rutinitas apa pun yang mengganggu atau berupaya mengganggu pengoperasian Produk. Pengguna akan mematuhi semua hukum dan peraturan yang berlaku terkait dengan penggunaan dan akses ke Produk. Pengguna tidak boleh memberikan akses ke Produk kepada pihak ketiga. Lisensi yang diberikan di atas dan hak pengguna untuk menggunakan Produk akan segera berakhir jika Pengguna gagal untuk mematuhi Syarat Penggunaan ini.
        Produk sedang dilisensikan, tidak dijual, dan Penyedia secara tegas menyimpan dan memelihara setiap saat semua hak kepemilikan atas Produk, termasuk hak kekayaan intelektual di dalamnya. Ketentuan Penggunaan ini tidak dapat ditafsirkan untuk memberikan hak kepemilikan apa pun atas Produk kepada Pengguna, tetapi hanya akan memberikan lisensi terbatas kepada Pengguna untuk menggunakan Produk sampai lisensi ini diakhiri, sebagaimana ditetapkan di sini. Pengguna mengakui bahwa Produk, termasuk semua hak kekayaan intelektual berdasarkan undang-undang hak cipta, rahasia dagang atau merek dagang, dimiliki oleh Penyedia. Penyedia dapat melakukan semua upaya hukum jika Pengguna menggunakan Produk dengan melanggar Persyaratan Penggunaan ini.
        Pengguna mengakui bahwa jika ia menggunakan Produk dengan melanggar Ketentuan Penggunaan ini (termasuk, namun tidak terbatas pada, dengan menyalin, mendistribusikan atau membuat turunan dari Produk), Pengguna dapat dikenakan denda pidana dan / atau perdata dan hukuman. berdasarkan hukum yang berlaku.
        Sebagai syarat penggunaan Produk oleh Pengguna, Pengguna menjamin bahwa ia tidak akan menggunakan Produk untuk tujuan apa pun yang melanggar hukum atau dilarang oleh Ketentuan Penggunaan ini. Pengguna setuju untuk mematuhi semua peraturan lokal yang berlaku bagi Pengguna dalam penggunaan Produknya. Pengguna setuju untuk tidak mereproduksi, menjual kembali, atau mengeksploitasi untuk tujuan komersial apa pun, bagian apa pun dari Produk. Jika Pengguna melanggar salah satu persyaratan ini, lisensi untuk menggunakan Produk secara otomatis dihentikan.
        Produk disediakan hanya untuk tujuan dan penggunaan yang sah. Pengguna setuju untuk mengawasi dan mengontrol penggunaan Produk sesuai dengan ketentuan Ketentuan Penggunaan ini.
        Produk disediakan untuk Pengguna "sebagaimana adanya" oleh Penyedia dan setiap penggunaan Produk merupakan risiko Pengguna sendiri. Pengguna mengetahui bahwa Produk mungkin mengandung bug, kesalahan, dan masalah lain yang dapat menyebabkan sistem atau kegagalan lain dan kehilangan data. Penyedia tidak menjamin kinerja Produk dan tidak menjamin bahwa Produk akan beroperasi secara substansial sesuai dengan dokumentasi yang menyertai Produk. Produk dapat membuat perubahan pada registri Pengguna dan file lainnya dan Pengguna menanggung semua risiko untuk perubahan tersebut. Harap pastikan untuk mencadangkan komputer Anda dan semua data penting sebelum menggunakan atau menjalankan Produk.
        Sejauh diizinkan oleh hukum, Penyedia menyangkal semua jaminan dalam bentuk apa pun, baik tersurat maupun tersirat, dalam ekuitas atau hukum, termasuk, tanpa batasan, jaminan tersirat tentang kelayakan untuk diperdagangkan, kesesuaian untuk tujuan tertentu, dan non-pelanggaran. Penyedia tidak menjamin bahwa fungsi yang terkandung dalam Produk akan memenuhi persyaratan atau kebutuhan yang mungkin dimiliki Pengguna, atau bahwa Produk akan beroperasi bebas dari kesalahan, atau dengan cara yang tidak terputus, atau bahwa setiap cacat atau kesalahan pada Produk akan diperbaiki , atau bahwa Produk kompatibel dengan platform tertentu. Penyedia tidak menjamin bahwa ia akan memberikan versi Produk yang paling baru dikembangkan atau didistribusikan kepada Pengguna. Pengguna sepenuhnya bertanggung jawab untuk memastikan bahwa ia memiliki Produk versi terbaru.
        Pengguna mengakui dan menerima bahwa hanya ia yang pada akhirnya bertanggung jawab atas kerugian atau kerusakan yang ditimbulkan oleh Pengguna sebagai akibat dari penggunaan atau penginstalan Produk atau sebagai akibat dari perubahan apa pun yang dilakukan pada komputer dan perangkat seluler Pengguna. Sejauh diizinkan oleh undang-undang, dalam keadaan atau peristiwa apa pun Penyedia tidak akan bertanggung jawab kepada Pengguna atau orang atau entitas lain atas kerusakan insidental atau konsekuensial (termasuk, tanpa batasan, langsung atau tidak langsung, insidental, khusus, hukuman, atau Kerusakan teladan untuk kehilangan bisnis, kehilangan keuntungan atau pendapatan, gangguan bisnis, atau kehilangan informasi atau data bisnis) yang timbul dari penggunaan atau ketidakmampuan untuk menggunakan Produk, atau untuk klaim oleh pihak lain, bahkan jika Penyedia telah diberitahukan tentang kemungkinan kerusakan tersebut atau jika kerusakan sudah dapat diduga sebelumnya.
      </p>

    <pclass="p-content">
            <b> 【Perlindungan Data Pribadi dan Kebijakan Privasi】 </b> Pengumpulan, penggunaan, dan pengungkapan data pribadi Pengguna oleh Penyedia diatur oleh ketentuan Kebijakan Privasi. Pengguna menyetujui penggunaan dan pengungkapan data pribadi Pengguna dalam keadaan yang dinyatakan dalam Kebijakan Privasi tersebut.
            Pengguna harus membaca Kebijakan Privasi lengkap dengan cermat sebelum memutuskan untuk menjadi Pengguna.
            Jika Pengguna menggunakan Produk dengan cara yang melanggar Ketentuan Penggunaan ini atau hukum yang berlaku, Penyedia berhak untuk menggunakan data pribadi Pengguna dalam ruang lingkup yang diperlukan untuk menetapkan tanggung jawabnya. Dalam kasus seperti itu, Penyedia akan memberi tahu Pengguna tentang / nya melakukan kegiatan ilegal dengan permintaan penghentian segera.
          </p>

          <pclass="p-content">
            <b> 【Pembayaran】 </b> Pembayaran untuk produk diproses oleh layanan pihak ketiga. Penyedia tidak bertanggung jawab atas pemrosesan pembayaran, masalah apa pun harus diselesaikan dengan penyedia pembayaran.
          </p>

    <pclass="p-content">
        <b> 【Ketentuan Akhir】 </b> Ketentuan Penggunaan tersedia untuk Pengguna di sini.
        Penyedia berhak untuk mengubah, menambah, mengganti, atau mengubah Ketentuan Penggunaan ini kapan saja, dan khususnya dengan mengubah biaya yang harus dibayar, memperkenalkan biaya baru, memperkenalkan Ketentuan Penggunaan baru, karena satu atau lebih alasan berikut:
        untuk mematuhi setiap perubahan atau perubahan yang diantisipasi dalam hukum atau kode praktik yang relevan;
        untuk mencerminkan keputusan pengadilan, ombudsman atau regulator;
        untuk mencerminkan perubahan dalam sistem atau prosedur kami, termasuk untuk alasan keamanan;
        sebagai akibat dari keadaan yang berubah (termasuk dengan menambahkan manfaat atau fitur baru ke Produk);
        untuk menanggapi secara proporsional perubahan biaya penyediaan Produk; atau
        untuk membuatnya lebih jelas.
        Jika ada hukum yang mengatur perubahan yang diputuskan oleh Penyedia untuk dilakukan, Penyedia hanya dapat melakukan perubahan sejauh diizinkan oleh hukum.
        Jika perubahan diperlukan dalam pandangan Penyedia untuk memulihkan atau menjaga keamanan Produk, perubahan dapat dilakukan segera dan tanpa pemberitahuan sebelumnya kepada Pengguna.
        Dalam hal ada modifikasi, tambahan, penggantian atau perubahan lain dari Ketentuan Penggunaan ini, Pengguna akan diberitahukan tentang mereka melalui pengumuman yang sesuai yang diposting di halaman beranda Situs ini. Modifikasi, tambahan, penggantian atau perubahan lain mulai berlaku pada tanggal yang ditentukan oleh Penyedia, yang tidak boleh lebih awal dari empat belas (14) hari kalender sejak tanggal pengumuman tersebut. Pengguna yang tidak menyetujui modifikasi, tambahan, penggantian atau perubahan lain dari Ketentuan Penggunaan ini dapat mengakhiri perjanjian dengan Penyedia dan harus segera menginformasikan Penyedia tentang hal itu dengan mengirimkan pernyataan yang relevan di alamat email berikut: service @ netboom. com. Penghentian akan berlaku efektif setelah menerima pernyataan Pengguna tetapi tidak lebih awal dari tanggal berlakunya Ketentuan Penggunaan yang diubah. Penggunaan berkelanjutan dari Situs ini setelah modifikasi, suplemen, penggantian atau perubahan lain telah dilakukan secara efektif terhadap Ketentuan Penggunaan ini menunjukkan penerimaan Pengguna atas Ketentuan Penggunaan yang diubah.
        Tidak ada pihak dalam perjanjian ini yang dapat mengalihkan atau mentransfer hak atau kewajiban mereka berdasarkan perjanjian tanpa persetujuan sebelumnya dari pihak lain. Jika dilakukan berdasarkan merger, reorganisasi atau restrukturisasi yang melibatkan Penyedia, atau penjualan bisnisnya oleh Penyedia, Penyedia dapat, dengan pemberitahuan kepada Pengguna, mentransfer atau mengalihkan haknya, atau membatalkan hak dan kewajibannya, berdasarkan Ketentuan ini Penggunaan dan Pengguna akan dianggap telah menyetujui transfer, penugasan, atau novasi tersebut.
        Ketentuan Penggunaan akan diatur oleh hukum Republik Rakyat Tiongkok, tanpa memperhatikan pertentangan prinsip hukumnya.
        Jika Anda memiliki pertanyaan atau komentar tentang Ketentuan Penggunaan ini, silakan hubungi kami melalui {email}.
      </p>
  `
