import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import 'normalize.css'
import MetaInfo from 'vue-meta-info'
Vue.config.productionTip = false

Vue.use(MetaInfo)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
