export default [
  {
    path: '/netboom',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'Netboom'
    },
    children: [
      {
        path: '/netboom/register',
        name: 'register',
        component: () => import('@/views-rem/register/index.vue'),
        meta: {
          title: 'Register'
        }
      }
    ]
  }
]
