export default [
  {
    path: '/paypal',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'Paypal'
    },
    children: [
      {
        path: '/paypal/amount',
        name: 'PaypalAmount',
        component: () => import('@/views-rem/paypal/index.vue'),
        meta: {
          title: 'PaypalAmount'
        }
      },
      {
        path: '/paypal/mall',
        name: 'paypalMall',
        component: () => import('@/views-rem/paypal-mall/index.vue'),
        meta: {
          title: 'paypalMall'
        }
      },
      {
        path: '/paypal/subscribe',
        name: 'PaypalSubscribe',
        component: () => import('@/views-rem/paypal_subscribe/index.vue'),
        meta: {
          title: 'PaypalSubscribe'
        }
      },
      {
        path: '/paypal/waitting',
        name: 'PaypalWaitting',
        component: () => import('@/views-rem/paypal_subscribe/waitting.vue'),
        meta: {
          title: 'wait a moment...',
          hidden: true
        }
      },
      {
        path: '/paypal/success',
        name: 'paymentSuccess',
        component: () => import('@/views-rem/paypal-mall/success.vue'),
        meta: {
          title: 'wait a moment...',
          hidden: true
        }
      },

      {
        path: '/netbang/payment',
        name: 'netBangPayment',
        component: () => import('@/views-rem/netbang/payment.vue'),
        meta: {
          title: 'netBangPayment'
        }
      },
      {
        path: '/pay/subscribe',
        name: 'paySubscribe',
        component: () => import('@/views-rem/stripe-subscribe/index.vue'),
        meta: {
          title: 'PaySubscribe'
        }
      },
      {
        path: '/pay/airwallex',
        name: 'airwallex',
        component: () => import('@/views-rem/airwallex/index.vue'),
        meta: {
          title: 'airwallex'
        }
      },
      {
        path: '/pay/airwallex-vr',
        name: 'airwallex-vr',
        component: () => import('@/views-rem/airwallex/indexVr.vue'),
        meta: {
          title: 'airwallex-vr'
        }
      },
      {
        path: '/pay/subscribe/success',
        name: 'paySubscribeSuccess',
        component: () => import('@/views-rem/stripe-subscribe/success.vue'),
        meta: {
          title: 'Subscribe Success',
          hidden: true
        }
      },
      {
        path: '/pay/subscribe/appGoback',
        name: 'paySubscribeappGoback',
        component: () => import('@/views-rem/stripe-subscribe/appGoback.vue'),
        meta: {
          title: 'appGoback',
          hidden: true
        }
      }
    ]
  }
]
