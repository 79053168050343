export default `
<b>{projectUpperCase} CLOUD PTE. LED. PRIVACY POLICY</b>

<p>Last updated on September 24th, 2020</p>
<p>The {project} Privacy Policy is designed to help you better understand the information we collect and the different ways we may use or share it.</p>
<p>This policy applies to your use of the {project} services. By “{project} services,” we mean any of our websites, stores, games, applications, or other products or services that link to this policy. Additional terms and conditions may also apply to your relationship with {project}, depending on how you choose to interact with us. Please read this entire policy to make sure you fully understand it. You should also be sure to carefully review other terms potentially applicable to your visit and your use of specific {project} services (such as dedicated games or the use of your {project} Account with third-party games), and confirm that you understand and agree to them, before using the {project} services.</p>
<p>{project} and our subsidiaries operate and support the {project} services from different locations around the world. When we say “{project}” or “{projectUpperCase} CLOUD PTE. LED.” we’re referring to the relevant {project} entity identified for your region in the “How to Contact Us” section of the policy below. This {project} entity is responsible for providing the {project} services to you and is the controller of information collected under this policy.</p>
<p>Please see “How to Contact Us” below to learn more about sending us any privacy-related questions or concerns you may have.</p>

<b>Information We Collect</b>

<p>We generally collect information in three ways: (1) you voluntarily provide information to us, such as by creating an account, making purchases, or signing up for email alerts, (2) we collect information automatically from your use of the {project} services, such as through cookies or our games and other software, and (3) other parties, such as game console platforms and social networks, may provide information to us. Each of these methods is described in more detail below. </p>
<br>
<p>(1) Information You Provide</p>
<p>You may provide your personal information to us when you choose to use the {project} services. Because we change our offerings and features from time to time, the options you have to provide us with information also may change. Some of the most common examples of situations in which you may provide information to us include:</p>
<ul>
    <li>Creating a {project} account;</li>
    <li>Using the {project} services’ social features (see “When You Use Social Features of the {project} Services”);</li>
    <li>Purchasing something from us or otherwise engaging in a transaction with us;</li>
    <li>Entering online competitions or registering for or participating in special events;</li>
    <li>Entering contests or sweepstakes, participating in any of our promotions, or accepting any prizes from us;</li>
    <li>Signing up for email alerts or subscribing to receive other information from us;</li>
    <li>Completing a survey;</li>
    <li>Using a mobile device, such as a phone, to play our games or use our applications; and</li>
    <li>Contacting us for any reason, such as by email, including for technical support or customer service.</li>
</ul>
<p>The specific types of information you may be able to provide us will often vary depending on the {project} cloud you are using and how you interact with them.</p>
<br>
<p>(2) Information We Collect Automatically</p>
<p>We collect some information automatically when you visit the {project} cloud. We typically aggregate this data and use it in statistical form to determine trends among groups of users, rather than using it to identify individuals. If a combination of information that we collect does identify you as an individual, we will treat the combined information as personal information.</p>
<p>We collect information automatically through technologies such as web browsers, cookies, log files, web beacons, and our back-end servers collect usage data transmitted from the {project} cloud. We use the information for purposes such as modifying or improving features, addressing technical issues, preventing fraud or misuse of our services, and conducting data analytics. The type of information that we automatically collect may vary, but generally includes:</p>
<ul>
<li>Technical information about your computer, device, hardware, or software you use to access the Internet or our services, such as IP address or other transactional or identifier information for your device (such as device make and model, information about device operating systems and browsers, or other device or system related specifications);</li>
<li>Usage information and statistics about your interaction with the {project} cloud, which may include the URLs of our websites that you have visited, URLs of referring and exiting pages, page views, time spent on a page, number of clicks, platform type, the application you used or the game you played, how long you used or played it and when, and other usage statistics;</li>
<li>Crash reports, which may be automatically generated when a game or application crashes and includes information about your system and the crash;</li>
<li>Information that facilitates a safer and more personalized experience, such as your display name or other user identification provided in connection with your application use or game play, saved preferences, game progress, and device identifiers or usage information for authentication and fraud prevention purposes; and</li>
<li>The location of your device, such as may be derived from your device’s IP address.</li>
</ul>
<p>Please note that if certain features on the {project} cloud are provided by third parties, those third parties may also use automated means of data collection and may record information about your use of the {project} cloud or others’ websites over time. These features are subject to those third parties’ privacy notices and policies.</p>
<br>
<b>Fraud Prevention & Anti-Cheat:</b>
<p>Providing users with a fair, balanced, and competitive experience on the {project} cloud is extremely important to us. We strictly enforce prohibitions against cheating, hacking, account stealing, and any other unauthorized or fraudulent activity on the {project} cloud. When you create an {project} account, buy our products or services, play our games, or otherwise interact with the {project} on or through the {project} cloud, we may use a variety of anti-cheat and fraud prevention technologies to help us identify and prevent malicious activity. These services may collect and analyze data about the game binary or your computer to detect cheating, and may be provided by {project} or by third parties such as BattleEye.</p>
<br>
<p>(3) Information We Receive from Others</p>
<p>In some cases, other parties provide us with information about you, or allow us to collect information about you. We generally collect information from other parties in four ways: (1) you interact with {project} using a service that is not controlled by us, such as a social network, (2) you choose to use a social feature of the {project} cloud, (3) you access {project} cloud through another party, such as a gaming console or mobile device operator, or (4) you link a third party account to your {project} account. In some cases, we are not able to control the amount or type of information that other parties, like social networks, make available to us. In those cases, we use only the data that we need to provide the types of services we think our users want and expect. Although we cannot control your privacy settings at those websites or how those other parties protect your privacy, once we receive information about you through those websites, we will treat it in accordance with this policy.</p>
<p>When You Interact with {project} on Third Party Services</p>
<p>When you interact with {project} on a website or app not controlled by {project}, that website or app may allow {project} access to certain information about you. For example, social networks may provide us with information from your profile on their services or other information that you agreed the social network or other party could provide to us.</p>
<br>
<b>How We Use and Share Information</b>
<p>As a general matter, we use and share the information we collect (either individually or in combination with other information collected as described in this policy) to help us provide, improve, customize, analyze, and market the {project} cloud where we have an appropriate basis to do so.</p>
<p>(1) Use <br>
We rely on several different legal bases to process personal information for the purposes disclosed in this policy. These often depend on the exact nature of your relationship with {project}, but common examples include:
</p>
<ul>
<li>To support our legitimate interests, such as:</li>
<li>To communicate with you, respond to your requests, or provide you with updates and information;</li>
<li>To better understand our users and their preferences;</li>
<li>To personalize your experience, save your preferences, authenticate our users, and provide similar user-experience features;</li>
<li>To develop, deliver, and improve our products, services, and other offerings, some of which may be offered in partnership with other parties;</li>
<li>To manage and customize advertisements or promotional offers;</li>
<li>For security purposes; and</li>
<li>For internal purposes such as auditing and data analysis.
<br></li>
<li>To provide services that are subject to terms you have accepted, such as enforcing our licenses, agreements, and terms of service, which may include reasonable monitoring to detect and prevent misuse or fraud, keep our games fair for all users, and otherwise protect and defend the {project} cloud.</li>
<li>When you give consent, which you can withdraw at any time.</li>
<li>To comply with legal obligations.</li>
</ul>
<br>
<p>We store personal information for as long as we reasonably need it to fulfill the purposes for which it was collected. For example, if you create an {project} account, we will store the personal information associated with your {project} account for as long as needed to maintain your account, provide the {project} cloud you requested, enforce any applicable terms that govern your use of the {project} cloud, and maintain appropriate records to reflect our delivery of the {project} cloud to you. We periodically review the personal information we retain to determine whether continued storage is proper.</p>


<p>
(2) Sharing <br>
Service Providers. We share personal information with service providers that we’ve engaged to help support the {project} cloud by performing certain services on our behalf and under our instructions.
</p>
<br>
<p>You may also exercise and amend your choices by sending an email to us as detailed in the “How to Contact Us” section below.</p>
<p>EEA and UK Residents. If you are an EEA or UK resident, you may have the right to access your information, the right to correct your information, the right to request deletion of your personal information, the right to request restricted processing of your personal information, the right to a portable copy of the personal information you have provided, and the right to object to processing of your personal information, such as for direct marketing. For more information about exercising these rights, send an email to the address specified in the “How to Contact Us” section below.</p>
<p>EEA and UK residents may contact {project}’s data protection officer with any questions or concerns about our handling of personal information at contact@{project}.com. EEA and UK residents also have the right to lodge a complaint with a data protection authority if they have concerns about {project}’s handling of personal information.</p>
<p>California Residents. If you are a California resident and are not a job applicant, employee or employee of another company interacting with us in your job, you have the right to ask us what personal information we have collected and shared about you, the right to request deletion of your personal information, and the right not to be discriminated against for exercising any of these rights. For more information about exercising these rights, send an email to the address specified in the “How to Contact Us” section below.</p>
<p>Sales of Information. {project} only uses the personal information we collect to help provide, support, and improve the {project} cloud as described in this policy, and we do not sell this information to third parties.</p>
<br>
<b>How We Protect Information</b>
<p>We maintain appropriate administrative, technical, and physical safeguards to protect your personal information from accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use and other unlawful forms of processing. In some cases, your information is accessible when you log into a feature we offer, and in those cases, you need to keep your user credentials and password confidential and secure so that your information is protected. </p>

<b>Transfers of Information</b>
<p>{project} Games is a company with operations around the world. By using the {project} cloud, you acknowledge and agree that your information may be processed outside your country of residence, including in other jurisdictions (like the United States) that may have different data protection frameworks than your region. To learn more about the {project} entity responsible for operating the {project} cloud in your region, see “How to Contact Us” below.</p>
<p>We’ve implemented and maintain a framework consistent with applicable law and this policy for transfers of information outside the country of collection. For example, when we transfer personal data collected in the European Union to locations outside the European Economic Area, we rely on transfer mechanisms adopted by the European Commission to help establish adequate safeguards, like Standard Contractual Clauses. We may also need to transfer your information to provide the {project} cloud to you in accordance with our existing agreements with you, such as the Terms of Service. </p>
<br>
<b>Updates to Our Privacy Policy</b>
<p>This policy may be updated periodically to reflect changes in our personal information practices or relevant laws. We will indicate at the top of this policy when this policy was last updated. Please review this policy every time you access or use the {project} cloud to make sure that you have reviewed the most recent version.</p>
<br>
<b>How to Contact Us</b>
<p>If you have general questions or concerns about the {project} services (such as game-related issues, trouble accessing your {project} account, bugs or other technical problems, payment matters, or content and entitlement issues), please contact our Player Support team.</p>
<p>To learn more about {project}’s privacy practices or this policy, please email your questions to {email} or send them to us via postal mail (marked “Attn: Legal Department”) at the appropriate address listed below. The {project} entity identified below is responsible for providing the {project} services to your region and is the controller of personal data collected under this policy.</p>
<p>{project} Cloud, 141 MIDDLE ROAD #05-06 GSM BUILDING SINGAPORE(188976).</p>
`
