export default `
<h3>1. Common Rules</h3>
<p>Thank you for using Cyberline .</p>
<p>These Terms of Use govern the conditions of use of all services provided by the Cyberline and apply to all customers who use the Services. Please read the following items carefully.</p>

<h3>1.1. Consent to Terms of Use</h3>
<p>By using the Services, you consent to these Terms of Use. If you do not consent to the Terms of Use, you may not use the Services.</p>

<h3>1.2. Accounts</h3>
<p>In some cases, an account may be required for use of the Services. You may create an account yourself.</p>
<p>When creating an account, you are obligated to register information that is true and accurate, and to personally amend registered content, as appropriate, to ensure that such content is up-to-date.</p>
<p>For purposes of account protection, passwords should not be shared with others. You are personally liable for all acts performed via your account. The Company is entitled to deem any act performed via your account to be an act committed by you.</p>

<h3>1.3. Expected Behavior</h3>
<p>Cyberline is a forum for holding civil, evidence-based discussion. Do not post disingenuously, or in an inflammatory manner for the sole purpose of upsetting others. Negative commentary and minority opinions are not frowned upon, but members are expected to be able to substantiate their positions. At the same time, do not derail threads around yourself or attempt to respond to every single reaction among dozens after posting a comment that is reacted to severely en masse.</p>
<p>A. Language</p>
<p>You agree, through your use of this service, that you will not use Cyberline to post any material which is knowingly false and/or defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, profane, sexually oriented, threatening, invasive of a person's privacy, or otherwise in violation of any law. Sexual, racial, or ethnic slurs will not be tolerated in any form and are bannable on the first offense. It is recommended that profanity be held to a minimum in order to keep the level of discourse high, and profanity should not be used when addressing others. </p>
<p>Though Cyberline is an international forum, discussion is to be held in English unless otherwise designated.</p>
<p>B. Images</p>
<p>Images of a sexual or profane nature are discouraged. Images presenting exposed genitalia, bared buttocks, and/or female nipple/aureola are not allowed. Outside of this, common sense and a "safe-for-work" rule should be the primary considerations. Do not spam meme images or any other image macro. Avatar images should be static and entirely "G-rated" and family-friendly.</p>
<p>C. Copyrighted Material</p>
<p>Full transcriptions of articles and whole or partially-scanned images (e.g. magazine scans) are prohibited. Standard fair-use policies apply, and sources should always be attributed.</p>
<p>D. Emulation/Piracy</p>
<p>The topics of emulation and piracy in the context of the technical nature of emulators and ROM images, hardware modification technology, as well as their effect on the industry as a political topic are deemed to be generally acceptable.</p>
<p>Linking to pirate download sites, directions on how to get pirated software to work, reviews or impressions of pirated software, and livestreams of pirated software play are all strictly prohibited.</p>
<p>E. Self-Promotion and Spam</p>
<p>Do not create new threads promoting a product or service you have any involvement in, or paste PR materials for promotional purposes, or attempt to embed referral links in your posts to generate revenue. If an existing discussion is taking place directly involving something you are associated with, you may participate in order to answer questions, clarify details, and otherwise engage with the community, but as a person, not an advertisement.</p>
<p>These aforementioned Terms of Service are subject to change at any time without notification.</p>

`
