export default `
<p>{projectUpperCase} CLOUD PTE. LED. including the associated software, materials and services (“{project}”) is a cloud-based service that enables users to use a virtual PC for gaming on a membership basis from an internet-connected device.</p>
<p>These terms are a legal agreement between you and {projectUpperCase} CLOUD PTE. LED. ("{project}") for the use of {project} Cloud Gaming Services. By registering for or using {project}, you are affirming that you have read and agree to these terms.</p>
<p>These terms can be accepted only by an adult of legal age of majority in the country in which {project} is used. If you are under the legal age of majority, you must ask your parent or legal guardian to consent to these terms. If you are not old enough to enter into these terms or if you don’t accept all the terms and conditions below, do not use {project}.</p>
<h3 class="underline">1. LICENSE.</h3>
<p>1.1. Grant. Subject to these terms, {project} grants you a non-commercial, personal, non-exclusive, non-transferable license to access and use {project} during the term of services through a supported device, one session at a time, for your entertainment with video games. The list of {project} supported devices is available on {project} websites.</p>
<p>1.2 Performance. A number of factors may affect your use of {project}, or may impede or interfere with your ability to start or continue using {project} or certain content or may result in the loss of data such as game saves, and you understand that {project} is not responsible for these: (a) any factors or circumstances outside of {project}’s reasonable control, including issues with your device, telecommunications, firewall, your service providers, or the public internet; (b) the type of device you have, your location, the available bandwidth, or the speed of your internet connection, and in some cases {project} may decrease the resolution and bitrate of the content streamed to you in an effort to provide a better user experience; or (c) other reasonable technical reasons, errors, or bandwidth constraints.</p>
<h3 class="underline">2. CONTENT.</h3>
<p>2.1 Content. {project} is renting you a virtual PC for gaming, and it is your responsibility to have sufficient rights to use the content (i.e. third-party video games or DLC (downloadable content)). Certain content may only work on certain types of devices. Not all the content you have may be used when streaming from {project}. Content purchased from a digital store on {project} may not be available to stream from {project}. The availability of content on {project} may vary over time, as examples: terms from service providers or game publishers may change, content may not be available from {project} or in certain geographies or languages, and content that was previously available on {project} (including your saved games) may become unavailable later on {project}. When you obtain content, whether through {project} or separately, the content provider may require you to accept their terms, open a user account and/or may charge you for the content.</p>
<p>From time to time, you may find content from a content provider that is available from {project} at no charge to select users, based on territory, device, membership plan, or other promotional criteria. Promotional offerings may last for a limited time and may change or end without notice. Activities and transactions between you and a content provider (as examples, content settings, use, purchases and related advertising), even if taking place through {project}, are solely between you and the content provider. {projectUpperCase} CLOUD PTE. LED. does not endorse or assume any responsibility for any content, or for information, materials, products or services that users receive from other parties or load for use with {project}.</p>
<p>2.2 Content for Different Ages. You acknowledge that on {project} you will have access to content rated for different ages, and it is up to you to set the filters for your permitted users to use age-appropriate content. You must follow the age rating rules of your country and laws.</p>
<p>2.3 Content Management. You acknowledge and agree that {project} may, but is not obligated to, block, monitor, scan or review communications or content transmitted through {project} and that {project} reserves the right to stop communications or remove content as described in section 10. {project} accepts no responsibility or liability for content from other parties (yours or of content providers) regardless of whether such content is transmitted to or by you in breach of these terms.</p>
<h3 class="underline">3. LIMITATIONS.</h3>
<p>3.1 You may not use {project}, or load content to {project}, for any purpose other than as described in section 1 above and as permitted under the laws, regulations and guidelines of your place of use.</p>
<p>3.2 You may not copy, sell, rent, sublicense, transfer or distribute any portion of {project}, and you may not use {project} commercially or for crypto currency mining.</p>
<p>3.3 You may not reverse engineer, decompile, disassemble, modify, create derivative works, or remove copyright or other proprietary notices from any portion of {project}.</p>
<p>3.4 You may not misuse, disrupt or exploit {project} Cloud Gaming, Inc servers for any unauthorized use, or try to access areas or download software or materials not intended for users (including using {project} in any way to access any unauthorized third-party sites), or load to {project} servers any malware (such as viruses, drop dead device, worm, trojan horse, trap, back door or other software routine of such nature), or disturb the use and enjoyment of {project} by other users.</p>
<h3 class="underline">4. PRE-RELEASE SERVICE and FEATURES.</h3>
<p>{project} features or versions may be identified as alpha, beta, preview or otherwise in pre-release, may not be fully functional, may contain errors or design flaws, and may have reduced or different security, privacy, accessibility, availability, and reliability standards. Use of a pre-release version may result in unexpected results, such as loss of use or loss of content. You may use a pre-release version at your risk and stop at your convenience.</p>
<h3 class="underline">5. OWNERSHIP.</h3>
<p>{project}, including all intellectual property rights, is and will remain the sole and exclusive property of {projectUpperCase} CLOUD PTE. LED. or its licensors. Except as expressly granted in these terms, {project} reserves all rights, interests, and remedies in connection with {project}. You agree to cooperate with {projectUpperCase} CLOUD PTE. LED. and provide reasonably requested information to verify your compliance with these terms.</p>
<h3 class="underline">6. UPDATES.</h3>
<p>{project} may change, discontinue, or deprecate any part, or all, of {project}, or change or remove features, functionality or content (including games) available, and your continued use of {project} is deemed acceptance of such changes. In addition, you agree to receive automatic maintenance, updates and/or upgrades without notice and you permit {project} to download and install them for you. {project} may be temporarily unavailable, inaccessible or slow when {project} is performing maintenance or upgrades or at other times for reasons not within {project}’s reasonable control.</p>
<h3 class="underline">7. LOG-IN INFORMATION.</h3>
<p>You are responsible for maintaining your log-in information secure for your use only, and for the activities under your account. You agree to notify {project} of any known unauthorized use of your {project} account.</p>
<h3 class="underline">8. IMPORTANT SAFETY AND HEALTH NOTICE.</h3>
<p>{project} hopes you will find the use of {project} enjoyable. However, for some individuals under certain circumstances, using {project} (or content on {project}) might have harmful effects on your health or safety. As an example, a very small percentage of individuals may experience epileptic seizures or blackouts when exposed to certain light patterns or flashing lights. Also, discontinue using {project} (or content on {project}) if you experience health problems or symptoms including (but not limited to) light-headedness, dizziness, or other discomfort, pain or health concern and consult your physician when appropriate. You are responsible for managing your health risks and choosing whether or not to use {project}. You waive {projectUpperCase} CLOUD PTE. LED.’s liability for any liabilities, damages, losses, costs and expenses that result from these risks.</p>
<h3 class="underline">9. SUSPENSION / TERMINATION.</h3>
<p>{projectUpperCase} CLOUD PTE. LED. may suspend or terminate your right to use {project}, or content on {project}, at its discretion, including (but not limited to) non-payment of applicable fees or if {project} reasonably suspects or determines that a use does not comply with these terms, the rights of others, or applicable laws and regulations.</p>
<p>{project} may investigate violations of these terms or misuse of {project} and may report activities to law enforcement, regulators or other parties.</p>
<p>All provisions of these terms which by their nature should survive termination will survive termination, including, without limitation, ownership provisions, warranty disclaimers and limitations of liability.</p>
<h3 class="underline">10. DATA COLLECTION.</h3>
<p>You hereby acknowledge that to use {project} and depending on your privacy preferences, {project} may access and collect data in order to: (a) properly configure and optimize the device in which {project} is installed for use with the device; (b) deliver content or service through {project}; (c) improve {projectUpperCase} CLOUD PTE. LED. products and services; and (d) provide automatic updates.</p>
<p>To improve the performance and quality at which {project} streams games to your machine and to run and update software and materials on your device, {project} will need to process system data such as software version numbers, geographical region, internet speed, games played, session data and system information such as CPU, GPU, device serial number, and system memory.</p>
<p>If you opt-in to share Technical Data from your device to help {project} find and fix bugs or improve the quality of services, you will be sharing device and accessory information, software (including settings, usage, and how well they run), feature usage, error logs, and geographical information. If you opt-in to Recommendations, {project} may inform you of games, apps, and rewards you may enjoy.</p>
<p>Any data will be collected, used and disclosed by {project} in accordance with {projectUpperCase} CLOUD PTE. LED.’s Privacy Policy.</p>
<p>{project} may contain links to websites and services. {projectUpperCase} CLOUD PTE. LED. does not endorse or assume any responsibility for any third-party websites, products, services or information. If you access a third-party website or service, you do so at your own risk, and you understand that these terms and {project}’s Privacy Policy do not apply. {projectUpperCase} CLOUD PTE. LED. encourages you to review the privacy statements on those websites and services that you choose to visit so that you can understand how they may collect, use and share your information.</p>
<h3 class="underline">11. NO WARRANTIES.</h3>
<p>{projectUpperCase} IS PROVIDED BY {projectUpperCase} CLOUD PTE. LED. “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS.” {projectUpperCase} EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND OR NATURE, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT. NO WARRANTY IS MADE ON THE BASIS OF TRADE USAGE, COURSE OF DEALING OR COURSE OF TRADE. WITHOUT LIMITING THE FOREGOING, {projectUpperCase} CLOUD PTE. LED. DOES NOT WARRANT THAT ACCESS TO {projectUpperCase} WILL BE UNINTERRUPTED; THAT {projectUpperCase} WILL MEET YOUR REQUIREMENTS; THAT {projectUpperCase} WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; THAT ANY CERTAIN CONTENT WILL BE AVAILABLE; OR THAT {projectUpperCase} IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
<h3 class="underline">12. LIMITATION OF LIABILITY.</h3>
<p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, {projectUpperCase} CLOUD PTE. LED. AND ITS AFFILIATES SHALL NOT BE LIABLE FOR ANY SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, OR FOR ANY LOST PROFITS, LOSS OF USE, LOSS OF DATA OR LOSS OF GOODWILL, OR THE COSTS OF PROCURING SUBSTITUTE PRODUCTS, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE USE OR THE PERFORMANCE OF {projectUpperCase}, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER CAUSE OF ACTION OR THEORY OF LIABILITY. IN NO EVENT WILL {projectUpperCase} CLOUD PTE. LED. AND ITS AFFILIATES TOTAL CUMULATIVE LIABILITY UNDER OR ARISING OUT OF THESE TERMS EXCEED THE AMOUNTS PAID FOR {projectUpperCase} USE DURING THE THREE MONTHS PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY. THE NATURE OF THE LIABILITY OR THE NUMBER OF CLAIMS OR SUITS SHALL NOT ENLARGE OR EXTEND THIS LIMIT. THE FOREGOING LIMITATIONS SHALL APPLY REGARDLESS OF WHETHER {projectUpperCase} CLOUD GAMING INC. OR ITS LICENSORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER ANY REMEDY FAILS ITS ESSENTIAL PURPOSE.</p>
<h3 class="underline">13. GOVERNING LAW; DISPUTE RESOLUTION</h3>
<p>Governing Law. These terms and all disputes that may arise from it or out of use of {project} in interstate commerce shall be governed by the Federal Arbitration Act, in addition to the internal substantive laws of the United States, without regard to or application of its conflict of laws rules or principles. The United Nations Convention on Contracts for the International Sale of Goods is expressly disclaimed. You agree to these terms in the English language unless {projectUpperCase} CLOUD PTE. LED. provided you these terms in another language.</p>
<p>Arbitration. For any claim against or dispute with {projectUpperCase} CLOUD PTE. LED. relating to these terms or your use of {project}, please give {projectUpperCase} CLOUD PTE. LED. a chance to resolve it and contact {projectUpperCase} CLOUD PTE. LED. by mail at 141 MIDDLE ROAD #05-06 GSM BUILDING SINGAPORE (188976)</p>
<p> . For any disputes that are not resolved informally, you and {projectUpperCase} CLOUD PTE. LED. each agree to resolve any such dispute (excluding any {projectUpperCase} CLOUD PTE. LED. claims for injunctive or other equitable relief) by binding arbitration before an arbitrator from Judicial Mediation and Arbitration Services ("JAMS"). Nothing in these terms shall prevent a party from seeking injunctive or other equitable relief from the courts in any jurisdiction to prevent the actual or threatened violation of that party's data security, intellectual property rights, or other proprietary rights.</p>
<p>Class Action & Jury Trial Waiver. ALL CLAIMS MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION UNLESS SUCH ARBITRATION IS NECESSARY TO EFFECTUATE THE ENFORCEMENT OF THE COURT CLASS ACTION WAIVER OR IN THE EVENT THAT CLASS ARBITRATION IS EXPRESSLY AGREED TO BY {projectUpperCase} CLOUD PTE. LED. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND {projectUpperCase} CLOUD PTE. LED. ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</p>
<p>Right to Opt-Out. YOU MAY OPT-OUT OF THE FOREGOING ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION OF THESE TERMS BY NOTIFYING {projectUpperCase} CLOUD PTE. LED. IN WRITING WITHIN 30 DAYS OF COMMENCEMENT OF USE OF {projectUpperCase}. SUCH WRITTEN NOTIFICATION SHALL BE SENT TO 141 MIDDLE ROAD #05-06 GSM BUILDING SINGAPORE (188976)</p>
<p> AND SHALL INCLUDE (1) YOUR NAME, (2) YOUR ADDRESS, (3) THE REFERENCE TO {projectUpperCase} AS THE SERVICE THE NOTICE RELATES TO, AND (4) A CLEAR STATEMENT INDICATING THAT YOU DO NOT WISH TO RESOLVE DISPUTES THROUGH ARBITRATION AND DEMONSTRATING COMPLIANCE WITH THE 30-DAY TIME LIMIT TO OPT-OUT.</p>
<h3 class="underline">14. GENERAL</h3>
<p>You agree that you will not transfer or assign these terms or your rights and obligations by any means or operation of law without {projectUpperCase} CLOUD PTE. LED.’s permission. These terms do not create any third-party beneficiary rights.</p>
<p>If {projectUpperCase} CLOUD PTE. LED. needs to contact you about {project} or content, you consent to receive notices by email or through the software interface and agree that electronic notice will satisfy any legal communication requirements. Please direct your legal notices or other correspondence to 141 MIDDLE ROAD #05-06 GSM BUILDING SINGAPORE (188976)</p>
<p>You may, but don’t have to, provide to {projectUpperCase} CLOUD PTE. LED. any use information or suggestions, feature requests, or other feedback regarding {project} and its contents, including possible enhancements or modifications to {project}. For any feedback that you voluntarily provide, you hereby grant {projectUpperCase} CLOUD PTE. LED. and its affiliates a perpetual, non-exclusive, worldwide, irrevocable license to use, reproduce, modify, license, sublicense (through multiple tiers of sublicensees), and distribute (through multiple tiers of distributors) it without the payment of any royalties or fees to you. {projectUpperCase} CLOUD PTE. LED. will decide if and how to respond to feedback and if to incorporate feedback into {project}.</p>
<p>By accepting these terms, you confirm that you are not currently residing or ordinarily reside in any country or region currently embargoed by the U.S.A. and that you are not otherwise prohibited (e.g. SDN, DPL) from interacting with a U.S.A. company.</p>
<p>These terms constitute the entire agreement between you and {projectUpperCase} CLOUD PTE. LED. concerning {project}. If it turns out that any provision of these terms is not unenforceable, such provision will be construed as limited to the extent necessary to be consistent with and fully enforceable under the law and the remaining provisions will remain in full force and effect. No waiver or failure to assert a right under these terms shall be deemed a further or continuing waiver of such right or any other right. Unless otherwise specified, remedies are cumulative.</p>
<p>{project} may make changes to these terms from time to time. The updated terms will be effective when published. Please review the terms of use on a regular basis. You understand and agree that your express acceptance of the updated terms or use of {project} after the date of publication shall constitute your agreement to the updated terms of use. If you do not agree with the updated terms, you must terminate your use of {project}.</p>
<p>(v. September 24, 2020)</p>

  `
