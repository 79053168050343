export default {
  slogan: 'ข้อเสนอสุดพิเศษสำหรับวีไอพีเท่านั้น!',
  introduce_1: 'เวลาเล่นเกม 20 ชั่วโมงในแต่ละเดือน',
  introduce_2: 'คิวลำดับความสำคัญสูงพิเศษ',
  introduce_3: 'มีเกมทั้งหมด',
  encourage_only: 'เพียง {money}',
  encourage_discounts: 'ประหยัด {discounts}%',
  monthUnit: 'เดือน',
  agreementInstructionMain: 'การเรียกเก็บเงินซ้ำยกเลิกได้ตลอดเวลา',
  agreementInstructionTitle: 'คำแนะนำบริการต่ออายุอัตโนมัติ',
  agreementInstructionItem_1: '1. หลังจากเปิดให้บริการแล้วการต่ออายุอัตโนมัติจะดำเนินการในแต่ละเดือนธรรมชาติและขยายบริการวีไอพีเป็นเวลาหนึ่งเดือน',
  agreementInstructionItem_2: '2. หากคุณต้องการยกเลิกการต่ออายุกรุณาปิดภายใน 48 ชั่วโมงเป็นอย่างน้อยในช่วงเวลาหัก ณ ที่จ่าย',
  agreementInstructionItem_3: '3. วิธียกเลิก: คุณสามารถยกเลิกได้ตลอดเวลาในการสมัครรับข้อมูลบน Paypal'
}
