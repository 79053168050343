
/* global WXEnvironment */
export const inBrowser = typeof window !== 'undefined'
export const inWeex = typeof WXEnvironment !== 'undefined' && !!WXEnvironment.platform
export const weexPlatform = inWeex && WXEnvironment.platform.toLowerCase()
export const UA = inBrowser && window.navigator.userAgent.toLowerCase()
export const isIE = UA && /msie|trident/.test(UA)
export const isIE9 = UA && UA.indexOf('msie 9.0') > 0
export const isEdge = UA && UA.indexOf('edge/') > 0
export const isAndroid = (UA && UA.indexOf('android') > 0) || (weexPlatform === 'android')
export const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA)) || (weexPlatform === 'ios')
export const isChrome = UA && /chrome\/\d+/.test(UA) && !isEdge
export const isPhantomJS = UA && /phantomjs/.test(UA)
export const isFF = UA && UA.match(/firefox\/(\d+)/)

export function paypalOrderSuccess (data) {
  if (!window.webkit && !window.dalongNetboom) return
  if (window.dalongNetboom) {
    // Android
    return new Promise(resolve => {
      window
        .dalongNetboom
        .orderSuccess(JSON.stringify(data || {}))
      resolve({})
    })
  } else {
    // IOS
    return new Promise(resolve => {
      window
        .webkit
        .messageHandlers
        .orderSuccess
        .postMessage(data, (response) => {
          resolve(response)
        })
    })
  }
}

export function checkSuccess (data, orderid) {
  if (!window.webkit && !window.dalongNetboom) return
  if (window.dalongNetboom) {
    // Android
    return new Promise(resolve => {
      window
        .dalongNetboom
        .checkIsSuccess(data, orderid)
      resolve({})
    })
  } else {
    // IOS
    window
      .webkit
      .messageHandlers
      .checkIsSuccess
      .postMessage({ data: data, orderid: orderid })
  }
}

export function appgoBack () {
  if (!window.webkit && !window.dalongNetboom) return
  if (window.dalongNetboom) {
    // Android
    return new Promise(resolve => {
      window
        .dalongNetboom
        .goBack()
      resolve({})
    })
  } else {
    // IOS
    return new Promise(resolve => {
      window
        .webkit
        .messageHandlers
        .goBack
        .postMessage(data => {
          resolve()
        })
    })
  }
}
export function showBuyState (bool) {
  if (!window.webkit && !window.dalongNetboom) return
  if (window.dalongNetboom) {
    // Android
    return new Promise(resolve => {
      window
        .dalongNetboom
        .showBuyState(bool)
      resolve({})
    })
  } else {
    // IOS
    // return new Promise(resolve => {
    //   window
    //     .webkit
    //     .messageHandlers
    //     .goBack
    //     .postMessage(data => {
    //       resolve()
    //     })
    // })
  }
}
export function paypalOrderFailed (data) {
  if (!window.webkit && !window.dalongNetboom) return
  if (window.dalongNetboom) {
    // Android
    window
      .dalongNetboom
      .orderFailed(data)
  } else {
    // IOS
    window
      .webkit
      .messageHandlers
      .orderFailed
      .postMessage(data)
  }
}

export function subscriptionSuccess (data) {
  if (!window.webkit) return Promise.resolve()
  return new Promise(resolve => {
    window
      .webkit
      .messageHandlers
      .subscriptionSuccess
      .postMessage(data, (response) => {
        resolve(response)
      })
  })
}

export function subscriptionFailed (data) {
  if (!window.webkit) return
  window
    .webkit
    .messageHandlers
    .subscriptionFailed
    .postMessage(data)
}

const TOKEN_KEY = 'NETBOOM_IOS_TOKEN'

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}

export const setToken = (token) => {
  return localStorage.setItem(TOKEN_KEY, token || '')
}

export function formatFirstUpperCase (str) {
  const reg = /\b(\w)|\s(\w)/g
  return str.toLowerCase().replace(reg, function (m) { return m.toUpperCase() })
}
