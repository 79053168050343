/* eslint-disable no-irregular-whitespace */
export default `
<p class = "p-content">
<b>Thỏa thuận dịch vụ VIP</b>
</p>
<p class = "p-content">
Chào mừng bạn đến sử dụng dịch vụ VIP của chúng tôi (sau đây gọi là “Dịch vụ”). Vui lòng đọc kỹ thỏa thuận này trước khi bạn sử dụng Dịch vụ của chúng tôi. Bằng cách đăng ký Dịch vụ của chúng tôi, bạn đồng ý bị ràng buộc theo các điều khoản và điều kiện như sau,
</p>
<p class = "p-content">
<b>1. Mô tả dịch vụ</b>
</p>
<p class = "p-content">
1.1 Dịch vụ có thể là đăng ký tự động định kỳ hàng tháng. Sau khi bạn mua Dịch vụ thông qua Mạng, bạn sẽ được nhắc xác nhận việc mua hàng của mình với nhà cung cấp thanh toán hiện hành và phương thức thanh toán của bạn (có thể là thẻ của bạn hoặc tài khoản bên thứ ba như Google Play hoặc iTunes) (“Phương thức thanh toán” của bạn) sẽ được tính phí cho giao dịch mua trong ứng dụng theo giá được hiển thị cho bạn đối với Dịch vụ cũng như mọi khoản bán hàng hoặc thuế tương tự có thể áp dụng cho các khoản thanh toán của bạn.
</p>
<p class = "p-content">
1.2 Đăng ký sẽ tự động được gia hạn cho đến khi bạn chấm dứt hoặc hủy đăng ký. Sau khoảng thời gian cam kết đăng ký ban đầu của bạn và một lần nữa sau bất kỳ giai đoạn đăng ký tiếp theo nào, đăng ký của bạn sẽ tự động tiếp tục trong một khoảng thời gian tương đương bổ sung, với mức giá bạn đã đồng ý khi đăng ký.
</p>
<p class = "p-content">
1.3 Phương thức Thanh toán của bạn sẽ tiếp tục được lập hóa đơn trước hàng tháng trong vòng 24 giờ kể từ ngày mua ban đầu với mức giá bạn đã đồng ý khi đăng ký ban đầu. Thông tin thanh toán thẻ của bạn sẽ được lưu trữ và sau đó được sử dụng cho các khoản thanh toán thẻ tự động theo Thỏa thuận.
</p>
<p class = "p-content">
1.4 Sau mỗi lần thanh toán, bạn sẽ nhận được biên lai qua email. Việc phản đối một khoản thanh toán đã được thực hiện, nên được chuyển đến bộ phận Hỗ trợ khách hàng nếu bạn được {project} hoặc tài khoản bên thứ ba có liên quan, chẳng hạn như Google Play thanh toán trực tiếp. Bạn cũng có thể phản đối bằng cách liên hệ với ngân hàng hoặc nhà cung cấp dịch vụ thanh toán của mình, họ có thể cung cấp thêm thông tin về quyền của bạn cũng như các giới hạn thời gian áp dụng.
</p>
<p class = "p-content">
1.5 Bạn ủy quyền không hủy ngang cho {project} tài khoản của bên thứ ba, nếu có, tính phí bạn mà không cần xác nhận thông tin đăng nhập, bao gồm nhưng không giới hạn ở mật khẩu, thông tin nhận dạng của bạn, phí đăng ký trong 30 ngày tiếp theo trước khi hết hạn 30 ngày đối với đăng ký hiện tại. Việc tính phí không thành công sẽ được coi là bạn đã chấm dứt Dịch vụ
</p>
<p class = "p-content">


<b>2. Quyền lợi</b>
</p>
<p class = "p-content">
Bằng cách đăng ký Dịch vụ, bạn được hưởng các quyền như trang VIP đã hiển thị.
</p>
<p class = "p-content">


<b>3. phí dịch vụ</b>
</p>
<p class = "p-content">
{project} có ​​thể điều chỉnh Phí dịch vụ theo định kỳ và hiển thị sự điều chỉnh đó cho bạn để xác nhận.

 </p>
<p class = "p-content">

<b>4. kết thúc</b>
</p>
<p class = "p-content">
4.1 Bạn có thể chấm dứt Dịch vụ bất cứ khi nào bạn chọn làm như vậy với điều kiện là bạn đồng ý rằng {project} sẽ không hoàn lại cho bạn phí dịch vụ trong thời gian cam kết đăng ký hiện tại của bạn.
</p>
<p class = "p-content">
4.2 {project} có ​​toàn quyền quyết định chấm dứt Dịch vụ có nguyên nhân hoặc không có lý do. {project} sẽ không chịu trách nhiệm pháp lý đối với bạn nếu {project} chấm dứt Dịch vụ theo lệnh hoặc nghị định của cơ quan có thẩm quyền hoặc nếu {project} tin tưởng một cách hợp lý rằng hành vi của bạn vi phạm quy định của nền tảng {project}.
</p>
<p class = "p-content">
4.3 {project} sẽ hoàn trả cho bạn phí dịch vụ cho bạn trong thời gian cam kết đăng ký hiện tại nếu {project} chấm dứt Dịch vụ mà không có bất kỳ lý do gì.
</p>
<p class = "p-content">
<b>5. điều khoản khác</b>
</p>
<p class = "p-content">
5.1 Thỏa thuận này là một phụ lục của Thỏa thuận người dùng {project} mà bạn đã ký kết với {project} khi đăng ký trên nền tảng của chúng tôi.
</p>
<p class = "p-content">
5.2 Bất kỳ sự khác biệt hoặc khác biệt nào giữa Thỏa thuận này và Thỏa thuận người dùng {project}, Thỏa thuận này sẽ được ưu tiên áp dụng.
</p>
`
