export default `
<p class = "p-content">
<b>Perjanjian Layanan VIP</b>
</p>
<p class = "p-content">
Selamat menggunakan layanan VIP kami (selanjutnya disebut sebagai "Layanan"). Harap baca perjanjian ini dengan cermat sebelum Anda menggunakan Layanan kami. Dengan berlangganan Layanan kami, Anda setuju untuk terikat dengan syarat dan ketentuan sebagai berikut,
</p>
<p class = "p-content">
<b>1. Deskripsi Layanan</b>
</p>
<p class = "p-content">
1.1 Layanan dapat menjadi langganan bulanan yang berulang secara otomatis. Setelah Anda membeli Layanan melalui{project}, Anda akan diminta untuk mengkonfirmasi pembelian Anda dengan penyedia pembayaran yang berlaku, dan metode pembayaran Anda (baik itu kartu Anda atau akun pihak ketiga seperti Google Play atau iTunes) ("Metode Pembayaran" Anda) akan ditagih untuk pembelian dalam aplikasi dengan harga yang ditampilkan kepada Anda untuk Layanan serta setiap penjualan atau pajak serupa yang mungkin dikenakan pada pembayaran Anda.
</p>
<p class = "p-content">
1.2 Langganan diperpanjang secara otomatis, sampai Anda menghentikan atau membatalkan langganan. Setelah periode komitmen langganan awal Anda, dan sekali lagi setelah periode langganan berikutnya, langganan Anda secara otomatis akan berlanjut untuk periode tambahan yang setara, dengan harga yang Anda setujui saat berlangganan.
</p>
<p class = "p-content">
1.3 Metode Pembayaran Anda akan terus ditagih setiap bulan dalam waktu 24 jam sejak tanggal pembelian awal dengan harga yang Anda setujui saat pertama kali berlangganan. Informasi pembayaran kartu Anda akan disimpan dan selanjutnya digunakan untuk pembayaran kartu otomatis sesuai dengan Perjanjian.
</p>
<p class = "p-content">
1.4 Setelah setiap pembayaran Anda akan menerima tanda terima melalui email. Keberatan atas pembayaran yang telah dilakukan, harus ditujukan ke dukungan Pelanggan jika Anda ditagih langsung oleh {project} atau akun pihak ketiga yang relevan seperti Google Play. Anda juga dapat mengajukan keberatan dengan menghubungi bank atau penyedia pembayaran Anda, yang dapat memberikan informasi lebih lanjut tentang hak Anda serta batas waktu yang berlaku.
</p>
<p class = "p-content">
1.5 Anda memberikan otorisasi yang tidak dapat ditarik kembali kepada {project} akun pihak ketiga, sebagaimana berlaku, untuk menagih Anda, tanpa konfirmasi kredensial, termasuk tetapi tidak terbatas pada kata sandi Anda, informasi identitas Anda, biaya berlangganan untuk 30 hari berikutnya sebelum berakhirnya 30 hari untuk Anda langganan saat ini. Pembebanan biaya yang tidak berhasil akan dianggap sebagai penghentian Layanan Anda
</p>
<p class = "p-content">


<b>2. Hak</b>
</p>
<p class = "p-content">
Dengan berlangganan Layanan, Anda berhak atas hak seperti yang ditunjukkan halaman VIP.

 </p>
<p class = "p-content">

<b>3. Biaya Layanan</b>
</p>
<p class = "p-content">
{project} dapat menyesuaikan Biaya Layanan secara berkala dan menampilkan penyesuaian tersebut kepada Anda untuk konfirmasi.
</p>
<p class = "p-content">


<b>4. Pengakhiran</b>
</p>
<p class = "p-content">
4.1 Anda dapat menghentikan Layanan kapan pun Anda memilih untuk melakukannya asalkan Anda setuju bahwa {project} tidak akan mengembalikan biaya layanan untuk periode komitmen berlangganan Anda saat ini.
</p>
<p class = "p-content">
4.2 {project} memiliki kebijakan mutlaknya untuk menghentikan Layanan dengan sebab atau tanpa sebab. {project} tidak akan bertanggung jawab kepada Anda jika {project} menghentikan Layanan sesuai dengan perintah atau keputusan dari otoritas yang berwenang atau jika {project} secara wajar yakin bahwa perilaku Anda melanggar peraturan platform {project}.
</p>
<p class = "p-content">
4.3 {project} akan mengembalikan kepada Anda biaya layanan untuk periode komitmen berlangganan Anda saat ini jika {project} menghentikan Layanan tanpa alasan apa pun.
</p>
<p class = "p-content">
<b>5. Lain-lain</b>
</p>
<p class = "p-content">
5.1 Perjanjian ini adalah tambahan dari Perjanjian Pengguna {project} yang telah Anda buat dengan {project} saat mendaftar di platform kami.
</p>
<p class = "p-content">
5.2 Setiap perbedaan atau ketidaksesuaian antara Perjanjian ini dan Perjanjian Pengguna {project}, Perjanjian ini yang akan berlaku.
</p>
`
