export default [
  {
    path: '/tips',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'Tips'
    },
    children: [
      {
        path: '/tips/Redeem',
        name: 'RedeemTips',
        component: () => import('@/views-rem/tips_page/TipsPage.vue'),
        meta: {
          title: 'RedeemTips'
        }
      }
    ]
  }
]
