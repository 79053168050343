import PrivacyPolicyqf from '@/i18n/agreements/PrivacyPolicyqf'
import ServiceClauseqf from '@/i18n/agreements/ServiceClauseqf'
import h5Cyberline from '@/i18n/agreements/h5Cyberline'
import VIPServiceAgreement from '@/i18n/agreements/VIPServiceAgreement'
import IOSPrivacy from '@/i18n/agreements/IOSPrivacy'
import h5TechnicalSupport from '@/i18n/agreements/h5TechnicalSupport'

import h5PrivacyPolicy from '@/i18n/agreements/h5PrivacyPolicy'
import IOSTerms from '@/i18n/agreements/IOSTerms'
import h5Terms from '@/i18n/agreements/h5Terms'
import h5NetboomGame from '@/i18n/agreements/h5NetboomGame'
import IOSPrivacyV2 from '@/i18n/agreements/IOSPrivacyV2'
import IOSTermsV2 from '@/i18n/agreements/IOSTermsV2'
import TreasureBox from '@/i18n/rules/TreasureBox'
import NetboomPrivacyPolicyqf from '@/i18n/netboom/PrivacyPolicyqf'
import NetboomServiceClauseqf from '@/i18n/netboom/ServiceClauseqf'
import NetboomVIPServiceAgreement from '@/i18n/netboom/VIPServiceAgreement'
import subscribe from '@/i18n/subscribe/en.js'
export default {
  subscribe,
  rules: {
    TreasureBox: TreasureBox.en
  },
  agreement: {
    h5TechnicalSupport: h5TechnicalSupport.en,
    h5PrivacyPolicy: h5PrivacyPolicy.en,
    h5Cyberline: h5Cyberline.en,
    PrivacyPolicyqf: PrivacyPolicyqf.en,
    ServiceClauseqf: ServiceClauseqf.en,
    VIPServiceAgreement: VIPServiceAgreement.en,
    NetboomPrivacyPolicyqf: NetboomPrivacyPolicyqf.en,
    NetboomServiceClauseqf: NetboomServiceClauseqf.en,
    NetboomVIPServiceAgreement: NetboomVIPServiceAgreement.en,
    IOSPrivacy: IOSPrivacy.en,
    IOSTerms: IOSTerms.en,
    h5Terms: h5Terms.en,
    h5NetboomGame: h5NetboomGame.en,
    IOSPrivacyV2: IOSPrivacyV2.en,
    IOSTermsV2: IOSTermsV2.en
  },
  tips: {
    Redeem: 'Join netboom on discord, to participate in community events and win prizes.'
  },
  common: {}
}
