import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import agreements from './agreements'
import community from '@/router/community'
import rules from '@/router/rules'
import netboomAgreements from '@/router/netboomAgreements'
import paypal from '@/router/paypal'
import tips from '@/router/tips.js'
import netboom from '@/router/netboom.js'
import game from '@/router/game.js'
import payermax from '@/router/payermax.js'
Vue.use(VueRouter)

const devRoute = process.env.VUE_APP_ENVIRONMENT === 'prod' ? [] : [{
  path: '/README',
  name: 'README',
  component: Home
}]

const routes = [
  ...devRoute,
  ...agreements,
  ...community,
  ...rules,
  ...netboomAgreements,
  ...paypal,
  ...tips,
  ...netboom,
  ...game,
  ...payermax
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
