export default `
<div id="write" class="is-mac">
    <h2><a name="header-n0" class="md-header-anchor "></a>Privacy Policy</h2>

    <p>As used in this Privacy Policy, “<strong>{project}</strong>”, “We”, “Us” or “Our”, refers to
      <strong>{project}</strong> developed by <strong>{project} Team</strong>. Please read this Privacy Policy in order to
      learn more about the data we collect, what do we use it for and how we protect your privacy.</p>
    <h3><a name="header-n3" class="md-header-anchor "></a>What information do we collect?</h3>

    <p>We collect certain information from your mobile device, including but not limited to the following:</p>
    <p><strong>Device and Usage Information </strong>that may include(i) information specific to your mobile device
      (<em>e.g.,</em> make, model, operating system, advertising identifier and similar information); (ii) information
      about your use of features, functions, or notifications on the device; and (iii) signal strength relating to WiFi
      or Bluetooth functionality, temperature, battery level, and similar technical data.</p>
    <h3><a name="header-n6" class="md-header-anchor "></a>How we use the information we collect:</h3>

    <ul>
      <li>Operate and improve our services;</li>
      <li>Provide user and other support, including to our third-party business partners;</li>
      <li>Provide you with information when you enter participating local venues; </li>
      <li>Carry out other purposes that are disclosed to you and to which you consent.</li>
      <li>To personalize your experience
        (your information helps us to better respond to your individual needs)</li>
    </ul>
    <p>We will retain your information for as long as the application is installed on your mobile device or as needed to
      provide you service. If you remove the application, we will remove your information and use copies of your
      information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
    <h3><a name="header-n19" class="md-header-anchor "></a>Do we share any information to outside parties?</h3>

    <p>We share the information we collect with third-party business partners who commit to comply with applicable laws
      concerning their usage of the information. Our third-party business partners may share the information with their
      own third party business partners and use the information either for themselves or on behalf of their own business
      partners to:</p>
    <ul>
      <li>Operate and improve their services;</li>
      <li>Provide you with advertisements and information for products and services in this app or on other apps or
        websites based on variables such as your location, and assess your level of engagement with them;</li>
      <li>Carry out other purposes that are disclosed to you and to which you consent.</li>
    </ul>
    <h3><a name="header-n28" class="md-header-anchor "></a>Online Privacy Policy Only</h3>

    <p>This online privacy policy applies only to information collected through our website and not to information
      collected offline.</p>
    <h3><a name="header-n30" class="md-header-anchor "></a>Security</h3>

    <p>We use a variety of security technologies and procedures for the purpose of preventing loss, misuse, unauthorized
      access or disclosure of the Information. For example, in some of our Services, we use encryption technology to
      protect certain sensitive Information provided by you to us. Please note that no data security measures can
      guarantee 100% security at all times. Our systems and the communications networks through which you access our
      services may be subject to security breaches and failures which are due to circumstances beyond our reasonable
      control</p>
    <h3><a name="header-n32" class="md-header-anchor "></a>Advertising</h3>

    <p>We may collect and use information about you, such as your device identifier, MAC address, IMEI, geo-location
      information and IP address for the purpose of the tracking of the advertising. This information is used together
      to create a unique user number to help us to better count and track advertising based on language, geographic
      location and other relevant details. This information is NOT SOLD to outside parties.</p>
    <h3><a name="header-n34" class="md-header-anchor "></a>User Logs</h3>

    <p>When using our Services, including engaging with, watching or producing any content in relation to our Services,
      certain user log information in our application will be automatically collected, including (i) details on when and
      how often our Services are being used, (ii) devices statistics, critical operation paths, errors, crashes,
      language and time zone and so on. These user logs are used to improve our Services and are NOT USED to identify
      and track our users individually.</p>
    <h3><a name="header-n36" class="md-header-anchor "></a>Your Consent</h3>

    <p>By using our site, you consent to our web site privacy policy.</p>
    <h3><a name="header-n38" class="md-header-anchor "></a>Children Policy</h3>

    <p>Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s why we do not knowingly
      collect personal information from anyone under 13.</p>
    <h3><a name="header-n40" class="md-header-anchor "></a>Revision to the privacy policy</h3>

    <p>We may change this privacy policy from time to time. But when we do, we’ll let you know one way or another.
      Sometimes, we’ll let you know by revising the date at the top of the privacy policy that’s available on our
      website and mobile application. Other times, we may provide you with additional notice (such as adding a statement
      to our websites’ homepages or providing you with an in-app notification).</p>
    <h3><a name="header-n42" class="md-header-anchor "></a>Contacting Us</h3>

    <p>If there are any questions regarding this privacy policy you may contact us using the information below. </p>
    <p>
      <a href="mailto:{email}" target="_blank" class="url" style="
    color: #990000;
    text-decoration: none;
  ">{email}</a>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>

`
