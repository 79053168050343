export default {
  slogan: '专供VIP的超值優惠！',
  introduce_1: '每月20小時遊戲時間',
  introduce_2: '独家高優先級隊列',
  introduce_3: '所有可用的遊戲',
  encourage_only: '僅 {money}',
  encourage_discounts: '節省 {discounts}%',
  monthUnit: '月',
  agreementInstructionMain: '定期結算，隨時取消',
  agreementInstructionTitle: '自動續訂服務說明',
  agreementInstructionItem_1: '1.開通後，將在每個自然月進行自動續訂，並將VIP服務延長一個月；',
  agreementInstructionItem_2: '2.如果您想取消續簽，請在預扣期間至少48小時之內關閉；',
  agreementInstructionItem_3: '3.如何取消：您可以隨時在Paypal訂閱中取消'
}
