import PrivacyPolicyqf from '@/i18n/agreements/PrivacyPolicyqf'
import ServiceClauseqf from '@/i18n/agreements/ServiceClauseqf'
import VIPServiceAgreement from '@/i18n/agreements/VIPServiceAgreement'
import IOSPrivacy from '@/i18n/agreements/IOSPrivacy'
import IOSTerms from '@/i18n/agreements/IOSTerms'
import TreasureBox from '@/i18n/rules/TreasureBox'
import NetboomPrivacyPolicyqf from '@/i18n/netboom/PrivacyPolicyqf'
import NetboomServiceClauseqf from '@/i18n/netboom/ServiceClauseqf'
import NetboomVIPServiceAgreement from '@/i18n/netboom/VIPServiceAgreement'

import subscribe from '@/i18n/subscribe/in.js'

export default {
  subscribe,
  rules: {
    TreasureBox: TreasureBox.in
  },
  agreement: {
    PrivacyPolicyqf: PrivacyPolicyqf.in,
    ServiceClauseqf: ServiceClauseqf.in,
    VIPServiceAgreement: VIPServiceAgreement.in,
    NetboomPrivacyPolicyqf: NetboomPrivacyPolicyqf.in,
    NetboomServiceClauseqf: NetboomServiceClauseqf.in,
    NetboomVIPServiceAgreement: NetboomVIPServiceAgreement.in,
    IOSPrivacy: IOSPrivacy.in,
    IOSTerms: IOSTerms.in
  },
  tips: {
    Redeem: 'Join netboom on discord, to participate in community events and win prizes.'
  },
  common: {}
}
