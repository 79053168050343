import PrivacyPolicyqf from '@/i18n/agreements/PrivacyPolicyqf'
import ServiceClauseqf from '@/i18n/agreements/ServiceClauseqf'
import VIPServiceAgreement from '@/i18n/agreements/VIPServiceAgreement'
import IOSPrivacy from '@/i18n/agreements/IOSPrivacy'
import IOSTerms from '@/i18n/agreements/IOSTerms'
import TreasureBox from '@/i18n/rules/TreasureBox'
import NetboomPrivacyPolicyqf from '@/i18n/netboom/PrivacyPolicyqf'
import NetboomServiceClauseqf from '@/i18n/netboom/ServiceClauseqf'
import NetboomVIPServiceAgreement from '@/i18n/netboom/VIPServiceAgreement'

import subscribe from '@/i18n/subscribe/zh.js'

export default {
  subscribe,
  rules: {
    // 无繁体
    TreasureBox: TreasureBox.en
  },
  agreement: {
    PrivacyPolicyqf: PrivacyPolicyqf.zh,
    ServiceClauseqf: ServiceClauseqf.zh,
    VIPServiceAgreement: VIPServiceAgreement.zh,
    NetboomPrivacyPolicyqf: NetboomPrivacyPolicyqf.zh,
    NetboomServiceClauseqf: NetboomServiceClauseqf.zh,
    NetboomVIPServiceAgreement: NetboomVIPServiceAgreement.zh,
    IOSPrivacy: IOSPrivacy.zh,
    IOSTerms: IOSTerms.zh
  },
  tips: {
    Redeem: 'Join netboom on discord, to participate in community events and win prizes.'
  },
  common: {}
}
