import axios from 'axios'
import store from '@/store'
// import router from '@/router'
// import { setUserInfo } from '@/utils/index'
import { Toast } from 'vant'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASEURL, // url = base url + request url
  method: 'post',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (!config.url.includes('wss')) {
      config.headers.noencrypt = 1
      config.headers.notsign = 1
    }
    if (config.method === 'post') {
      config.data = {
        params: config.data,
        common: {
          device_id: store.state.service.idfv,
          appKey: store.state.service.appKey,
          aKey: store.state.service.aKey || 'H5',
          gaid: store.state.service.gaid || '',
          idfa: store.state.service.idfa || '',
          udid: '-1',
          appName: 'NetboomH5',
          pName: 'h5.netboom',
          pVersion: '100',
          t: Math.floor(Date.now() / 1000),
          lang: store.state.service.language,
          token: store.state.service.token
        }
      }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.data && [-3102, -3101].includes(response.data.code)) {
      Toast(response.data.msg)
      // Token expired
      // firebase.auth().signOut()
      //   .then(() => {
      //     setUserInfo()
      //     store.commit('app/updateSignState', false)
      //     store.commit('app/updateUserInfo', {})
      //     router.replace('/home')
      //   }).catch((error) => {
      //     console.log(error)
      //   })
    }

    return response.data
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
