export default `
<p class = "p-content">
<b>ข้อตกลงบริการ VIP</b>
</p>
<p class = "p-content">
ยินดีต้อนรับสู่การใช้บริการวีไอพีของเรา (ต่อไปนี้เรียกว่า“ บริการ”) โปรดอ่านข้อตกลงนี้อย่างละเอียดก่อนที่คุณจะใช้บริการของเรา ในการสมัครใช้บริการของเราคุณตกลงที่จะผูกพันภายใต้ข้อกำหนดและเงื่อนไขดังต่อไปนี้
</p>
<p class = "p-content">
<b>1. คำอธิบายบริการ</b>
</p>
<p class = "p-content">
1.1 บริการนี้สามารถสมัครสมาชิกรายเดือนแบบอัตโนมัติได้ หลังจากที่คุณซื้อบริการผ่าน {project} คุณจะได้รับแจ้งให้ยืนยันการซื้อของคุณกับผู้ให้บริการชำระเงินที่เกี่ยวข้องและวิธีการชำระเงินของคุณ (ไม่ว่าจะเป็นบัตรของคุณหรือบัญชีบุคคลที่สามเช่น Google Play หรือ iTunes) ("วิธีการชำระเงิน" ของคุณ) จะถูกเรียกเก็บเงินสำหรับการซื้อในแอปตามราคาที่แสดงให้คุณเห็นสำหรับบริการตลอดจนภาษีการขายหรือภาษีที่คล้ายกันที่อาจเรียกเก็บจากการชำระเงินของคุณ
</p>
<p class = "p-content">
1.2 การสมัครสมาชิกจะได้รับการต่ออายุโดยอัตโนมัติจนกว่าคุณจะยุติหรือยกเลิกการสมัครสมาชิก หลังจากระยะเวลาการสมัครสมาชิกครั้งแรกของคุณและอีกครั้งหลังจากระยะเวลาการสมัครสมาชิกครั้งต่อ ๆ ไปการสมัครของคุณจะดำเนินต่อไปโดยอัตโนมัติเป็นระยะเวลาเทียบเท่าเพิ่มเติมตามราคาที่คุณตกลงเมื่อสมัคร
</p>
<p class = "p-content">
1.3 วิธีการชำระเงินของคุณจะยังคงถูกเรียกเก็บเงินทุกเดือนล่วงหน้าภายใน 24 ชั่วโมงนับจากวันที่ซื้อครั้งแรกในราคาที่คุณตกลงเมื่อสมัครสมาชิกครั้งแรก ข้อมูลการชำระเงินด้วยบัตรของคุณจะถูกจัดเก็บและนำไปใช้ในการชำระเงินด้วยบัตรอัตโนมัติตามข้อตกลง
</p>
<p class = "p-content">
1.4 หลังจากการชำระเงินแต่ละครั้งคุณจะได้รับใบเสร็จทางอีเมล การคัดค้านการชำระเงินที่ดำเนินการไปแล้วควรส่งไปยังฝ่ายสนับสนุนลูกค้าหากคุณถูกเรียกเก็บเงินโดยตรงจาก {project} หรือบัญชีบุคคลที่สามที่เกี่ยวข้องเช่น Google Play นอกจากนี้คุณยังสามารถคัดค้านได้โดยติดต่อธนาคารหรือผู้ให้บริการชำระเงินซึ่งสามารถให้ข้อมูลเพิ่มเติมเกี่ยวกับสิทธิ์ของคุณตลอดจนการ จำกัด เวลาที่เกี่ยวข้อง
</p>
<p class = "p-content">
1.5 คุณอนุญาตให้ {project} บัญชีบุคคลที่สามเรียกเก็บเงินจากคุณโดยไม่ได้รับการยืนยันข้อมูลรับรองรวมถึง แต่ไม่ จำกัด เพียงรหัสผ่านข้อมูลประจำตัวของคุณค่าธรรมเนียมการสมัครสมาชิก 30 วันถัดไปก่อนที่จะหมดอายุ 30 วันสำหรับคุณ การสมัครสมาชิกปัจจุบัน การเรียกเก็บเงินที่ไม่สำเร็จจะถือว่าคุณยุติการใช้บริการ
</p>
<p class = "p-content">


<b>2. สิทธิ</b>
</p>
<p class = "p-content">
เมื่อสมัครใช้บริการคุณจะได้รับสิทธิ์ตามที่หน้า VIP แสดง

 </p>
<p class = "p-content">

<b>3. ค่าบริการ</b>
</p>
<p class = "p-content">
{project} อาจปรับค่าบริการเป็นระยะและแสดงการปรับปรุงดังกล่าวให้คุณเพื่อยืนยัน

 </p>
<p class = "p-content">

<b>4.Termination</b>
</p>
<p class = "p-content">
4.1 คุณสามารถยกเลิกบริการเมื่อใดก็ตามที่คุณเลือกที่จะทำเช่นนั้นโดยที่คุณตกลงว่า {project} จะไม่คืนเงินค่าบริการให้กับคุณสำหรับระยะเวลาข้อผูกพันในการสมัครสมาชิกปัจจุบันของคุณ
</p>
<p class = "p-content">
4.2 {project} ขอสงวนดุลพินิจในการยุติบริการโดยมีสาเหตุหรือไม่มีสาเหตุ {project} จะไม่รับผิดชอบต่อคุณหาก {project} ยุติการให้บริการตามคำสั่งหรือคำสั่งจากหน่วยงานที่มีอำนาจหรือหาก {project} เชื่ออย่างมีเหตุผลว่าการกระทำของคุณละเมิดกฎระเบียบของแพลตฟอร์ม {project}
</p>
<p class = "p-content">
4.3 {project} จะคืนเงินค่าบริการให้กับคุณสำหรับระยะเวลาการสมัครสมาชิกปัจจุบันของคุณหาก {project} ยุติบริการโดยไม่มีสาเหตุใด ๆ
</p>
<p class = "p-content">
<b>5.Miscellaneous</b>
</p>
<p class = "p-content">
5.1 ข้อตกลงนี้เป็นภาคผนวกของข้อตกลงผู้ใช้ {project} ที่คุณได้ทำกับ {project} เมื่อลงทะเบียนบนแพลตฟอร์มของเรา
</p>
<p class = "p-content">
5.2 ความแตกต่างหรือความคลาดเคลื่อนใด ๆ ระหว่างข้อตกลงนี้และข้อตกลงผู้ใช้ {project} ข้อตกลงนี้จะมีผลเหนือกว่า
</p>
`
