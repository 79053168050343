import PrivacyPolicyqf from '@/i18n/agreements/PrivacyPolicyqf'
import ServiceClauseqf from '@/i18n/agreements/ServiceClauseqf'
import VIPServiceAgreement from '@/i18n/agreements/VIPServiceAgreement'
import IOSPrivacy from '@/i18n/agreements/IOSPrivacy'
import IOSTerms from '@/i18n/agreements/IOSTerms'
import TreasureBox from '@/i18n/rules/TreasureBox'
import NetboomPrivacyPolicyqf from '@/i18n/netboom/PrivacyPolicyqf'
import NetboomServiceClauseqf from '@/i18n/netboom/ServiceClauseqf'
import NetboomVIPServiceAgreement from '@/i18n/netboom/VIPServiceAgreement'
import subscribe from '@/i18n/subscribe/ko.js'

export default {
  subscribe,
  rules: {
    TreasureBox: TreasureBox.ko
  },
  agreement: {
    PrivacyPolicyqf: PrivacyPolicyqf.ko,
    ServiceClauseqf: ServiceClauseqf.ko,
    VIPServiceAgreement: VIPServiceAgreement.ko,
    NetboomPrivacyPolicyqf: NetboomPrivacyPolicyqf.ko,
    NetboomServiceClauseqf: NetboomServiceClauseqf.ko,
    NetboomVIPServiceAgreement: NetboomVIPServiceAgreement.ko,
    IOSPrivacy: IOSPrivacy.ko,
    IOSTerms: IOSTerms.ko
  },
  tips: {
    Redeem: 'Join netboom on discord, to participate in community events and win prizes.'
  },
  common: {}
}
