export default [
  {
    path: '/community',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'Community'
    },
    children: [
      {
        path: '/community/discords',
        name: 'Discords',
        component: () => import('@/views/community/Discords'),
        meta: {
          title: 'Discords'
        }
      },
      {
        path: '/community/fb-groups',
        name: 'FbGroups',
        component: () => import('@/views/community/FbGroups'),
        meta: {
          title: 'FbGroups'
        }
      },
      {
        path: '/community/kakao',
        name: 'Kakao',
        component: () => import('@/views/community/Kakao'),
        meta: {
          title: 'Kakao'
        }
      }
    ]
  }
]
