import PrivacyPolicyqf from '@/i18n/agreements/PrivacyPolicyqf'
import ServiceClauseqf from '@/i18n/agreements/ServiceClauseqf'
import VIPServiceAgreement from '@/i18n/agreements/VIPServiceAgreement'
import IOSPrivacy from '@/i18n/agreements/IOSPrivacy'
import IOSTerms from '@/i18n/agreements/IOSTerms'
import TreasureBox from '@/i18n/rules/TreasureBox'
import NetboomPrivacyPolicyqf from '@/i18n/netboom/PrivacyPolicyqf'
import NetboomServiceClauseqf from '@/i18n/netboom/ServiceClauseqf'
import NetboomVIPServiceAgreement from '@/i18n/netboom/VIPServiceAgreement'

import subscribe from '@/i18n/subscribe/ms.js'

export default {
  subscribe,
  rules: {
    TreasureBox: TreasureBox.ms
  },
  agreement: {
    PrivacyPolicyqf: PrivacyPolicyqf.ms,
    ServiceClauseqf: ServiceClauseqf.ms,
    VIPServiceAgreement: VIPServiceAgreement.ms,
    NetboomPrivacyPolicyqf: NetboomPrivacyPolicyqf.ms,
    NetboomServiceClauseqf: NetboomServiceClauseqf.ms,
    NetboomVIPServiceAgreement: NetboomVIPServiceAgreement.ms,
    IOSPrivacy: IOSPrivacy.ms,
    IOSTerms: IOSTerms.ms
  },
  tips: {
    Redeem: 'Join netboom on discord, to participate in community events and win prizes.'
  },
  common: {}
}
