export default [
  {
    path: '/agreement',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'Agreement'
    },
    children: [
      {
        path: '/privacyPolicyqf',
        name: 'PrivacyPolicyqf',
        component: () => import('@/views/agreements/PrivacyPolicyqf.vue'),
        meta: {
          title: 'PrivacyPolicyqf'
        }
      },
      {
        path: '/serviceClauseqf',
        name: 'ServiceClauseqf',
        component: () => import('@/views/agreements/ServiceClauseqf.vue'),
        meta: {
          title: 'ServiceClauseqf'
        }
      },
      {
        path: '/Cyberline/Terms-of-Service',
        name: 'Cyberline',
        component: () => import('@/views/agreements/Cyberline.vue'),
        meta: {
          title: 'Cyberline'
        }
      },
      {
        path: '/Cyberline/PrivacyPolicy',
        name: 'CyberPrivacyPolicy',
        component: () => import('@/views/agreements/CyberPrivacyPolicy.vue'),
        meta: {
          title: 'CyberPrivacyPolicy'
        }
      },
      {
        path: '/Cyberline/TechnicalSupportAgreement',
        name: 'CyberTechnicalSupport',
        component: () => import('@/views/agreements/CyberTechnicalSupport.vue'),
        meta: {
          title: 'CyberTechnicalSupport'
        }
      },

      {

        path: '/vipAgreements',
        name: 'vipAgreements',
        component: () => import('@/views/agreements/VIPServiceAgreement.vue'),
        meta: {
          title: 'vipAgreements'
        }
      },
      {
        path: '/IOSPrivacy',
        name: 'IOSPrivacy',
        component: () => import('@/views/agreements/IOSPrivacy.vue'),
        meta: {
          title: 'IOSPrivacy'
        }
      },
      {
        path: '/IOSTerms',
        name: 'IOSTerms',
        component: () => import('@/views/agreements/IOSTerms.vue'),
        meta: {
          title: 'IOSTerms'
        }
      },
      {
        path: '/IOSPrivacyV2',
        name: 'IOSPrivacyV2',
        component: () => import('@/views/agreements/IOSPrivacyV2.vue'),
        meta: {
          title: 'IOSPrivacyV2'
        }
      },
      {
        path: '/IOSTermsV2',
        name: 'IOSTermsV2',
        component: () => import('@/views/agreements/IOSTermsV2.vue'),
        meta: {
          title: 'IOSTermsV2'
        }
      },
      {
        path: '/terms-of-service/arabic',
        name: 'ArabicTermsOffService',
        component: () => import('@/views/agreements/ArabicTerms.vue'),
        meta: {
          title: '阿拉伯语的服务条款'
        }
      },
      {
        path: '/NetboomCommunity/TechnicalSupportAgreement',
        name: 'NetboomCommunityTechnicalSupportAgreement',
        component: () => import('@/views/agreements/TechnicalSupportAgreement.vue'),
        meta: {
          title: 'NetboomCommunityTechnicalSupportAgreement'
        }
      },
      {
        path: '/NetboomCommunity/PrivacyPolicy',
        name: 'NetboomCommunityPrivacyPolicy',
        component: () => import('@/views/agreements/PrivacyPolicy.vue'),
        meta: {
          title: 'NetboomCommunityPrivacyPolicy'
        }
      },
      {
        path: '/NetboomGame/TechnicalSupportAgreement',
        name: 'NetboomGameTechnicalSupportAgreement',
        component: () => import('@/views/agreements/NetboomGameTech.vue'),
        meta: {
          title: 'NetboomGameTechnicalSupportAgreement'
        }
      },
      {
        path: '/Hololink/TechnicalSupportAgreement',
        name: 'HololinkTechnicalSupportAgreement',
        component: () => import('@/views/agreements/HololinkContact.vue'),
        meta: {
          title: 'HololinkTechnicalSupportAgreement'
        }
      },
      {
        path: '/NBVRPrivacyPolicy',
        name: 'NBVRPrivacyPolicy',
        component: () => import('@/views/agreements/NBVRPrivacyPolicy.vue'),
        meta: {
          title: 'NBVRPrivacyPolicy'
        }
      },
      {
        path: '/Hololink/PrivacyPolicy/',
        name: 'HololinkPrivacyPolicy',
        component: () => import('@/views/agreements/HololinkPrivacyPolicy.vue'),
        meta: {
          title: 'HololinkPrivacyPolicy'
        }
      },
      {
        path: '/Hololink/Terms-of-Service',
        name: 'HololinkTerms-of-Service',
        component: () => import('@/views/agreements/HololinkTerms-of-Service.vue'),
        meta: {
          title: 'HololinkTerms-of-Service'
        }
      },
      {
        path: '/NetboomGame/Terms-of-Service',
        name: 'NetboomGameTerms-of-Service',
        component: () => import('@/views/agreements/NetboomGameTerms.vue'),
        meta: {
          title: 'NetboomGameTerms-of-Service'
        }
      },
      {
        path: '/NetboomGame/PrivacyPolicy',
        name: 'NetboomGamePrivacyPolicy',
        component: () => import('@/views/agreements/NetboomGamePriva.vue'),
        meta: {
          title: 'NetboomGamePrivacyPolicy'
        }
      },
      {
        path: '/Fingertips/PrivacyPolicy',
        name: 'FingertipsPrivacyPolicy',
        component: () => import('@/views/agreements/FingertipsPriva.vue'),
        meta: {
          title: 'FingertipsPrivacyPolicy'
        }
      },
      {
        path: '/Fingertips/Terms-of-Use',
        name: 'FingertipsTermsOfUse',
        component: () => import('@/views/agreements/FingertipsTermsOfUse.vue'),
        meta: {
          title: 'FingertipsTermsOfUse'
        }
      },
      {
        path: '/netboom/userdatadeletion',
        name: 'userdatadeletion',
        component: () => import('@/views/agreements/userdatadeletion.vue'),
        meta: {
          title: 'userdatadeletion'
        }
      },
      {
        path: '/Fingertips/TechnicalSupportAgreement',
        name: 'FingertipsTechnicalSupportAgreement',
        component: () => import('@/views/agreements/FingertipsTechnicalSupportAgreementvue'),
        meta: {
          title: 'FingertipsTechnicalSupportAgreement'
        }
      },

      {
        path: '/NetboomCommunity/Terms-of-Service',
        name: 'NetboomCommunity/Terms-of-Service',
        component: () => import('@/views/agreements/Terms-of-Service.vue'),
        meta: {
          title: 'NetboomCommunityTerms-of-Service'
        }
      },
      {
        path: '/netbomb-privacy-policy',
        name: 'Privacylicy',
        component: () => import('@/views/agreements/Privacylicy.vue'),
        meta: {
          title: '隐私条款'
        }
      },
      {
        path: '/netbomb-terms-of-use',
        name: 'Termsofuse',
        component: () => import('@/views/agreements/Termsofuse.vue'),
        meta: {
          title: '服务条款'
        }
      },
      {
        path: '/privacy-policy/arabic',
        name: 'ArabicPrivacyPolicy',
        component: () => import('@/views/agreements/ArabicPrivacy.vue'),
        meta: {
          title: '阿拉伯语的隐私协议'
        }
      },

      {
        path: '/terms-of-service/spanish',
        name: 'SpanishTermsOffService',
        component: () => import('@/views/agreements/SpanishTerms.vue'),
        meta: {
          title: '西班牙语的服务条款'
        }
      },
      {
        path: '/privacy-policy/spanish',
        name: 'SpanishPrivacyPolicy',
        component: () => import('@/views/agreements/SpanishPrivacy.vue'),
        meta: {
          title: '西班牙语的隐私协议'
        }
      },
      {
        path: '/terms-of-service/returnhome',
        name: 'ReturnHomeTermsOffService',
        component: () => import('@/views/agreements/ReturnHomeTerms.vue'),
        meta: {
          title: 'Return Home的服务条款'
        }
      },
      {
        path: '/privacy-policy/returnhome',
        name: 'ReturnHomePrivacyPolicy',
        component: () => import('@/views/agreements/ReturnHomePrivacy.vue'),
        meta: {
          title: 'Return Home的隐私协议'
        }
      }
    ]
  }
]
