import { getToken, setToken } from '@/utils'

const state = {
  appKey: '',
  aKey: '',
  gaid: '',
  idfv: '',
  idfa: '',
  language: localStorage.getItem('language') || 'en',
  token: getToken()
  // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4MzUwOTI1NzcxODkwNzQwMjI0IiwiYXBwSWQiOjIwLCJleHAiOjE2MTY1NjY4ODAsImlhdCI6MTYxMzk3NDg4MH0.Nxx3RrOt2tg_K6ngfCp9mUxMULf6b2_1rthvD5IsfPo'
}

const mutations = {
  SET_gaid (state, gaid) {
    state.gaid = gaid
  },
  SET_idfa (state, idfa) {
    state.idfa = idfa
  },
  SET_idfv (state, idfv) {
    state.idfv = idfv
  },
  SET_AKEY (state, aKey) {
    state.aKey = aKey
  },
  SET_APPKEY (state, appKey) {
    state.appKey = appKey
  },
  SET_LANGUAGE (state, lang) {
    state.language = lang
  },
  SET_TOKEN (state, token) {
    if (token) {
      state.token = token
      setToken(token)
    }
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
