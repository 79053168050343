export default `
<h3>Technical Support</h3>
<p>If you encounter any problems in the process of using Cyberline, please give us feedback in time：
<a
href="mailto:CyberlineContact@gmail.com"
target="_blank"
class="url"
style="
color: #990000;
text-decoration: none;
"
>CyberlineContact@gmail.com</a>. Such problems include inability to log in, failure to post, incorrect information, etc.</p>
<p>Thanks for support</p>
`
