export default `
<p class = "p-content">
<b>VIP服務協議</b>
</p>
<p class = "p-content">
歡迎使用我們的VIP服務（以下簡稱“服務”）。在使用我們的服務之前，請仔細閱讀本協議。訂閱我們的服務即表示您同意接受以下條款和條件的約束，
</p>
<p class = "p-content">
<b>1.服務說明</b>
</p>
<p class = "p-content">
1.1該服務可以按月定期自動訂閱。通過{project}購買服務後，系統會提示您向適用的付款提供商確認購買的內容以及付款方式（您的卡還是Google Play或iTunes等第三方帳戶）（“付款方式”）將按照向您顯示的服務價格向應用內購買收取費用，以及可能對您的付款徵收的任何銷售或類似稅金。
</p>
<p class = "p-content">
1.2訂閱會自動更新，直到您終止或取消訂閱。在您最初的訂閱承諾期之後，以及在隨後的任何訂閱期之後，您的訂閱將自動按照您在訂閱時同意的價格繼續進行另外的等效期。
</p>
<p class = "p-content">
1.3您的付款方式將繼續在首次購買日期後的24小時內按您最初訂閱時同意的價格每月預先結算。您的卡付款信息將被存儲，然後根據協議用於自動卡付款。
</p>
<p class = "p-content">
1.4每次付款後，您將通過電子郵件收到收據。如果您是通過{project}或相關第三方帳戶（例如Google Play）直接向您付款的，則對已經付款的異議應直接聯繫客戶支持。您也可以通過與銀行或付款提供商聯繫來提出反對，他們可以提供有關您的權利以及適用的時限的更多信息。
</p>
<p class = "p-content">
1.5您不可撤銷地授權{project}第三方帳戶向您收取費用，而無需確認憑據，包括但不限於您的密碼，您的身份信息，您的30天到期前的接下來30天的訂閱費用當前訂閱。收費失敗將視為您終止服務
</p>
<p class = "p-content">


<b>2.權利</b>
</p>
<p class = "p-content">
通過訂購服務，您有權獲得VIP頁面顯示的權利。
</p>
<p class = "p-content">


<b>3.服務費</b>
</p>
<p class = "p-content">
{project}可能會定期調整服務費，並向您顯示此類調整以供確認。
</p>
<p class = "p-content">


<b>4.終止</b>
</p>
<p class = "p-content">
4.1您可以選擇終止服務，前提是您同意{project}不會在您當前的訂購承諾期內退還服務費。
</p>
<p class = "p-content">
4.2 {project}保留絕對的酌處權以有理由或無故地終止服務。如果{project}根據主管當局的命令或法令終止了服務，或者{project}合理地認為您的行為違反了{project}平台的法規，則{project}對您不承擔任何責任。
</p>
<p class = "p-content">
4.3如果{project}無故終止服務，則{project}將退還您當前訂購承諾期的服務費。
</p>
<p class = "p-content">
<b>5，雜項</b>
</p>
<p class = "p-content">
5.1本協議是您在我們的平台上註冊時與{project}簽訂的《 {project}用戶協議》的附錄。
</p>
<p class = "p-content">
5.2本協議與{project}用戶協議之間如有任何差異或差異，以本協議為準。
</p>
`
