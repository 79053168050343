export default `
      <p class = "p-content">
        Sila baca syarat penggunaan berikut dengan teliti. Dengan mengakses atau menggunakan Produk (seperti yang didefinisikan di bawah), anda dengan ini setuju untuk terikat secara kontrak oleh dan dengan tegas mematuhi syarat-syarat penggunaan ini, kerana ia dapat dipinda atau ditambah dari semasa ke semasa. Sekiranya anda tidak menerima Syarat Penggunaan ini, anda tidak boleh menggunakan Produk.
      </p>

      <p class = "p-content">
        <b> 【Peruntukan Umum】 </b> Istilah-istilah yang menggunakan huruf besar yang digunakan dalam Syarat Penggunaan ini mempunyai makna berikut:
        Penyedia - entiti yang menyediakan akses ke Produk, iaitu {project} PTE. LTD.
        Produk - perisian utiliti sesuai dengan Syarat Penggunaan ini, yang merupakan desktop jarak jauh klien di mana Pengguna dapat, antara lain, mengakses semua program dan permainan yang sudah terpasang, menikmati video dan muzik yang tersimpan di komputer Pengguna atau bermain permainan PC dan semua Permainan kilat.
        Syarat Penggunaan - dokumen ini, yang menentukan syarat dan ketentuan penggunaan Produk serta hak dan kewajipan Pengguna dan Penyedia. Syarat Penggunaan merupakan perjanjian yang mengikat antara Pengguna dan Penyedia, berkenaan dengan penggunaan Produk oleh Pengguna.
        Pengguna - individu yang telah mencapai usia mayoritas dalam bidang kuasa, dengan kemampuan penuh untuk melakukan transaksi undang-undang, bertindak atas namanya sendiri, yang memanfaatkan Produk sesuai dengan Syarat Penggunaan.
        Semasa menyediakan perkhidmatan dengan cara elektronik, Penyedia dengan ini menetapkan Syarat Penggunaan berikut.
      </p>
      <p class = "p-content">
        <b> 【Fungsi】 </b> Produk boleh diakses di seluruh dunia oleh sesiapa sahaja yang mempunyai akses Internet.
        Produk ini dirancang untuk memungkinkan Pengguna memiliki akses jarak jauh ke semua program dan permainan yang sudah dipasang oleh Pengguna, ke permainan yang disimpan di komputer awan (dengan Windows, Mac atau Linux) atau bermain permainan PC dan semua permainan Flash. Setakat ini Produk hanya berfungsi pada peranti berasaskan Android.
        Penyedia berhak untuk mengubah fungsi Produk pada bila-bila masa, khususnya dengan memperkenalkan fungsi dan kemudahan baru untuk Pengguna.
      </p>
      <p class = "p-content">
        <b> 【Syarat untuk Menggunakan Produk dan Peraturan Tanggungjawab】 </b> Keperluan teknikal mengenai penggunaan Produk adalah seperti berikut:
        Peranti mudah alih berasaskan Android dengan 3G / 4G / 5G atau Wi-Fi;
        Adalah menjadi tanggungjawab Pengguna untuk memastikan bahawa peralatan yang dimiliki olehnya serta perisian yang digunakan memenuhi syarat di atas dan membenarkannya menggunakan Produk.
        Sebelum memulai penggunaan Produk, Pengguna wajib membiasakan dirinya dengan ketentuan Syarat Penggunaan ini dan dengan Kebijakan Privasi. Akses dan penggunaan Produk setara dengan menerima Syarat Penggunaan dan Dasar Privasi ini.
        Pengguna berjanji untuk menghormati Syarat Penggunaan semasa menggunakan Produk, baik dalam pernyataannya yang terkini dan dengan perubahan selanjutnya.
        Pengguna hanya boleh memiliki satu lesen. Lesen tidak boleh dipindahkan ke pihak ketiga atau digunakan oleh pihak ketiga tersebut.
        Pengguna berjanji untuk tidak melakukan sebarang tindakan yang dapat menghalangi atau mengacaukan penggunaan Produk. Penyedia boleh, tanpa pemberitahuan sebelumnya, melakukan tindakan yang tersedia, termasuk permintaan pampasan, sebagai tindak balas terhadap kegiatan berbahaya atau pelanggaran undang-undang lain yang berlaku atau Syarat Penggunaan ini. Tanpa batasan, tindakan yang disebut dalam kalimat sebelumnya adalah seperti berikut:
        percubaan mengganggu atau memotong akses ke langganan Pengguna lain atau ke komputer mereka (DOS, serangan DDOS, pemalsuan DNS);
        phishing, iaitu memalsukan maklumat yang memungkinkan untuk mengenal pasti Pengguna atau menyembunyikan maklumat tersebut tanpa terlebih dahulu mendapat persetujuan bertulis;
        memasukkan perisian berniat jahat ke dalam sistem atau ke komputer Penyedia, terutamanya termasuk virus, kuda Trojan atau bug internet;
        pengimbasan rangkaian Penyedia atau Pengguna lain yang tidak dibenarkan dalam mencari jurang keselamatan;
        pemantauan lalu lintas rangkaian yang tidak dibenarkan atau percubaan memintas maklumat yang disediakan untuk Penyedia atau Pengguna lain;
        percubaan yang tidak dibenarkan untuk memecahkan keselamatan komputer atau rangkaian Pengguna lain;
        mengambil alih alamat IP;
        penghantaran, pengumpulan, penjualan atau pengedaran alamat e-mel secara langsung atau tidak langsung untuk tujuan menghantar surat-menyurat yang tidak diminta secara besar-besaran (spam).
        Tanpa mengehadkan ketentuan lain dalam Syarat Penggunaan ini, Pengguna tidak boleh menggunakan Produk untuk melakukan perkara berikut atau membantu orang lain melakukan perkara berikut:
        mengancam, memfitnah, menghentam, menyalahgunakan atau mengganggu orang lain atau melakukan aktiviti haram;
        melanggar hak undang-undang mana-mana orang atau entiti (termasuk, tanpa batasan, hak harta intelek atau hak privasi), menghantar bahan yang melanggar atau mengelakkan hak tersebut atau membuang atau mengubah harta intelek atau notis undang-undang lain;
        menghantar fail yang mengandungi virus, perisian intip, adware atau kod berbahaya yang lain;
        mengiklankan atau mempromosikan barang atau perkhidmatan yang disediakan oleh Penyedia, mengikut budi bicara mutlaknya, tidak menyenangkan (termasuk, tanpa batasan, dengan mengirim spam);
        mengganggu orang lain menggunakan Produk;
        menghantar, mengumpulkan atau mengakses data peribadi Pengguna tanpa persetujuan Pengguna dan Penyedia tersebut;
        terlibat dalam labah-labah yang tidak dibenarkan, "mengikis" atau menuai, menghubungi atau data peribadi lain atau menggunakan cara automatik lain yang tidak dibenarkan untuk mengumpulkan maklumat;
        kalahkan sebarang kawalan akses, akses mana-mana bahagian Produk yang tidak dibenarkan oleh Pengguna untuk mengaksesnya.
        Penyedia diberi kuasa untuk segera, tanpa pemberitahuan sebelumnya, menghentikan perjanjian, menangguhkan atau menghapus secara kekal akses ke Produk Pengguna yang melanggar Syarat Penggunaan ini.
        Sekiranya Pengguna menggunakan Borang Kenalan, dia berkewajiban untuk memberikan maklumat sebenar, yang benar dengan fakta kes.
        Penyedia berjanji untuk memastikan kemungkinan kualiti dan kestabilan Produk yang tertinggi, namun dalam tahap maksimum yang diizinkan oleh undang-undang yang berlaku, Penyedia tidak akan bertanggung jawab atas sebarang gangguan atau gangguan yang disebabkan oleh force majeure atau aktiviti pihak ketiga yang tidak dibenarkan.
        Dalam batas maksimum yang diizinkan oleh hukum yang berlaku, Penyedia tidak akan bertanggung jawab atas ketidakmampuan sementara untuk menggunakan Produk, yang disebabkan oleh pelaksanaan solusi dan fasilitas baru dalam kerangka Produk. Seboleh mungkin, Penyedia akan memberi tahu Pengguna tentang jeda yang dijadualkan dalam aksesibilitas Produk atau segala ketidaknyamanan yang mungkin terjadi dalam penggunaannya. Penyedia berhak untuk melakukan kerja-kerja penyelenggaraan pada peralatan sistem IT yang digunakan untuk menyediakan akses ke Produk, yang mungkin menyebabkan kesulitan buat sementara waktu atau tidak memungkinkan Pengguna menggunakan Produk.
        Dalam kes-kes khas yang mempengaruhi keselamatan atau kestabilan Produk, Penyedia berhak untuk mengurangkan atau menangguhkan sementara akses ke Produk tanpa memberi tahu Pengguna sebelumnya dan melakukan kerja-kerja penyelenggaraan yang bertujuan untuk mengembalikan keselamatan dan kestabilan Produk.
        Dalam tahap maksimum yang diizinkan oleh undang-undang yang berlaku, Penyedia tidak akan bertanggung jawab atas hal Pengguna lain atau pihak ketiga mengemukakan tuntutan terhadap Penyedia mengenai pelanggaran Syarat Penggunaan atau undang-undang yang berlaku, baik oleh Pengguna lain atau oleh pihak ketiga. Hanya Pengguna atau pihak ketiga yang melanggar undang-undang yang berkuat kuasa yang bertanggungjawab dalam kes tersebut.
        Produk ini menyediakan platform untuk membolehkan Pengguna bermain permainan.
      </p>

     <p class = "p-content">
        <b> 【Momen Penutup Perjanjian】 </b> Perjanjian untuk menyediakan akses ke Produk dan membiasakan serentak dengan Syarat Penggunaan dan Dasar Privasi ini. Pengguna tidak dapat mengakses dan menggunakan Produk tanpa terlebih dahulu menerima Syarat Penggunaan dan Dasar Privasi ini.
        Pengguna boleh menamatkan perjanjian untuk menyediakan akses ke Produk, berkuat kuasa dengan segera, pada bila-bila masa dan tanpa menyatakan alasan.
        Pengguna boleh berhenti menggunakan Produk pada bila-bila masa.
      </p>

      <p class = "p-content">
        <b> 【Aduan】 </b> Pengguna berhak membuat aduan sekiranya Penyedia tidak memenuhi kewajibannya yang dinyatakan di sini atau jika Penyedia memenuhi mereka dengan cara yang bertentangan dengan ketentuan di sini.
        Aduan boleh diajukan melalui alamat e-mel kami {email}. Suatu aduan harus merangkumi khususnya masalah yang menjadi asas untuk aduan dan maklumat pengenalan Pengguna (nama, alamat e-mel).
        Penyedia berhak untuk menghubungi Pengguna jika maklumat lebih lanjut diperlukan dan Pengguna wajib memberikannya dengan segera.
        Dalam masa empat belas (14) hari kerja setelah menerima aduan Pengguna dalam bentuk yang tepat, Penyedia akan:
        menyelesaikan siasatan dalamannya dan memberi nasihat kepada Pengguna mengenai keputusannya; atau
        memberitahu Pengguna bahawa memerlukan lebih banyak masa untuk menyelesaikan penyelidikan.
        Sekiranya aduan tidak dapat dipertimbangkan dalam tarikh akhir yang ditentukan, Penyedia akan memberitahu pada masa itu dengan Pengguna e-mel yang mengemukakan keluhan mengenai sebab-sebab kelewatan tersebut dan tarikh yang diharapkan untuk penyelesaian aduan dan juga memberi pengguna kemas kini mingguan kemajuan penyiasatan, kecuali di mana Penyedia sedang menunggu jawapan dari Pengguna dan Pengguna telah diberitahu bahawa Penyedia memerlukan respons Pengguna.
        Aduan harus diserahkan dalam waktu tiga puluh (30) hari kalender pada saat alasan keluhan tersebut menjadi jelas.
        Setelah menyelesaikan penyelidikan, Penyedia akan memberi tahu Pengguna mengenai hasil dan alasan keputusannya dengan merujuk kepada ketentuan yang relevan dari Syarat Penggunaan ini.
        Sekiranya Pengguna tidak berpuas hati dengan keputusan Penyedia berkenaan dengan aduan yang diajukan, Pengguna mungkin ingin membawa perkara itu ke penyelesaian sengketa bebas luaran.
        Balasan untuk aduan akan dikirim ke alamat e-mel yang ditunjukkan oleh Pengguna yang mengemukakan aduan.
        Penyedia tidak akan mempertimbangkan aduan yang disebabkan oleh ketidaktahuan undang-undang yang berlaku, ketentuan Syarat Penggunaan ini, atau informasi yang diumumkan dalam Produk.
        Sekiranya syarat prosedur aduan dilanggar, aduan tidak dapat dipertimbangkan.
      </p>

    <p class = "p-content">
        <b> Property Harta Intelek】 </b> Produk dan semua hak yang berkaitan dengannya adalah hak milik eksklusif Penyedia atau pihak ketiga. Semua elemen kreatif yang diletakkan di Laman ini dilindungi oleh hak harta intelek, dan terutama oleh hak cipta. Semua tanda dagang, logo, grafik, gambar, animasi, video, teks dan tanda khas lain yang terdapat dalam Produk adalah hak intelektual Penyedia atau pihak ketiga. Oleh itu, mereka tidak boleh diterbitkan semula, digunakan atau diwakili tanpa kebenaran bertulis daripada Penyedia atau pihak ketiga.
        Pengguna dengan ini berjanji untuk menghormati hak harta intelek (termasuk hak ekonomi pengarang dan hak harta industri, serta hak yang terhasil dari pendaftaran tanda dagang) yang berhak diberikan oleh Penyedia atau pihak ketiga.
        Dengan syarat Pengguna mematuhi semua kewajibannya di bawah Syarat Penggunaan ini, Penyedia dengan ini memberikan kepada pengguna hak untuk memuat turun, memasang, menyandarkan, terhad, boleh dicabut, tidak eksklusif, tidak boleh diserahkan, tidak boleh dilisensikan , dan menggunakan Produk, termasuk fail dokumentasi atau maklumat laman web yang menyertainya, kerana Penyedia bermaksud Produk akan digunakan, dan hanya sesuai dengan Syarat Penggunaan ini dan secara khusus dengan syarat bahawa: (i) Produk tidak diubah dalam apa-apa cara; (ii) semua notis atau label hak cipta dan hak milik disimpan pada Produk dalam format asalnya; dan (iii) penggunaan Produk adalah semata-mata untuk tujuan bukan komersial (dalaman) Pengguna. Penyedia memberikan kepada Pengguna hak lain, tersirat atau sebaliknya. Pengguna tidak akan dan tidak membenarkan Pengguna mana-mana pihak ketiga untuk: (i) menyalin, mengubahsuai, menyesuaikan, menterjemahkan atau membuat karya terbitan Produk; (ii) jurutera mundur, menyusun semula, membongkar atau cuba mencari kod sumber Produk, kecuali sejauh mana undang-undang yang berlaku secara khusus melarang sekatan tersebut; (iii) menyewa, memberi lesen, memajak, menjual, memberikan atau memindahkan hak (atau bermaksud melakukan perkara yang sama) di dalam atau ke Produk; dan (iv) menggunakan, mengirim, mengirim atau memperkenalkan sebarang peranti, perisian atau rutin yang mengganggu atau cuba mengganggu operasi Produk. Pengguna akan mematuhi semua undang-undang dan peraturan yang berlaku yang berkaitan dengan penggunaan dan akses ke Produk. Pengguna tidak boleh memberikan akses ke Produk kepada pihak ketiga. Lesen yang diberikan di atas dan hak penggunaan Produk oleh Pengguna akan segera dihentikan sekiranya Pengguna gagal mematuhi Syarat Penggunaan ini.
        Produk dilesenkan, tidak dijual, dan Penyedia secara tegas menyimpan dan mempertahankan setiap saat semua hak pemilikan di dan ke Produk, termasuk hak harta intelek di dalamnya. Syarat Penggunaan ini tidak akan ditafsirkan untuk memberikan hak kepemilikan apa pun dalam Produk kepada Pengguna, tetapi hanya akan memberi Pengguna lesen terhad untuk menggunakan Produk sehingga lesen ini ditamatkan, seperti yang dinyatakan di sini. Pengguna mengakui bahawa Produk, termasuk semua hak harta intelek di bawah hak cipta, rahsia perdagangan atau undang-undang tanda dagang, dimiliki oleh Penyedia. Penyedia boleh melakukan semua penyelesaian undang-undang jika Pengguna menggunakan Produk yang melanggar Syarat Penggunaan ini.
        Pengguna mengakui bahawa sekiranya dia menggunakan Produk yang melanggar Syarat Penggunaan ini (termasuk, tanpa batasan, dengan menyalin, menyebarkan atau membuat turunan Produk), Pengguna boleh dikenakan denda dan denda jenayah dan / atau sivil di bawah undang-undang yang terpakai.
        Sebagai syarat penggunaan Produk oleh Pengguna, Pengguna menjamin bahawa dia tidak akan menggunakan Produk untuk tujuan apa pun yang menyalahi undang-undang atau dilarang oleh Syarat Penggunaan ini. Pengguna bersetuju untuk mematuhi semua peraturan tempatan yang berlaku untuk Pengguna dalam penggunaan Produknya. Pengguna bersetuju untuk tidak memperbanyak, menjual semula atau mengeksploitasi untuk tujuan komersil, mana-mana bahagian Produk. Sekiranya Pengguna melanggar mana-mana syarat ini, lesen untuk menggunakan Produk akan dihentikan secara automatik.
        Produk ini disediakan hanya untuk tujuan dan penggunaan yang sah. Pengguna bersetuju untuk mengawasi dan mengawal penggunaan Produk sesuai dengan syarat Syarat Penggunaan ini.
        Produk diberikan kepada Pengguna "sebagaimana adanya" oleh Penyedia dan setiap penggunaan Produk adalah atas risiko Pengguna sendiri. Pengguna mengakui bahawa Produk mungkin mengandungi bug, kesalahan, dan masalah lain yang dapat menyebabkan kegagalan sistem atau lain-lain dan kehilangan data. Penyedia tidak menjamin prestasi Produk dan tidak menjamin bahawa Produk akan beroperasi dengan ketara sesuai dengan dokumentasi yang menyertakan Produk. Produk boleh membuat perubahan pada pendaftaran Pengguna dan fail lain dan Pengguna menanggung semua risiko untuk perubahan tersebut. Pastikan anda membuat sandaran komputer dan semua data penting sebelum menggunakan atau menjalankan Produk.
        Sejauh yang dibenarkan oleh undang-undang, Penyedia menolak semua jaminan dalam bentuk apa pun, sama ada tersurat atau tersirat, dalam ekuiti atau undang-undang, termasuk, tanpa batasan, jaminan tersirat untuk diperdagangkan, kesesuaian untuk tujuan tertentu, dan bukan pelanggaran. Penyedia tidak menjamin bahawa fungsi yang terkandung dalam Produk akan memenuhi sebarang keperluan atau keperluan yang mungkin dimiliki oleh Pengguna, atau bahawa Produk akan beroperasi tanpa ralat, atau dengan cara yang tidak terganggu, atau bahawa sebarang kecacatan atau kesalahan dalam Produk akan diperbaiki. , atau bahawa Produk ini serasi dengan platform tertentu. Penyedia tidak memberikan jaminan bahawa ia akan memberikan Pengguna versi Produk yang paling baru dibangunkan atau diedarkan. Pengguna akan bertanggungjawab sepenuhnya untuk memastikan bahawa dia mempunyai versi Produk yang terkini.
        Seorang Pengguna menyedari dan menerima bahawa hanya dia yang akhirnya bertanggung jawab atas segala kerugian atau kerosakan yang ditanggung oleh Pengguna akibat menggunakan atau memasang Produk atau sebagai akibat dari perubahan yang dibuat pada komputer dan peranti mudah alih Pengguna. Sejauh maksimum yang diizinkan oleh undang-undang, dalam keadaan atau peristiwa apa pun, Penyedia tidak akan bertanggungjawab kepada Pengguna atau orang lain atau entiti lain atas sebarang kerosakan sampingan atau akibat (termasuk, tanpa batasan, langsung atau tidak langsung, sampingan, istimewa, hukuman, atau ganti rugi teladan untuk kehilangan perniagaan, kehilangan keuntungan atau pendapatan, gangguan perniagaan, atau kehilangan maklumat atau data perniagaan) yang timbul daripada penggunaan atau ketidakmampuan untuk menggunakan Produk, atau atas tuntutan oleh pihak lain, walaupun Penyedia telah diberitahu tentang kemungkinan kerosakan tersebut atau jika kerosakan itu dapat diramalkan.
      </p>

     <p class = "p-content">
        <b> 【Perlindungan Data Peribadi dan Dasar Privasi】 </b> Pengumpulan, penggunaan dan pendedahan peribadi Pengguna oleh data Penyedia diatur oleh peruntukan dalam Dasar Privasi. Pengguna bersetuju dengan penggunaan dan pendedahan data peribadi Pengguna dalam keadaan yang dinyatakan dalam Dasar Privasi tersebut.
        Pengguna mesti membaca Dasar Privasi sepenuhnya dengan teliti sebelum memutuskan untuk menjadi Pengguna.
        Sekiranya Pengguna menggunakan Produk dengan cara yang melanggar Syarat Penggunaan ini atau undang-undang yang berlaku, Penyedia berhak untuk menggunakan data peribadi Pengguna dalam ruang lingkup yang diperlukan untuk menetapkan tanggungjawabnya. Dalam kes seperti itu, Penyedia harus memberitahu Pengguna tentang kegiatannya yang tidak sah dengan tuntutan penghentian segera mereka.
      </p>

      <p class = "p-content">
        <b> 【Pembayaran】 </b> Pembayaran untuk produk diproses oleh pihak ketiga. Penyedia tidak bertanggungjawab untuk memproses pembayaran, setiap masalah harus diselesaikan dengan penyedia pembayaran.
      </p>
      <p class = "p-content">
        <b> 【Peruntukan Akhir】 </b> Syarat Penggunaan tersedia untuk Pengguna di sini.
        Penyedia berhak untuk mengubah, menambah, mengganti atau mengubah Syarat Penggunaan ini pada bila-bila masa, dan khususnya dengan mengubah yuran yang perlu dibayar, memperkenalkan yuran baru, memperkenalkan Syarat Penggunaan baru, untuk satu atau lebih sebab berikut:
        untuk mematuhi sebarang perubahan atau jangkaan perubahan dalam undang-undang atau kod amalan yang berkaitan;
        untuk menggambarkan keputusan mahkamah, ombudsman atau pengawal selia;
        untuk menggambarkan perubahan dalam sistem atau prosedur kami, termasuk atas sebab keselamatan;
        sebagai akibat daripada keadaan yang berubah (termasuk dengan menambahkan faedah atau ciri baru pada Produk);
        untuk bertindak balas secara seimbang terhadap perubahan kos penyediaan Produk; atau
        untuk menjadikan mereka lebih jelas.
        Sekiranya ada undang-undang yang mengatur perubahan yang ditentukan oleh Penyedia, Penyedia hanya dapat membuat perubahan sejauh yang diizinkan oleh undang-undang.
        Sekiranya perubahan diperlukan dalam pandangan Penyedia untuk memulihkan atau menjaga keamanan Produk, perubahan tersebut dapat dilakukan dengan segera dan tanpa pemberitahuan sebelumnya kepada Pengguna.
        Sekiranya terdapat pengubahsuaian, suplemen, penggantian atau perubahan lain dari Syarat Penggunaan ini, Pengguna akan diberitahu tentangnya melalui pengumuman yang sesuai yang dipaparkan di laman utama Laman web ini. Pengubahsuaian, suplemen, penggantian atau perubahan lain berlaku pada tarikh yang ditentukan oleh Penyedia, yang mungkin tidak lebih awal dari empat belas (14) hari kalendar dari tarikh pengumuman tersebut. Pengguna yang tidak bersetuju dengan pengubahsuaian, tambahan, penggantian atau perubahan lain dari Syarat Penggunaan ini boleh menghentikan perjanjian dengan Penyedia dan akan segera memberitahu Penyedia mengenai hal itu dengan mengirimkan pernyataan yang berkaitan pada alamat e-mel berikut: service @ netboom. com. Penamatan akan berkuat kuasa setelah menerima pernyataan Pengguna tetapi tidak lebih awal dari tarikh berlakunya Terma Penggunaan yang diubah. Penggunaan Laman web ini secara berterusan setelah pengubahsuaian, tambahan, penggantian atau perubahan lain telah dibuat secara efektif pada Syarat Penggunaan ini menunjukkan penerimaan Pengguna terhadap Syarat Penggunaan yang diubah.
        Tidak ada pihak dalam perjanjian ini yang dapat menyerahkan atau memindahkan hak atau kewajiban mereka berdasarkan perjanjian tanpa persetujuan pihak lain terlebih dahulu. Sekiranya dilakukan berdasarkan penggabungan, penyusunan semula atau penyusunan semula yang melibatkan Penyedia, atau penjualan perniagaannya oleh Penyedia, Penyedia boleh, melalui pemberitahuan kepada Pengguna, memindahkan atau menyerahkan haknya, atau memulai hak dan kewajibannya, di bawah Syarat ini Penggunaan dan Pengguna akan dianggap telah bersetuju dengan pemindahan, penugasan atau pemula tersebut.
        Syarat Penggunaan akan diatur oleh undang-undang Republik Rakyat China, tanpa memperhatikan prinsip pertentangan undang-undangnya.
        Sekiranya anda mempunyai pertanyaan mengenai atau komen mengenai Syarat Penggunaan ini, sila hubungi kami melalui {email}.
      </p>
  `
