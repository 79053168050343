export default `
<div>100% winning prize</div>
<div>50% chance to win 130-200coins</div>
<div>20% chance to win 200-300coins</div>
<div>14% chance to win 10-130coins</div>
<div>10% chance to win 80 of VIP hours (expired in a month)</p>
<div>5% chance to win 240 of VIP hours (expired in a month)</p>
<div>1% chance to win 960 of VIP hours (expired in a month)</p>
<p>Each chest will cost 1 key to open</p>
`
