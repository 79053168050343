import request from '@/utils/request'

export function subscriptionProductList (data = {}) {
  return request({
    url: '/pay/subscriptionProductList',
    data
  })
}

export function getUserWallet (data = {}) {
  return request({
    url: '/user/wallet',
    data
  })
}
export function createOrder (data = {}) {
  return request({
    url: '/pay/createOrder',
    data
  })
}
export function createOrderPayermax (data = {}) {
  return request({
    url: '/pay/payermax/create/paymentIntents',
    data
  })
}

export function stripePayments (data = {}) {
  return request({
    url: '/pay/stripe/create/checkout/session',
    data
  })
}
export function stripePaycodes (data = {}) {
  return request({
    url: '/pay/stripe/create/gold/session',
    data
  })
}
export function createSubscribeSession (data = {}) {
  return request({
    url: '/pay/stripe/create/subscribe/session',
    data
  })
}

export function paymentIntents (data = {}) {
  return request({
    url: '/pay/airWallex/create/paymentIntents',
    data
  })
}
