export default [
  {
    path: '/payermax',
    component: () => import('@/layouts/LayoutCommon'),
    meta: {
      title: 'payermax'
    },
    children: [
      {
        path: '/pay/payermax',
        name: 'payermax',
        component: () => import('@/views-rem/payermax/index.vue'),
        meta: {
          title: 'payermax'
        }
      }
    ]
  }
]
