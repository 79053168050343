<template>
  <div class="home">
    <pre>
      <code>
        ?lang=[ ko | zh | en | in | th | vi | ms ]
        ko韩语 zh中文繁体 en英语 in印尼  th泰语 vi越南语 ms 马来语
      </code>
    </pre>
    <h3>Agreements</h3>
    <ul>
      <li
        v-for="(item, index) in agreementsList"
        :key="index"
      >
        <span @click="handleGoPage(item)">{{ item.meta.title }}</span><span class="path">{{ item.path }}</span>
      </li>
    </ul>
    <ul>
      <li
        v-for="(item, index) in netboomAgreementsList"
        :key="index"
      >
        <span @click="handleGoPage(item)">{{ item.meta.title }}</span><span class="path">{{ item.path }}</span>
      </li>
    </ul>
    <h3>Community</h3>
    <ul>
      <li
        v-for="(item, index) in communityList"
        :key="index"
      >
        <span @click="handleGoPage(item)">{{ item.meta.title }}</span><span class="path">{{ item.path }}</span>
      </li>
    </ul>
    <h3>Rules</h3>
    <ul>
      <li
        v-for="(item, index) in rulesList"
        :key="index"
      >
        <span @click="handleGoPage(item)">{{ item.meta.title }}</span><span class="path">{{ item.path }}</span>
      </li>
    </ul>
    <h3>Paypal</h3>
    <ul>
      <li
        v-for="(item, index) in paypalList"
        :key="index"
      >
        <span @click="handleGoPage(item)">{{ item.meta.title }}</span><span class="path">{{ item.path }}</span>
      </li>
    </ul>
    <h3>Tips</h3>
    <ul>
      <li
        v-for="(item, index) in tipsList"
        :key="index"
      >
        <span @click="handleGoPage(item)">{{ item.meta.title }}</span><span class="path">{{ item.path }}</span>
      </li>
    </ul>
    <!-- <h3>Netboom</h3>
    <ul>
      <li
        v-for="(item, index) in netboomList"
        :key="index"
      >
        <span @click="handleGoPage(item)">{{ item.meta.title }}</span><span class="path">{{ item.path }}</span>
      </li>
    </ul> -->
    <h3>Game</h3>
    <ul>
      <li
        v-for="(item, index) in gameList"
        :key="index"
      >
        <span @click="handleGoPage(item)">{{ item.meta.title }}</span><span class="path">{{ item.path }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import agreements from '@/router/agreements'
import community from '@/router/community'
import rules from '@/router/rules'
import netboomAgreements from '@/router/netboomAgreements'
import paypal from '@/router/paypal'
import tips from '@/router/tips'
import netboom from '@/router/netboom.js'
import game from '@/router/game.js'
export default {
  name: 'Home',
  data () {
    return {
      agreementsList: [],
      communityList: [],
      rulesList: [],
      netboomAgreementsList: [],
      paypalList: [],
      tipsList: [],
      gameLists: []
    }
  },
  created () {
    this.agreementsList = this.getMenu(agreements)
    this.communityList = this.getMenu(community)
    this.rulesList = this.getMenu(rules)
    this.netboomAgreementsList = this.getMenu(netboomAgreements)
    this.paypalList = this.getMenu(paypal)
    this.tipsList = this.getMenu(tips)
    this.netboomList = this.getMenu(netboom)
    this.gameList = this.getMenu(game)
  },
  methods: {
    getMenu (routes, result = []) {
      routes.forEach(item => {
        if (!item.children) {
          !item.meta.hidden && result.push({
            name: item.name || 'No Name',
            meta: item.meta || {},
            path: item.path
          })
        } else {
          this.getMenu(item.children, result)
        }
      })
      return result
    },
    handleGoPage (item) {
      this.$router.push({
        name: item.name
      })
    }
  }
}
</script>
<style lang="less">
  .home{
    padding-bottom: 100px;
    h3 {
      color: darkslateblue;
      margin: 20px 0 10px 20px;
    }
    ul{
      li{
        color: darkslateblue;
        line-height: 30px;
        &:hover{
          color: lighten(darkslateblue, 10%);
        }
        >span{
          &:nth-of-type(1) {
            display: inline-block;
            text-decoration:underline;
            width: 200px;
            cursor: pointer;
          }
          &.path {
            text-decoration: none;
            color: #6f6f6f;
          }
        }
      }
    }
  }
</style>
