import { subscriptionProductList } from '@/api/pay'

const state = {
  subscriptionProductList: []
}

const mutations = {
  updateSubscriptionProductList (state, list) {
    state.subscriptionProductList = list
  }
}
const actions = {
  getSubscriptionProductList ({ commit }) {
    return subscriptionProductList({
      channel: 3, // 渠道1.安卓 2.IOS 3.paypal
      experimentGroup: 10 // 实验组:0.基准组 1.V1体验组 2.V2体验组  6.IOS内嵌 10. 新版订阅
    }).then(res => {
      if (res.code === 200) {
        commit('updateSubscriptionProductList', res.data.list)
      } else {
        console.log(res)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
