export default {
  slogan: 'Great offers for vip only!',
  introduce_1: '20 hours gaming time each month',
  introduce_2: 'Exclusive high priority queue',
  introduce_3: 'All games available',
  encourage_only: 'ONLY {money}',
  encourage_discounts: 'SAVE {discounts}%',
  monthUnit: 'MONTH',
  agreementInstructionMain: 'Monthly renewal costs USD 9.9 on Google Play',
  agreementInstructionTitle: 'Auto renewal service instruction',
  agreementInstructionItem_1: '1.  After opening, auto renewal will be operated in each natural month and extend the VIP service for a month;',
  agreementInstructionItem_2: '2.  If you wish to cancel renewal, please close it within at least 48 hours during the withholding period;',
  agreementInstructionItem_3: '3.  How to cancel: you can cancel at any time in Subscriptions on Paypal'
}
