import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/i18n/lang/en'
import ko from '@/i18n/lang/ko'
import ms from '@/i18n/lang/ms'
import th from '@/i18n/lang/th'
import vi from '@/i18n/lang/vi'
import zh from '@/i18n/lang/zh'
import _in from '@/i18n/lang/in'

Vue.use(VueI18n)

// ko韩语 zh中文繁体 en英语 in印尼  th泰语 vi越南语 ms 马来语
const messages = { en, ko, ms, th, vi, zh, in: _in }

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'en',
  messages
})

export default i18n
